import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { useParams, useLocation } from 'react-router-dom'
import { IonList, IonSpinner } from '@ionic/react'
import { SignInHeader } from '../components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { setSessionToken, clearSessionToken } from 'auth'
import { validReferrer, redirectAfterSignIn } from 'utils'

const propTypes = {
  impersonateUser: PropTypes.func.isRequired,
}

const defaultProps = {}

function Impersonate({ impersonateUser }) {
  const { constituentId } = useParams()
  const { search } = useLocation()
  const [error, setError] = useState()

  useEffect(() => {
    const signInAsUser = async () => {
      try {
        // Pass through all query params
        const searchParams = new URLSearchParams(search)
        const user = await impersonateUser(
          constituentId,
          Object.fromEntries(searchParams)
        )
        setSessionToken(user.bearerToken)
        redirectAfterSignIn()
      } catch {
        setError(`Unable to log you in as user ${constituentId}`)
      }
    }

    clearSessionToken()
    validReferrer() ? signInAsUser() : setError('Unauthorized action')
  }, [constituentId, impersonateUser, search])

  return (
    <IonList>
      <SignInHeader title="Signing in as...">
        {error ? (
          <div className="centered-content">{error}</div>
        ) : (
          <>
            <p>
              We are attempting to log you in to the selected account. Please
              wait and you will be redirected if successful.
            </p>
            <IonSpinner />
          </>
        )}
      </SignInHeader>
    </IonList>
  )
}

Impersonate.propTypes = exact(propTypes)
Impersonate.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchtoProps = {
  impersonateUser: apiActions.impersonateUser,
}

export default compose(connect(mapStateToProps, mapDispatchtoProps))(
  Impersonate
)
