import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { IonCard, IonCardContent, IonCardTitle } from '@ionic/react'
import { CELL_FORMATTING, APP_PATH } from 'config'

const propTypes = {
  fetchHomeMileageSummary: PropTypes.func.isRequired,
  savings: PropTypes.number,
  setError: PropTypes.func.isRequired,
}

const defaultProps = {
  savings: null,
}

function SavingsCard({ savings, fetchHomeMileageSummary, setError }) {
  useEffect(() => {
    const fetchSavings = async () => {
      try {
        await fetchHomeMileageSummary()
      } catch (errorMessage) {
        setError(
          `There was an issue loading your Mileage Savings data: ${errorMessage}`
        )
      }
    }

    fetchSavings()
  }, [fetchHomeMileageSummary, setError])

  return (
    <IonCard routerLink={APP_PATH.MILEAGE_TRACKER.ALL_TRIPS} button>
      <IonCardContent>
        <IonCardTitle>YTD Mileage Savings</IonCardTitle>
        <p>
          {savings?.toLocaleString('en-US', CELL_FORMATTING.CURRENCY) ?? '...'}
        </p>
      </IonCardContent>
    </IonCard>
  )
}

SavingsCard.propTypes = exact(propTypes)
SavingsCard.defaultProps = defaultProps

export default React.memo(SavingsCard)
