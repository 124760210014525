import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Document, Page, Thumbnail } from 'react-pdf'
import { IonButton, IonIcon, isPlatform } from '@ionic/react'
import { LoadingSpinner, DocumentStatusBadge } from 'components'
import { DOCUMENT_SCALE } from 'config'
import {
  downloadOutline,
  chevronForwardOutline,
  chevronBackOutline,
} from 'ionicons/icons'
import { convertBlobToDataUrl, downloadFileFromBlob } from 'utils'

const propTypes = {
  pdfBlob: PropTypes.object.isRequired,
  fileName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
}

const defaultProps = {}

function DocumentViewer({ fileName, status, pdfBlob }) {
  const isDesktop = isPlatform('desktop')
  const [pdfData, setPdfData] = useState()
  const [numPages, setNumPages] = useState()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageScale, setPageScale] = useState(
    isDesktop ? DOCUMENT_SCALE.START.DESKTOP : DOCUMENT_SCALE.START.MOBILE
  )
  const [downloadError, setDownloadError] = useState()
  const [pdfError, setPdfError] = useState()

  useEffect(() => {
    convertBlobToDataUrl({
      blob: pdfBlob,
      onSuccess: setPdfData,
      onFail: setPdfError,
    })
  }, [pdfBlob])

  const handleLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }
  const zoomIn = () => {
    if (pageScale === DOCUMENT_SCALE.MIN) return
    setPageScale(pageScale - DOCUMENT_SCALE.CHANGE_AMOUNT)
  }
  const zoomOut = () => {
    if (pageScale === DOCUMENT_SCALE.MAX) return
    setPageScale(pageScale + DOCUMENT_SCALE.CHANGE_AMOUNT)
  }
  const goToPrevPage = () => {
    if (pageNumber === 1) return
    setPageNumber(pageNumber - 1)
  }
  const goToNextPage = () => {
    if (pageNumber === numPages) return
    setPageNumber(pageNumber + 1)
  }
  const goToPage = ({ pageNumber }) => setPageNumber(pageNumber)

  return (
    <div className="document-viewer-wrapper">
      {pdfError ? (
        <p>{pdfError}</p>
      ) : !pdfData ? (
        <LoadingSpinner />
      ) : (
        <Document
          onLoadSuccess={handleLoadSuccess}
          file={pdfData}
          loading={() => <LoadingSpinner />}
        >
          <div className="document-page-wrapper">
            <Page
              pageNumber={pageNumber}
              loading={() => <LoadingSpinner />}
              className="document-page"
              scale={pageScale / 100}
            />
            <div className="document-page-bar">
              <div className="document-actions">
                <div className="page-scale">
                  <IonButton
                    onClick={zoomIn}
                    disabled={pageScale === DOCUMENT_SCALE.MIN}
                    aria-label="Zoom out"
                    fill="clear"
                    color="light"
                  >
                    -
                  </IonButton>
                  <span className="scale-amount">{pageScale}%</span>
                  <IonButton
                    onClick={zoomOut}
                    disabled={pageScale === DOCUMENT_SCALE.MAX}
                    aria-label="Zoom in"
                    fill="clear"
                    color="light"
                  >
                    +
                  </IonButton>
                </div>
                <div className="page-count">
                  <IonButton
                    onClick={goToPrevPage}
                    disabled={pageNumber === 1}
                    fill="clear"
                    color="light"
                    aria-label="Go to previous page"
                  >
                    <IonIcon icon={chevronBackOutline}></IonIcon>
                  </IonButton>
                  <span className="current-page">
                    {pageNumber} of {numPages}
                  </span>
                  <IonButton
                    onClick={goToNextPage}
                    disabled={pageNumber === numPages}
                    fill="clear"
                    color="light"
                    aria-label="Go to next page"
                  >
                    <IonIcon icon={chevronForwardOutline}></IonIcon>
                  </IonButton>
                </div>
                <div className="document-download">
                  <IonButton
                    aria-label="Download"
                    onClick={() =>
                      downloadFileFromBlob({
                        blob: pdfBlob,
                        fileName: `${fileName}.pdf`,
                        setError: setDownloadError,
                      })
                    }
                    fill="clear"
                    color="light"
                  >
                    <IonIcon slot="start" icon={downloadOutline} />
                  </IonButton>
                  {downloadError && <p>{downloadError}</p>}
                </div>
              </div>
              <DocumentStatusBadge status={status} />
            </div>
          </div>
          <div className="document-thumbnails-wrapper">
            {[...Array(numPages).keys()].map((pageIndex) => (
              <Thumbnail
                key={pageIndex}
                pageIndex={pageIndex}
                onItemClick={goToPage}
                height={100}
                className="document-thumbnail"
              />
            ))}
          </div>
        </Document>
      )}
    </div>
  )
}

DocumentViewer.propTypes = exact(propTypes)
DocumentViewer.defaultProps = defaultProps

export default React.memo(DocumentViewer)
