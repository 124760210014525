import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'

const propTypes = {
  value: PropTypes.string.isRequired,
  entry: Types.legendEntry.isRequired,
}

const defaultProps = {}

function CustomLegend({ value, entry }) {
  return <div style={{ color: entry.color }}>{value}</div>
}

CustomLegend.propTypes = exact(propTypes)
CustomLegend.defaultProps = defaultProps

export default React.memo(CustomLegend)
