import React from 'react'
import PropTypes from 'prop-types'
import { IonContent, IonPage } from '@ionic/react'
import { IonFlowHeader, PageTitle } from 'components'
import * as documentActions from './documents/actions'
import { compose } from 'redux'
import { connect } from 'react-redux'

const propTypes = {
  children: PropTypes.node.isRequired,
  resetDocument: PropTypes.func.isRequired,
  title: PropTypes.string,
  htmlTitle: PropTypes.string,
}
const defaultProps = {
  title: null,
  htmlTitle: null,
}

function UploadFlowLayout({ children, resetDocument, title, htmlTitle }) {
  return (
    <IonPage className="upload-flow-page">
      <PageTitle title={`${htmlTitle || title} - Document Upload`} />
      <IonFlowHeader title={title} resetDocument={resetDocument} />
      <IonContent>
        <div className="flow-content-wrapper">{children}</div>
      </IonContent>
    </IonPage>
  )
}

UploadFlowLayout.propTypes = propTypes
UploadFlowLayout.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  resetDocument: documentActions.resetDocument,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UploadFlowLayout
)
