import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { downloadOutline } from 'ionicons/icons'
import { IonButton, IonIcon } from '@ionic/react'
import { downloadFileWithFetch } from 'utils'
import { useToast } from 'hooks'

const propTypes = {
  url: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
}

const defaultProps = {}

function DownloadCsvButton({ url, fileName }) {
  const { showFailureToast } = useToast()

  return (
    <IonButton
      onClick={() =>
        downloadFileWithFetch({
          url,
          fileName,
          setError: (message) => showFailureToast({ message }),
        })
      }
      fill="outline"
    >
      <IonIcon slot="start" icon={downloadOutline} />
      Download CSV
    </IonButton>
  )
}

DownloadCsvButton.propTypes = exact(propTypes)
DownloadCsvButton.defaultProps = defaultProps

export default React.memo(DownloadCsvButton)
