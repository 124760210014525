import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  IonRadio,
  IonRadioGroup,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react'
import TableSection from './TableSection'
import {
  PROFIT_LINE_ITEMS,
  EXPENSES_TABLE_TYPES,
  PROFIT_LOSS_MOBILE_SEGMENT,
  YTD_OPTION,
  FINANCIAL_PERFORMANCE_TABLE_COLUMN,
  PROFIT_LOSS_SINGLE_MONTH_SETTINGS,
  PROFIT_LOSS_AMOUNT_KEY_SUBSTRING,
  PROFIT_LOSS_AMOUNT_KEY_SUBSTRINGS,
} from 'config'
import { capitalize, find, map, partition, some } from 'lodash'

const propTypes = {
  milesData: PropTypes.arrayOf(Types.profitAndLossRecord).isRequired,
  revenueData: PropTypes.arrayOf(Types.profitAndLossRecord).isRequired,
  expensesData: Types.profitAndLossExpenses.isRequired,
  liabilitiesData: PropTypes.arrayOf(Types.profitAndLossRecord).isRequired,
  year: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  hideNonAmountData: PropTypes.bool,
}

const defaultProps = {
  hideNonAmountData: false,
}

function ProfitLossMobileTable({
  milesData,
  revenueData,
  expensesData,
  liabilitiesData,
  year,
  period,
  hideNonAmountData,
}) {
  const [selectedSegment, setSelectedSegment] = useState(
    PROFIT_LOSS_MOBILE_SEGMENT.MILES.name
  )
  const [expensesOption, setExpensesOption] = useState(
    EXPENSES_TABLE_TYPES.VARIABLE
  )

  const [profitData, fixedExpensesData] = partition(expensesData.fixed, (row) =>
    some(PROFIT_LINE_ITEMS, (profitLineItem) =>
      row.name.includes(profitLineItem)
    )
  )
  const segmentConfig = find(PROFIT_LOSS_MOBILE_SEGMENT, {
    name: selectedSegment,
  })

  const getTableData = () => {
    switch (selectedSegment) {
      case PROFIT_LOSS_MOBILE_SEGMENT.MILES.name:
        return milesData
      case PROFIT_LOSS_MOBILE_SEGMENT.REVENUE.name:
        return revenueData
      case PROFIT_LOSS_MOBILE_SEGMENT.EXPENSES.name:
        if (expensesOption === EXPENSES_TABLE_TYPES.FIXED)
          return fixedExpensesData
        if (expensesOption === EXPENSES_TABLE_TYPES.LIABILITIES)
          return liabilitiesData
        return expensesData.variable
      case PROFIT_LOSS_MOBILE_SEGMENT.PROFIT.name:
        return profitData
    }
  }

  const getAccessorKey = (keySubstring) => {
    if (period !== YTD_OPTION.periodSelectorValue)
      return `${period}${keySubstring}`

    const substring = keySubstring.replace('Amount', '')
    return `${FINANCIAL_PERFORMANCE_TABLE_COLUMN.TOTAL.value}${substring}`
  }

  return (
    <div>
      <IonSegment
        mode="ios"
        onIonChange={(e) => setSelectedSegment(e.detail.value)}
        value={selectedSegment}
      >
        {map(PROFIT_LOSS_MOBILE_SEGMENT, ({ name }) => (
          <IonSegmentButton key={name} value={name}>
            {capitalize(name)}
          </IonSegmentButton>
        ))}
      </IonSegment>
      {selectedSegment === PROFIT_LOSS_MOBILE_SEGMENT.EXPENSES.name && (
        <IonRadioGroup
          value={expensesOption}
          onIonChange={(e) => {
            setExpensesOption(e.detail.value)
          }}
          mode="md"
          className="radio-toggle"
        >
          {map(EXPENSES_TABLE_TYPES, (value) => (
            <IonRadio
              key={value}
              value={value}
              labelPlacement="end"
              color="tertiary"
            >
              {capitalize(value)}
            </IonRadio>
          ))}
        </IonRadioGroup>
      )}
      {segmentConfig.tables.map(
        ({
          label,
          accessorKeySubstring,
          isCurrency,
          isPercentage,
          isUnits,
          doNotApplyRowTotalsStyle,
        }) => {
          if (
            hideNonAmountData &&
            !PROFIT_LOSS_AMOUNT_KEY_SUBSTRINGS.includes(accessorKeySubstring)
          ) {
            return null
          }

          const columns = PROFIT_LOSS_SINGLE_MONTH_SETTINGS({
            period,
            year,
            accessorKey: getAccessorKey(accessorKeySubstring),
            isCurrency,
            isPercentage,
            isUnits,
            isAmountValue:
              accessorKeySubstring === PROFIT_LOSS_AMOUNT_KEY_SUBSTRING.AMOUNT,
          })

          return (
            <TableSection
              key={label}
              label={label}
              columns={columns}
              data={getTableData()}
              doNotApplyRowTotalsStyle={doNotApplyRowTotalsStyle}
              disableColumnPinning
            />
          )
        }
      )}
    </div>
  )
}

ProfitLossMobileTable.propTypes = exact(propTypes)
ProfitLossMobileTable.defaultProps = defaultProps

export default React.memo(ProfitLossMobileTable)
