import React from 'react'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { IonList, IonItem, IonLabel, IonIcon } from '@ionic/react'
import { downloadOutline } from 'ionicons/icons'
import { selectors as globalSelectors } from 'global-reducer'
import {
  USER_TYPE,
  RESOURCES_FOLDER_ID,
  RESOURCES_FOLDER_FOR_USER_TYPE,
} from 'config'
import { compose } from 'redux'
import { connect } from 'react-redux'

const propTypes = {
  currentUser: Types.user.isRequired,
}

const defaultProps = {}

function Resources({ currentUser }) {
  const resourcesFolderId =
    RESOURCES_FOLDER_FOR_USER_TYPE[currentUser.clientCustomerType] ??
    RESOURCES_FOLDER_ID[USER_TYPE.PRO]
  const googleDriveEmbedUrl = `https://drive.google.com/embeddedfolderview?id=${resourcesFolderId}#list`

  return (
    <div className="resources-wrapper">
      <header>
        <h2>Find Documents</h2>
        <p>Download these FREE tools to help you run your business:</p>
      </header>
      <IonList>
        <IonItem
          target="_blank"
          rel="noreferrer"
          href="https://drive.google.com/file/d/17ybIyrBa0Zsi6pIR2Zb6GtL0hP2eLTqk/view?usp=sharing"
          className="item-link"
          lines="none"
          expand="block"
          detail="false"
        >
          <IonIcon
            color="primary"
            icon={downloadOutline}
            aria-hidden="true"
          ></IonIcon>
          <IonLabel>Tax Deadline Calendar</IonLabel>
        </IonItem>
        <IonItem
          target="_blank"
          rel="noreferrer"
          href="https://drive.google.com/file/d/1M8De4-DWy56YPT0ZJJb5_m7jXK3tvM2a/view?usp=sharing"
          className="item-link"
          lines="none"
          expand="block"
          detail="false"
        >
          <IonIcon
            color="primary"
            icon={downloadOutline}
            aria-hidden="true"
          ></IonIcon>
          <IonLabel>Owner-Operator Deduction Quick list</IonLabel>
        </IonItem>
        <IonItem className="drive-embed" lines="none">
          <iframe
            title="ATBS Client Resources"
            src={googleDriveEmbedUrl}
            style={{
              width: '100%',
              height: '250px',
              border: '0',
              padding: '12px 0',
            }}
          ></iframe>
        </IonItem>
      </IonList>
    </div>
  )
}

Resources.propTypes = exact(propTypes)
Resources.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Resources)
