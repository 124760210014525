import { handleActions } from 'redux-actions'
import { selectorForSlice, setState, unsetState } from 'lp-redux-utils'
import { setOnSuccess } from 'lp-redux-api'
import * as actions from './actions'
import * as apiActions from 'api-actions'
import { findLast } from 'lodash'
import { createSelector } from 'reselect'
import { DOCUMENT_PROCESSED_STATUS, DOCUMENT_CATEGORY } from 'config'

const reducerKey = 'documents'
const slice = 'root.documents'

const initialState = {
  files: [],
  title: '',
  documentFilter: DOCUMENT_CATEGORY.RECEIVED,
}

const reducer = handleActions(
  {
    [actions.addFileToDocument]: setState('files', (action, _, files) => {
      const { name, fileName, ...rest } = action.payload
      const updatedFiles = [...files, { ...rest, fileName: fileName ?? name }]
      return updatedFiles
    }),
    [actions.addNoteToFile]: setState(
      'files',
      ({ payload: { note, fileName } }, _, files) => {
        const updatedFiles = [...files] // copy so that redux sees it as new state
        // Update last file in case there are multiple files with the same fileName
        const fileToUpdate = findLast(
          updatedFiles,
          (file) => file.fileName === fileName
        )
        if (!fileToUpdate) return updatedFiles

        fileToUpdate.note = note
        return updatedFiles
      }
    ),
    [actions.removeFileFromDocument]: setState('files', (action, _, files) => {
      const indexToRemove = action.payload
      const updatedFiles = [
        ...files.slice(0, indexToRemove),
        ...files.slice(indexToRemove + 1),
      ]
      return updatedFiles
    }),
    [actions.clearFiles]: setState('files', []),
    [actions.setCaptureMethod]: setState('method'),
    [actions.setTitle]: setState('title'),
    [apiActions.fetchDocumentByID]: setOnSuccess('document'),
    [actions.clearDocument]: unsetState('document'),
    [actions.setUploadedPhoto]: setState('uploadedPhoto'),
    [actions.clearUploadedPhoto]: unsetState('uploadedPhoto'),
    [apiActions.fetchDocuments]: setOnSuccess('rawDocuments'),
    [actions.setDocumentFilter]: setState('documentFilter'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  files: select('files'),
  method: select('method'),
  title: select('title'),
  document: select('document'),
  uploadedPhoto: select('uploadedPhoto'),
  rawDocuments: select('rawDocuments'),
  documentFilter: select('documentFilter'),
}

// Computed

selectors.documents = createSelector(
  [selectors.rawDocuments],
  (rawDocuments) => {
    if (!rawDocuments) return

    return rawDocuments.map(({ attributes }) => attributes)
  }
)

selectors.receivedDocs = createSelector([selectors.documents], (documents) => {
  if (!documents) return

  return documents.filter(({ status }) => status !== DOCUMENT_PROCESSED_STATUS)
})

selectors.postedDocs = createSelector([selectors.documents], (documents) => {
  if (!documents) return

  return documents.filter(({ status }) => status === DOCUMENT_PROCESSED_STATUS)
})

selectors.filteredDocs = createSelector(
  [selectors.receivedDocs, selectors.postedDocs, selectors.documentFilter],
  (receivedDocs, postedDocs, documentFilter) => {
    if (!receivedDocs || !postedDocs || !documentFilter) return

    return documentFilter === DOCUMENT_CATEGORY.RECEIVED
      ? receivedDocs
      : postedDocs
  }
)

export { reducer, selectors, reducerKey }
