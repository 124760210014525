import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { LoadingSpinner } from 'components'
import classnames from 'classnames'
import { useToast } from 'hooks'
import { IonIcon, IonButton, isPlatform } from '@ionic/react'
import { warningOutline } from 'ionicons/icons'
import { APP_PATH } from 'config'

const propTypes = {
  postFlinksData: PropTypes.func.isRequired,
}

const defaultProps = {}

function FlinksConnect({ postFlinksData }) {
  const [loaded, setLoaded] = useState(false)
  const { showFailureToast } = useToast()

  const saveData = useCallback(
    async ({ loginId, institution }) => {
      try {
        await postFlinksData({ loginId, institution })
      } catch {
        showFailureToast({
          message: 'There was an issue saving your bank token.',
          callback: () => saveData({ loginId, institution }),
        })
      }
    },
    [postFlinksData, showFailureToast]
  )

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.step === 'APP_MOUNTED') setLoaded(true)
      if (event.data.step === 'REDIRECT') {
        const { loginId, institution } = event.data
        saveData({ loginId, institution })
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [saveData])

  if (!isPlatform('desktop')) {
    return (
      <div className="centered-content full-page">
        <IonIcon icon={warningOutline} />
        <h2>We apologize for the inconvenience</h2>
        <p>
          Due to system restrictions, ATBS app users must connect to their bank
          through their <em>desktops</em>. Please complete the bank connection
          process on a desktop device by visiting <strong>hub.atbs.com</strong>.
        </p>
        <IonButton
          fill="outline"
          routerLink={APP_PATH.HOME}
          routerDirection="back"
        >
          Return Home
        </IonButton>
      </div>
    )
  }

  return (
    <>
      {!loaded && <LoadingSpinner />}
      <div className="flinks-container">
        <iframe
          id="flinks"
          title="Connect to your bank institution via Flinks"
          className={classnames('flinks-connect', { loaded })}
          src={process.env.FLINKS_IFRAME_URL}
        ></iframe>
      </div>
    </>
  )
}

FlinksConnect.propTypes = propTypes
FlinksConnect.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  postFlinksData: apiActions.postFlinksData,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FlinksConnect
)
