import React, { useState, useEffect } from 'react'
import Routes from './Routes'
import store from 'store'
import { Provider } from 'react-redux'
import { IonReactRouter } from '@ionic/react-router'
import { IonApp, setupIonicReact } from '@ionic/react'
import { initializeStorage } from 'local-storage'
import { pdfjs } from 'react-pdf'
import { LoadingSpinner } from 'components'
import { isWebsite } from 'utils'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { isProduction, FIREBASE_CONFIG, SMARTLOOK_CONFIG } from 'config'
import { HelmetProvider } from 'react-helmet-async'
import SmartlookWebsite from 'smartlook-client'
import { Smartlook as SmartlookNativeApp } from '@awesome-cordova-plugins/smartlook'
import {
  clearLocalStorageTrip,
  clearLocalStoragePosition,
  clearLocalStorageWatcherId,
} from '../services/mileageTrackerStorage'

// Log runtime errors with sentry service
import 'sentry'

// Core CSS required for Ionic components to work properly
import '@ionic/react/css/core.css'

// Basic CSS for apps built with Ionic
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

// Application-specific styles
import '../../scss/application.scss'

function App() {
  const [localStorage, setLocalStorage] = useState()

  // Mandatory Ionic initialization. See https://ionicframework.com/docs/react/config
  // for more information on setting a global Ionic app configuration.
  setupIonicReact({
    platform: {
      /** The default `desktop` function returns false for devices with a touchscreen.
       * This is not always wanted, so this function tests the User Agent instead.
       **/
      desktop: (win) => {
        const isMobile =
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            win.navigator.userAgent
          )
        return !isMobile
      },
    },
  })

  // Set up Analytics
  useEffect(() => {
    const initializeAnalytics = async () => {
      if (isWebsite()) {
        FirebaseAnalytics.initializeFirebase({
          apiKey: FIREBASE_CONFIG.API_KEY,
          authDomain: 'atbs-app.firebaseapp.com',
          databaseURL: 'https://atbs-app.firebaseio.com',
          projectId: 'atbs-app',
          storageBucket: 'atbs-app.appspot.com',
          messagingSenderId: FIREBASE_CONFIG.SENDER_ID,
          appId: FIREBASE_CONFIG.APP_ID,
          measurementId: FIREBASE_CONFIG.MEASUREMENT_ID,
        })
        SmartlookWebsite.init(SMARTLOOK_CONFIG.PROJECT_KEY)
      } else {
        SmartlookNativeApp.setProjectKey({ key: SMARTLOOK_CONFIG.PROJECT_KEY })
        SmartlookNativeApp.start()
      }
    }

    if (isProduction()) {
      initializeAnalytics()
    }
  }, [])

  // Set up react-pdf
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url
  ).toString()

  defineCustomElements(window)

  useEffect(() => {
    setLocalStorage(initializeStorage())
  }, [])

  // Since it's possible mileage tracking was running when the app was killed, remove any trip data from storage when the app is cold started:
  useEffect(() => {
    clearLocalStorageTrip()
    clearLocalStoragePosition()
    clearLocalStorageWatcherId()
  }, [])

  return (
    <HelmetProvider>
      <Provider store={store}>
        <IonApp>
          <IonReactRouter>
            {!localStorage ? <LoadingSpinner /> : <Routes />}
          </IonReactRouter>
        </IonApp>
      </Provider>
    </HelmetProvider>
  )
}

export default App
