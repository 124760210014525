import { some } from 'lodash'
import { FINANCIAL_PERFORMANCE_TOTAL_ROWS } from 'config'

function isTotalRow(tableRowName) {
  return some(FINANCIAL_PERFORMANCE_TOTAL_ROWS, (totalRowName) =>
    tableRowName.toLowerCase().includes(totalRowName)
  )
}

export default isTotalRow
