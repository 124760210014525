import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Helmet } from 'react-helmet-async'
import { useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react'

const propTypes = {
  title: PropTypes.string.isRequired,
  forceUpdate: PropTypes.bool,
}

const defaultProps = {
  forceUpdate: false,
}

// Used to set the title attribute in the HTML head
function PageTitle({ title, forceUpdate }) {
  const [updatePageTitle, setUpdatePageTitle] = useState(forceUpdate)

  useIonViewWillEnter(() => {
    /* Must only update the title on view will enter
    (unless forceUpdate == true) as Ionic keeps a stack of views
    and we don't want other views in the stack that aren't visible
    modifying the page title */
    setUpdatePageTitle(true)
  }, [])

  useIonViewWillLeave(() => {
    setUpdatePageTitle(false)
  }, [])

  if (!updatePageTitle) return null

  return (
    <Helmet>
      <title>{title} - ATBS</title>
    </Helmet>
  )
}

PageTitle.propTypes = exact(propTypes)
PageTitle.defaultProps = defaultProps

export default React.memo(PageTitle)
