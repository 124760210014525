import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  FINANCIAL_PERFORMANCE_TABLE_COLUMN,
  BALANCE_SHEET_BASE_COLUMN_SETTINGS,
  FINANCIAL_PERFORMANCE_SECTION,
} from 'config'
import { TableRowCell, TableSection } from '../components'
import { map, snakeCase, toUpper } from 'lodash'
import { useSynchronizedScroll } from 'hooks'

const propTypes = {
  data: Types.balanceSheet.isRequired,
  year: PropTypes.string.isRequired,
}

const defaultProps = {}

const getTableContainer = (el) => el.parentElement
function BalanceSheetDesktopTable({ data, year }) {
  useSynchronizedScroll('table', getTableContainer)

  const baseColumnSettings = BALANCE_SHEET_BASE_COLUMN_SETTINGS()
  const nameColumn = {
    header: FINANCIAL_PERFORMANCE_TABLE_COLUMN.NAME.name,
    accessorKey: FINANCIAL_PERFORMANCE_TABLE_COLUMN.NAME.value,
    minSize: 125, //min size enforced during resizing
    size: 180, //medium column
    columns: [
      {
        header: FINANCIAL_PERFORMANCE_TABLE_COLUMN.NAME.name,
        accessorKey: FINANCIAL_PERFORMANCE_TABLE_COLUMN.NAME.value,
        minSize: 125, //min size enforced during resizing
        size: 180, //medium column
        Cell: ({ cell, row }) => (
          <TableRowCell {...{ cell, row, data, year }} />
        ),
      },
    ],
  }
  const columns = [nameColumn, ...baseColumnSettings]

  return map(data, (categories, sectionName) => (
    <TableSection
      key={sectionName}
      label={FINANCIAL_PERFORMANCE_SECTION[toUpper(snakeCase(sectionName))]}
      columns={columns}
      data={categories}
    />
  ))
}

BalanceSheetDesktopTable.propTypes = exact(propTypes)
BalanceSheetDesktopTable.defaultProps = defaultProps

export default React.memo(BalanceSheetDesktopTable)
