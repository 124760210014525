import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Link } from 'react-router-dom'
import { IonImg } from '@ionic/react'
import ATBSLogo from 'images/atbs-logo.svg'
import ATBSLogoWhite from 'images/atbs-logo-white.svg'
import { APP_PATH, LOGO_COLOR } from 'config'
import { values } from 'lodash'

const propTypes = {
  color: PropTypes.oneOf(values(LOGO_COLOR)),
  path: PropTypes.string,
}

const defaultProps = {
  color: LOGO_COLOR.DARK,
  path: APP_PATH.HOME,
}

function Logo({ color, path }) {
  const image = {
    [LOGO_COLOR.DARK]: ATBSLogo,
    [LOGO_COLOR.LIGHT]: ATBSLogoWhite,
  }

  return (
    <Link to={path} aria-label="Navigate home">
      <IonImg src={image[color]} className="atbs-logo" alt="" />
    </Link>
  )
}

Logo.propTypes = exact(propTypes)
Logo.defaultProps = defaultProps

export default React.memo(Logo)
