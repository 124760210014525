import React from 'react'
import PropTypes from 'prop-types'
import { IonDatetime, IonNote } from '@ionic/react'
import { withFormikAdapter } from 'utils'

const propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  onIonChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
}

const defaultProps = {
  error: null,
  multiple: false,
  value: null,
}

function DatetimeInput({ name, error, onIonChange, value, multiple, ...rest }) {
  return (
    <div className="datetime-wrapper">
      <IonDatetime
        name={name}
        onIonChange={(e) => {
          // Since Formik expects values to be defined for validation, convert undefined to its initial value when date(s) are unselected
          if (e.target.value === undefined) {
            e.target.value = multiple ? [] : ''
          }
          return onIonChange(e)
        }}
        value={value === '' ? null : value} // Empty string is not a valid option for ion-datetime value
        multiple={multiple}
        {...rest}
      />
      <IonNote color="danger">{error}</IonNote>
    </div>
  )
}

DatetimeInput.propTypes = propTypes
DatetimeInput.defaultProps = defaultProps

export default withFormikAdapter()(DatetimeInput)
