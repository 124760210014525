import { createAction } from 'redux-actions'

export const addFileToDocument = createAction('ADD_FILE_TO_DOCUMENT')
export const addNoteToFile = createAction('ADD_NOTE_TO_FILE')
export const setCaptureMethod = createAction('SET_CAPTURE_METHOD')
export const removeFileFromDocument = createAction('REMOVE_FILE_FROM_DOCUMENT')
export const setTitle = createAction('SET_TITLE')
export const clearFiles = createAction('CLEAR_FILES')
export const clearDocument = createAction('CLEAR_DOCUMENT')
export const clearUploadedPhoto = createAction('CLEAR_UPLOADED_PHOTO')
export const setUploadedPhoto = createAction('SET_UPLOADED_PHOTO')
export const setDocumentFilter = createAction('SET_DOCUMENT_FILTER')

export const resetDocument = () => {
  return (dispatch) => {
    dispatch(clearFiles())
    dispatch(setTitle(''))
    dispatch(clearUploadedPhoto())
  }
}
