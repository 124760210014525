import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { IonIcon, IonItem } from '@ionic/react'
import { caretDown, caretForward } from 'ionicons/icons'
import { flatten, values, compact, kebabCase } from 'lodash'
import classnames from 'classnames'
import { APP_PATH } from 'config'

const propTypes = {
  cell: Types.tableCell.isRequired,
  row: Types.tableRow.isRequired,
  data: Types.balanceSheet,
  year: PropTypes.string,
}

const defaultProps = {
  data: null,
  year: null,
}

function TableRowCell({ cell, row, data, year }) {
  // row.getIsExpanded() was no longer updating correctly on mobile for some reason
  const [rowIsExpanded, setRowIsExpanded] = useState(row.getIsExpanded())
  const toggleRowExpansion = () => {
    row.toggleExpanded()
    setRowIsExpanded(!rowIsExpanded)
  }

  const findMatchingCategory = (categories) => {
    if (!categories) return null

    return categories.find(
      (category) =>
        category.internalAccountNumber && category.name === cell.renderValue()
    )
  }

  const getCategoryViewUrl = (matchingCategory) => {
    if (!matchingCategory || !year) return undefined

    return `${
      APP_PATH.FINANCIAL_PERFORMANCE.BS_CATEGORY_TRANSACTIONS
    }/${kebabCase(matchingCategory.name)}/${
      matchingCategory.internalAccountNumber
    }?year=${year}`
  }

  const categories = data && flatten(values(data))
  const matchingCategory = findMatchingCategory(categories)

  // style child row name cell
  if (row.parentId) {
    const subCategories =
      categories &&
      flatten(compact(categories.map((category) => category.children)))
    const matchingSubCategory = findMatchingCategory(subCategories)

    return (
      <IonItem
        button={!!matchingSubCategory}
        style={{ width: 'calc(100% - 28px)', paddingLeft: '28px' }}
        className={classnames({
          'clickable-cell': matchingSubCategory,
        })}
        routerLink={getCategoryViewUrl(matchingSubCategory)}
        detail="false"
      >
        {matchingSubCategory?.name || cell.getValue()}
      </IonItem>
    )
  }

  return (
    <div className="table-cell-align-center">
      {!!row.originalSubRows && (
        <IonIcon
          icon={rowIsExpanded ? caretDown : caretForward}
          onClick={toggleRowExpansion}
        />
      )}
      {matchingCategory ? (
        <IonItem
          button
          className="clickable-cell"
          routerLink={getCategoryViewUrl(matchingCategory)}
          detail="false"
        >
          {matchingCategory.name}
        </IonItem>
      ) : (
        cell.getValue()
      )}
    </div>
  )
}

TableRowCell.propTypes = propTypes
TableRowCell.defaultProps = defaultProps

export default React.memo(TableRowCell)
