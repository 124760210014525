import React, { useState, useEffect, useMemo } from 'react'
import { IonIcon, IonAlert, IonButton } from '@ionic/react'
import { attachOutline } from 'ionicons/icons'
import { isEmpty } from 'lodash'
import { validateFile, convertBase64ToBlob } from 'utils'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as documentActions from '../actions'
import { Capacitor } from '@capacitor/core'
import { Filesystem } from '@capacitor/filesystem'
import { APP_PATH, UPLOAD_PAGE_TYPE } from 'config'
import { LoadingSpinner } from 'components'
import { clearSharedDocument } from '../../../services/documentShare'
import mime from 'mime'

const propTypes = {}
const defaultProps = {}

const Status = {
  LOADING: 'loading',
  FAILED: 'failed',
  LOADED: 'loaded',
}

async function readImage(data) {
  const photo = {
    webPath: Capacitor.convertFileSrc(data.url),
    format: data.type,
    mimeType: mime.getType(data.title),
    fileName: data.title,
    note: data.description,
    saved: false,
  }
  return photo
}

async function readFile(data) {
  const file = await Filesystem.readFile({ path: data.url })
  const blob = convertBase64ToBlob(file.data, data.type)
  return {
    blob,
    fileName: data.title,
    mimeType: data.type,
    note: data.description,
  }
}

function useDocShare() {
  const location = useLocation()
  const [docState, setDocState] = useState({
    fileName: '',
    errors: [],
    status: Status.LOADING,
  })
  const dispatch = useDispatch()
  const history = useHistory()
  // Ionic doesn't unmount components as expected to help with transitions (even when using history.replace)
  // so we need to memoize the initial state passed in and not listen to further routing changes.
  const shareDetail = useMemo(() => location.state, []) // eslint-disable-line

  useEffect(() => {
    let ignore = false

    async function processDocument() {
      let file
      dispatch(documentActions.resetDocument())
      if (shareDetail.type.startsWith('image/')) {
        file = await readImage(shareDetail)
        // Ideally we would validate this here, but it's kind of tricky because is isn't a blob until _after_ it's cropped
        // To-date, there isn't validation applied to the manual image capture flow, so this is technically parity
        if (!ignore) {
          dispatch(documentActions.setUploadedPhoto(file))
          setDocState((current) => ({
            ...current,
            fileName: shareDetail.title,
            status: Status.LOADED,
          }))

          history.replace(
            `${APP_PATH.UPLOAD.CAMERA}/${UPLOAD_PAGE_TYPE.SINGLE.value}`
          )
        }
      } else {
        file = await readFile(shareDetail)
        const errors = validateFile(file)
        if (!ignore) {
          if (!isEmpty(errors)) {
            setDocState((current) => ({
              ...current,
              fileName: shareDetail.title,
              errors,
              status: Status.FAILED,
            }))
            return
          }
          dispatch(documentActions.addFileToDocument(file))
          setDocState((current) => ({
            ...current,
            fileName: shareDetail.title,
            status: Status.LOADED,
          }))

          history.replace(
            `${APP_PATH.UPLOAD.FILE}/${UPLOAD_PAGE_TYPE.SINGLE.value}`
          )
        }
      }
    }

    processDocument()
      .catch(() => {
        setDocState((current) => ({
          ...current,
          status: Status.FAILED,
          errors: [
            'Failed to process document. Please try again or upload through the in-app experience.',
          ],
        }))
      })
      .finally(() => clearSharedDocument())

    return function cleanup() {
      ignore = true
    }
  }, [shareDetail, dispatch, history])

  return docState
}

function getErrorMessage(errors) {
  return `The following error${
    errors.length > 1 ? 's' : ''
  } occurred: ${errors.join(' ')}`
}

function ShareManager() {
  const [showAlert, setShowAlert] = useState(false)
  const dispatch = useDispatch()
  const { errors, fileName, status } = useDocShare()

  const hasErrors = !isEmpty(errors)

  if (hasErrors && !showAlert) {
    setShowAlert(true)
  }

  return (
    <div className="splash-wrapper ion-padding">
      <div>
        <div className="icon-circle-bg">
          <IonIcon icon={attachOutline} size="large" />
        </div>
        <h2>Share Document</h2>
      </div>
      <div>
        {status === Status.LOADING && (
          <LoadingSpinner color="secondary">
            <p>Processing...</p>
          </LoadingSpinner>
        )}
        <IonAlert
          isOpen={showAlert}
          backdropDismiss={false}
          header="Unable to process"
          subHeader={fileName}
          message={getErrorMessage(errors)}
          buttons={['Ok']}
          onDidDismiss={() => {
            setShowAlert(false)
          }}
        />
        {status === Status.FAILED && (
          <IonButton
            color="secondary"
            expand="block"
            routerLink={APP_PATH.HOME}
            routerDirection="root"
            onClick={() => dispatch(documentActions.resetDocument())}
          >
            Return Home
          </IonButton>
        )}
      </div>
    </div>
  )
}

ShareManager.propTypes = propTypes
ShareManager.defaultProps = defaultProps

export default React.memo(ShareManager)
