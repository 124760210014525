import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ReactPaginate from 'react-paginate'
import { isPlatform } from '@ionic/core'

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemComponent: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number,
}

const defaultProps = {
  itemsPerPage: 10,
}

function PaginatedItems({ items, itemsPerPage, itemComponent: ItemComponent }) {
  const isTablet = isPlatform('tablet')
  const isMobile = isPlatform('mobile') && !isTablet
  const getPageRangeForPlatform = () => {
    if (isMobile) return 1
    if (isTablet) return 3
    return 5
  }
  const getMarginPagesForPlatform = () => {
    if (isMobile) return 1
    if (isTablet) return 2
    return 3
  }
  const [itemOffset, setItemOffset] = useState(0)

  const endOffset = itemOffset + itemsPerPage
  const currentItems = items.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(items.length / itemsPerPage)

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length
    setItemOffset(newOffset)
  }

  return (
    <div className="pagination-items-wrapper">
      {currentItems.map((item, index) => {
        return <ItemComponent key={index} item={item} />
      })}
      <ReactPaginate
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={getPageRangeForPlatform()}
        marginPagesDisplayed={getMarginPagesForPlatform()}
        pageCount={pageCount}
        previousLabel="<"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </div>
  )
}

PaginatedItems.propTypes = exact(propTypes)
PaginatedItems.defaultProps = defaultProps

export default React.memo(PaginatedItems)
