import React from 'react'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
} from '@ionic/react'
import { CELL_FORMATTING, APP_PATH, CURRENT_YEAR } from 'config'
import { findRowByName } from 'utils'

const propTypes = {
  profitAndLoss: Types.profitAndLoss,
}

const defaultProps = {
  profitAndLoss: null,
}

function ProfitAndLossCard({ profitAndLoss }) {
  const yearToDateTotal = profitAndLoss
    ? findRowByName(profitAndLoss.fixed, 'Net Income Before Tax')?.yearToDate ??
      0
    : null

  const formattedValue = yearToDateTotal?.toLocaleString(
    'en-US',
    CELL_FORMATTING.CURRENCY
  )
  return (
    <IonCard routerLink={APP_PATH.FINANCIAL_PERFORMANCE.PROFIT_AND_LOSS} button>
      <IonCardContent>
        <IonCardTitle>Profit & Loss</IonCardTitle>
        <p>{formattedValue ?? '...'}</p>
        <IonCardSubtitle>{CURRENT_YEAR} YTD Profit</IonCardSubtitle>
      </IonCardContent>
    </IonCard>
  )
}

ProfitAndLossCard.propTypes = exact(propTypes)
ProfitAndLossCard.defaultProps = defaultProps

export default React.memo(ProfitAndLossCard)
