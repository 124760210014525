import React from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import { SubmitButton, TextInput, CheckboxInput } from 'components'
import * as Yup from 'yup'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { clearSessionToken, getAccount } from 'auth'
import { redirectAfterSignIn, isNativeApp } from 'utils'
import { SavePassword } from 'capacitor-ios-autofill-save-password'
import { isPlatform } from '@ionic/react'
import { isString } from 'lodash'

const propTypes = {
  logIn: PropTypes.func.isRequired,
}

const defaultProps = {}

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Email must be valid')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
  rememberMe: Yup.bool(),
})

function SignInForm({ logIn }) {
  return (
    <Formik
      initialValues={{
        email: getAccount() || '',
        password: '',
        rememberMe: false,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors }) => {
        clearSessionToken()
        try {
          await logIn(values)
          // Trigger Keychain save password functionality for iOS native app
          if (isPlatform('ios') && isNativeApp()) {
            await SavePassword.promptDialog({
              username: values.email,
              password: values.password,
            })
          }
          redirectAfterSignIn()
        } catch (error) {
          const errorMessage = isString(error) ? error : error.message
          setErrors({
            email: errorMessage,
            password: errorMessage,
          })
        }
      }}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form className="sign-in-form ion-padding" noValidate>
          <TextInput
            name="email"
            type="email"
            inputmode="email"
            autocomplete="email"
          />
          <TextInput
            name="password"
            type="password"
            inputmode="text"
            autocomplete="current-password"
          />
          <CheckboxInput
            name="rememberMe"
            label="Keep me logged in"
            labelPlacement="end"
          />
          <SubmitButton color="secondary" submitting={isSubmitting}>
            Sign In
          </SubmitButton>
        </Form>
      )}
    </Formik>
  )
}

SignInForm.propTypes = propTypes
SignInForm.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  logIn: apiActions.logIn,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SignInForm)
