import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { IonGrid, IonImg, IonRow } from '@ionic/react'
import { PageTitle } from 'components'
import ATBSLogo from 'images/atbs-logo.svg'

const propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function SignInHeader({ title, children }) {
  return (
    <>
      <PageTitle title={title} />
      <IonGrid className="sign-in-header ion-text-center ion-padding">
        <IonRow className="ion-justify-content-center">
          <IonImg src={ATBSLogo} alt="" />
        </IonRow>
        <IonRow className="sign-in-header-inner">
          <h1 className="ion-text-center">{title}</h1>
          <IonRow className="ion-justify-content-center">{children}</IonRow>
        </IonRow>
      </IonGrid>
    </>
  )
}

SignInHeader.propTypes = exact(propTypes)
SignInHeader.defaultProps = defaultProps

export default React.memo(SignInHeader)
