function findRowByName(rows, rowName) {
  if (!rows) return

  const normalizedRowName = normalizeName(rowName)
  return rows.find((row) => normalizeName(row.name) === normalizedRowName)
}

function normalizeName(name) {
  return name.toLowerCase()
}

export default findRowByName
