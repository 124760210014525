import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { IonCard, IonCardHeader, IonCardTitle } from '@ionic/react'
import { CELL_FORMATTING } from 'config'

const propTypes = {
  miles: PropTypes.number.isRequired,
  savings: PropTypes.number.isRequired,
}

const defaultProps = {}

function MileageSummaryCard({ miles, savings }) {
  return (
    <IonCard className="all-trips-summary-card">
      <IonCardHeader>
        <IonCardTitle>Summary</IonCardTitle>
      </IonCardHeader>
      <dl>
        <div className="column">
          <dt>Total Miles</dt>
          <dd>{miles.toLocaleString()}</dd>
        </div>
        <div className="column">
          <dt>Total Savings</dt>
          <dd>{savings.toLocaleString('en-US', CELL_FORMATTING.CURRENCY)}</dd>
        </div>
      </dl>
    </IonCard>
  )
}

MileageSummaryCard.propTypes = exact(propTypes)
MileageSummaryCard.defaultProps = defaultProps

export default MileageSummaryCard
