import React, { useEffect } from 'react'
import * as Types from 'types'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
} from '@ionic/react'
import { CELL_FORMATTING, APP_PATH, CURRENT_YEAR } from 'config'

const propTypes = {
  fetchHomePerDiem: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  perDiem: Types.perDiem,
}

const defaultProps = {
  perDiem: null,
}

function PerDiemCard({ fetchHomePerDiem, perDiem, setError }) {
  useEffect(() => {
    const requestPerDiem = async () => {
      try {
        await fetchHomePerDiem()
      } catch (errorMessage) {
        setError(
          `There was an issue loading your Per Diem total: ${errorMessage}`
        )
      }
    }

    requestPerDiem()
  }, [fetchHomePerDiem, setError])

  const yearToDateTotal = perDiem?.yearToDateDeductions?.toLocaleString(
    'en-US',
    CELL_FORMATTING.CURRENCY
  )

  return (
    <IonCard routerLink={APP_PATH.PER_DIEM} button>
      <IonCardContent>
        <IonCardTitle>Per Diem</IonCardTitle>
        <p>{yearToDateTotal ?? '...'}</p>
        <IonCardSubtitle>{CURRENT_YEAR} YTD Total</IonCardSubtitle>
      </IonCardContent>
    </IonCard>
  )
}

PerDiemCard.propTypes = exact(propTypes)
PerDiemCard.defaultProps = defaultProps

export default React.memo(PerDiemCard)
