import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonList,
  IonRouterLink,
} from '@ionic/react'
import {
  DocumentStatusLineItem,
  NoDocumentContent,
  LoadingSpinner,
} from 'components'
import { isEmpty } from 'lodash'
import { APP_PATH } from 'config'

const propTypes = {
  fetchLatestDocs: PropTypes.func.isRequired,
  latestDocs: PropTypes.arrayOf(Types.doc),
}

const defaultProps = {
  latestDocs: null,
}

function RecentDocumentsCard({ fetchLatestDocs, latestDocs }) {
  const [error, setError] = useState()

  useEffect(() => {
    const requestLatestDocs = async () => {
      try {
        await fetchLatestDocs()
      } catch (errorMessage) {
        setError(
          `There was an issue fetching your recent documents: ${errorMessage}`
        )
      }
    }

    requestLatestDocs()
  }, [fetchLatestDocs])

  return (
    <IonCard className="documents-list-card">
      <IonCardHeader>
        <IonCardTitle className="ion-text-center">
          Recent Documents
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent className="ion-text-center">
        {error ? (
          <div className="centered-content">{error}</div>
        ) : !latestDocs ? (
          <LoadingSpinner />
        ) : isEmpty(latestDocs) ? (
          <NoDocumentContent />
        ) : (
          <>
            <IonList>
              {latestDocs.map((doc) => {
                return <DocumentStatusLineItem key={doc.guid} doc={doc} />
              })}
            </IonList>
            <IonRouterLink routerLink={APP_PATH.DOCUMENTS.ALL}>
              View All
            </IonRouterLink>
          </>
        )}
      </IonCardContent>
    </IonCard>
  )
}

RecentDocumentsCard.propTypes = exact(propTypes)
RecentDocumentsCard.defaultProps = defaultProps

export default React.memo(RecentDocumentsCard)
