import React from 'react'
import PropTypes from 'prop-types'
import { IonSelect, IonSelectOption } from '@ionic/react'
import * as Types from 'types'
import { startCase } from 'lodash'

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(Types.inputOption).isRequired,
  onIonChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelPlacement: PropTypes.oneOf([
    'end',
    'fixed',
    'floating',
    'stacked',
    'start',
  ]),
  placeholder: PropTypes.string,
}

const defaultProps = {
  labelPlacement: 'stacked',
  placeholder: '',
  label: '',
}

function StandaloneSelectInput({
  name,
  value,
  options,
  onIonChange,
  label,
  placeholder,
  ...rest
}) {
  return (
    <IonSelect
      name={name}
      value={value}
      onIonChange={onIonChange}
      label={label}
      placeholder={placeholder}
      fill="outline"
      interface="popover"
      justify="start"
      {...rest}
    >
      {options.map((option) => {
        const { name = startCase(option), value = option } = option
        return (
          <IonSelectOption key={value} value={value}>
            {name}
          </IonSelectOption>
        )
      })}
    </IonSelect>
  )
}

StandaloneSelectInput.propTypes = propTypes
StandaloneSelectInput.defaultProps = defaultProps

export default React.memo(StandaloneSelectInput)
