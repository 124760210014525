import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  PROFIT_LOSS_MONTHLY_NESTING_SETTINGS,
  PROFIT_LOSS_SECTION,
} from 'config'
import { map } from 'lodash'
import TableSection from './TableSection'
import { useSynchronizedScroll } from 'hooks'

const propTypes = {
  tableData: Types.tableData.isRequired,
  year: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  hideNonAmountData: PropTypes.bool,
}

const defaultProps = {
  hideNonAmountData: false,
}

const getTableContainer = (el) => el.parentElement
function ProfitLossMonthlyDesktopTable({
  tableData,
  year,
  period,
  hideNonAmountData,
}) {
  useSynchronizedScroll('table', getTableContainer)

  return map(PROFIT_LOSS_SECTION, (sectionName) => {
    const columns = PROFIT_LOSS_MONTHLY_NESTING_SETTINGS({
      year,
      isMilesSection: sectionName === PROFIT_LOSS_SECTION.MILES,
      isCurrency: sectionName !== PROFIT_LOSS_SECTION.MILES,
      period,
      hideNonAmountData,
    })

    return (
      <TableSection
        key={sectionName}
        label={sectionName}
        columns={columns}
        data={tableData[sectionName]}
      />
    )
  })
}

ProfitLossMonthlyDesktopTable.propTypes = exact(propTypes)
ProfitLossMonthlyDesktopTable.defaultProps = defaultProps

export default React.memo(ProfitLossMonthlyDesktopTable)
