import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  ResponsiveContainer,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ComposedChart,
  Tooltip,
} from 'recharts'
import { isPlatform } from '@ionic/react'
import { CELL_FORMATTING, YTD_OPTION, BALANCE_SHEET_CATEGORY } from 'config'
import { isEmpty, camelCase, map } from 'lodash'
import * as Types from 'types'
import { CustomLegend, CustomTooltip } from '../components'

const propTypes = {
  data: PropTypes.arrayOf(Types.balanceSheetChartPeriod).isRequired,
  period: PropTypes.string.isRequired,
}

const defaultProps = {}

function BalanceSheetBarChart({ data, period }) {
  if (isEmpty(data)) {
    return <div className="centered-content">Please select a period</div>
  }

  const isYTD = period === YTD_OPTION.periodSelectorValue

  const formatCurrencyTicks = (value) => {
    return value.toLocaleString('en-US', CELL_FORMATTING.CURRENCY)
  }

  return (
    <ResponsiveContainer width="100%" minHeight={500}>
      <ComposedChart
        width={600}
        height={300}
        data={data}
        margin={
          isPlatform('desktop')
            ? { top: 20, right: 50, left: 70, bottom: 15 }
            : { top: 20, right: 20, left: 20, bottom: 15 }
        }
        stackOffset="sign"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="displayName"
          axisLine={true}
          dy={10}
          dx={isYTD ? -5 : undefined}
          angle={isYTD ? -90 : undefined}
          textAnchor={isYTD ? 'end' : 'middle'}
        />
        <YAxis
          yAxisId="left"
          type="number"
          label={
            isPlatform('desktop') && {
              value: 'Amount',
              dy: 40,
              dx: -50,
              angle: -90,
              position: 'insideLeft',
              textAnchor: 'middle',
            }
          }
          orientation="left"
          tickFormatter={formatCurrencyTicks}
          allowDecimals={false}
        />
        <Tooltip
          content={({ active, payload }) => (
            <CustomTooltip
              {...{
                active,
                payload,
                formatter: CELL_FORMATTING.CURRENCY,
              }}
            />
          )}
        />
        <Legend
          iconType="circle"
          formatter={(value, entry) => <CustomLegend {...{ value, entry }} />}
          wrapperStyle={{ paddingTop: isYTD ? '100px' : '50px' }}
          payload={map(
            BALANCE_SHEET_CATEGORY,
            ({ displayName: value, color }) => ({
              value,
              type: 'circle',
              id: value,
              color,
            })
          )}
        />
        {map(BALANCE_SHEET_CATEGORY, ({ name, color }) => (
          <Bar
            yAxisId="left"
            key={name}
            dataKey={camelCase(name)}
            stackId="a"
            fill={color}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

BalanceSheetBarChart.propTypes = exact(propTypes)
BalanceSheetBarChart.defaultProps = defaultProps

export default React.memo(BalanceSheetBarChart)
