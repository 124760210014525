import React from 'react'
import PropTypes from 'prop-types'
import { IonNote } from '@ionic/react'
import { withFormikAdapter } from 'utils'
import * as Types from 'types'
import classnames from 'classnames'
import StandaloneSelectInput from './StandaloneSelectInput'

const propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(Types.inputOption).isRequired,
  error: PropTypes.string,
  isTouched: PropTypes.bool,
  labelPlacement: PropTypes.oneOf([
    'end',
    'fixed',
    'floating',
    'stacked',
    'start',
  ]),
  placeholder: PropTypes.string,
}

const defaultProps = {
  labelPlacement: undefined,
  placeholder: '',
  error: '',
  isTouched: false,
}

function SelectInput({
  name,
  error,
  isTouched,
  options,
  labelPlacement,
  placeholder,
  ...rest
}) {
  return (
    <div className="error-wrapper">
      <StandaloneSelectInput
        name={name}
        className={classnames({
          'ion-invalid': error,
          'ion-touched': isTouched,
        })}
        options={options}
        labelPlacement={labelPlacement}
        placeholder={placeholder}
        {...rest}
      />
      <IonNote color="danger">{error}</IonNote>
    </div>
  )
}

SelectInput.propTypes = propTypes
SelectInput.defaultProps = defaultProps

export default withFormikAdapter()(SelectInput)
