import React from 'react'
import exact from 'prop-types-exact'

const propTypes = {}

const defaultProps = {}

function Disclaimer() {
  return (
    <small className="centered-content">
      ATBS provides no assurance on this financial statement.
    </small>
  )
}

Disclaimer.propTypes = exact(propTypes)
Disclaimer.defaultProps = defaultProps

export default React.memo(Disclaimer)
