import { isEmpty, some } from 'lodash'
import { PER_DIEM_ALLOCATION } from 'config'
import { useDispatch } from 'react-redux'
import * as apiActions from 'api-actions'
import { useToast } from 'hooks'

function usePerDiemFormSubmit(existingPerDiem) {
  const { showSuccessToast, showFailureToast } = useToast()
  const dispatch = useDispatch()

  async function handleSubmit({ dayAllocation, selectedDates }) {
    let requestPromises = []

    selectedDates.forEach((selectedDate) => {
      const entryToUpdate = existingPerDiem?.perDiemEntries.find(
        (entry) => entry.effectiveDate === selectedDate
      )

      if (entryToUpdate) {
        if (dayAllocation === PER_DIEM_ALLOCATION.CLEAR) {
          requestPromises.push(
            dispatch(apiActions.clearPerDiemDay(entryToUpdate.id))
          )
        } else {
          requestPromises.push(
            dispatch(
              apiActions.updatePerDiemDay(dayAllocation, entryToUpdate.id)
            )
          )
        }
      } else if (dayAllocation !== PER_DIEM_ALLOCATION.CLEAR) {
        requestPromises.push(
          dispatch(apiActions.createPerDiemDay(selectedDate, dayAllocation))
        )
      }
    })

    if (isEmpty(requestPromises)) return

    const requestResponses = await Promise.allSettled(requestPromises)

    if (hasRequestError(requestResponses)) {
      showFailureToast({
        message: `Error updating ${pluralize(
          'date',
          requestResponses.length
        )}! Please review your entries and try again.`,
        duration: 3000,
      })
    } else {
      showSuccessToast({
        message: `${pluralize(
          'Date',
          requestResponses.length
        )} successfully updated!`,
        duration: 3000,
      })
    }

    return
  }

  return { handleSubmit }
}

// Helpers
function pluralize(str, count, plural = `${str}s`) {
  return count === 1 ? str : plural
}

function hasRequestError(requestResponses) {
  return some(requestResponses, ['status', 'rejected'])
}

export default usePerDiemFormSubmit
