import { useIonToast } from '@ionic/react'
import { useCallback } from 'react'

function useToast() {
  const [present] = useIonToast()

  const showToast = useCallback(
    (message, color, callback, duration) => {
      present({
        message,
        duration: duration || 6000,
        position: 'bottom',
        color,
        buttons: !callback
          ? undefined
          : [
              {
                text: 'Try Again',
                role: 'cancel',
                handler: callback,
              },
            ],
      })
    },
    [present]
  )

  const showSuccessToast = useCallback(
    ({ message, callback, duration }) =>
      showToast(message, 'success', callback, duration),
    [showToast]
  )
  const showFailureToast = useCallback(
    ({ message, callback, duration }) =>
      showToast(`Error: ${message}`, 'danger', callback, duration),
    [showToast]
  )

  const showWarningToast = useCallback(
    ({ message, callback, duration }) =>
      showToast(`Warning: ${message}`, 'warning', callback, duration),
    [showToast]
  )

  return { showSuccessToast, showFailureToast, showWarningToast }
}

export default useToast
