import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { App } from '@capacitor/app'

// Used for enabling deep linking
function useAppUrlListener() {
  const history = useHistory()

  useEffect(() => {
    App.addListener('appUrlOpen', (event) => {
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2
      const slug = event.url.split('.com').pop()
      if (slug) history.push(slug)
      // If no match, do nothing - let regular routing logic take over
    })
  }, [history])
}

export default useAppUrlListener
