import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  IonNavLink,
  IonContent,
  IonGrid,
  IonRow,
  IonButton,
} from '@ionic/react'
import UploadModalMethodContent from './UploadModalMethodContent'
import { UPLOAD_MODAL_BREAKPOINT, UPLOAD_PAGE_TYPE } from 'config'
import { map } from 'lodash'

const propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  changeModalHeight: PropTypes.func.isRequired,
  addFileToDocument: PropTypes.func.isRequired,
  setUploadedPhoto: PropTypes.func.isRequired,
}

const defaultProps = {}

function UploadModalPageTypeContent({
  setIsOpen,
  changeModalHeight,
  addFileToDocument,
  setUploadedPhoto,
}) {
  const shrinkModalHeight = () =>
    changeModalHeight(UPLOAD_MODAL_BREAKPOINT.SHORT)
  const growModalHeight = () => changeModalHeight(UPLOAD_MODAL_BREAKPOINT.TALL)

  return (
    <IonContent className="ion-padding">
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <div className="sheet-modal modal-justify">
            <h2>Are you adding a single-page file or a multi-page file?</h2>
            <p>
              A single-page file might be a receipt, while a multi-page file
              would be a longer document like a tax return.
            </p>
            <p>Please upload different file types as separate files.</p>
            {/*
            <p>Need help? View our <a href="atbs.com">How to Upload Docs</a> tutorial.</p>
            */}
            {map(UPLOAD_PAGE_TYPE, (type) => {
              const isMultiPage = type.value === UPLOAD_PAGE_TYPE.MULTIPLE.value
              return (
                <IonNavLink
                  key={type.value}
                  onClick={shrinkModalHeight}
                  component={() => (
                    <UploadModalMethodContent
                      growModalHeight={growModalHeight}
                      setIsOpen={setIsOpen}
                      addFileToDocument={addFileToDocument}
                      isMultipleSelection={isMultiPage}
                      setUploadedPhoto={setUploadedPhoto}
                    />
                  )}
                >
                  <IonButton color={isMultiPage ? '' : 'secondary'}>
                    {type.display}
                  </IonButton>
                </IonNavLink>
              )
            })}
          </div>
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}

UploadModalPageTypeContent.propTypes = exact(propTypes)
UploadModalPageTypeContent.defaultProps = defaultProps

export default React.memo(UploadModalPageTypeContent)
