import React from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import { SubmitButton, SelectInput } from 'components'
import * as Yup from 'yup'
import exact from 'prop-types-exact'
import * as Types from 'types'

const propTypes = {
  taxReturnOptions: PropTypes.arrayOf(Types.inputOption).isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const defaultProps = {}

function TaxReturnForm({ taxReturnOptions, onSubmit }) {
  const validationSchema = Yup.object({
    taxReturnUrl: Yup.string().required('Must select a tax return'),
  })

  return (
    <Formik
      initialValues={{ taxReturnUrl: '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form className="filter-form">
          <SelectInput
            name="taxReturnUrl"
            options={taxReturnOptions}
            label={false}
            aria-label="Select a tax return"
            placeholder="Select a tax return"
          />
          <SubmitButton color="default" submitting={isSubmitting}>
            Apply
          </SubmitButton>
        </Form>
      )}
    </Formik>
  )
}

TaxReturnForm.propTypes = exact(propTypes)
TaxReturnForm.defaultProps = defaultProps

export default React.memo(TaxReturnForm)
