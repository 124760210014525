import React from 'react'
import PropTypes from 'prop-types'
import { IonInput } from '@ionic/react'
import { withFormikAdapter } from 'utils'
import classNames from 'classnames'

const propTypes = {
  name: PropTypes.string.isRequired,
  isTouched: PropTypes.bool.isRequired,
  error: PropTypes.string,
}

const defaultProps = {
  error: null,
}

function TextInput({ name, isTouched, error, ...rest }) {
  return (
    <IonInput
      className={classNames({
        'ion-invalid': error,
        'ion-touched': isTouched,
      })}
      fill="outline"
      labelPlacement="stacked"
      name={name}
      errorText={error}
      mode="md"
      {...rest}
    />
  )
}

TextInput.propTypes = propTypes
TextInput.defaultProps = defaultProps

export default withFormikAdapter()(TextInput)
