import React from 'react'
import PropTypes from 'prop-types'
import { IonButton, IonSpinner } from '@ionic/react'

const propTypes = {
  submitting: PropTypes.bool,
  children: PropTypes.node,
}

const defaultProps = {
  submitting: false,
  children: 'Submit',
}

function SubmitButton({ submitting, children, ...rest }) {
  return (
    <IonButton
      disabled={submitting}
      type="submit"
      expand="block"
      strong
      {...rest}
    >
      {children}
      {submitting && <IonSpinner />}
    </IonButton>
  )
}

SubmitButton.propTypes = propTypes
SubmitButton.defaultProps = defaultProps

export default React.memo(SubmitButton)
