import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { DocumentCard, LoadingSpinner, PageTitle } from 'components'
import { useParams } from 'react-router-dom'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { fetchFileBlob } from 'utils'
import { snakeCase } from 'lodash'

const propTypes = {
  fetchDocumentByID: PropTypes.func.isRequired,
  clearDocument: PropTypes.func.isRequired,
  document: Types.doc,
}

const defaultProps = {
  document: null,
}

function DocumentShow({ fetchDocumentByID, clearDocument, document }) {
  const { id } = useParams()
  const [pdfBlob, setPdfBlob] = useState()
  const [documentError, setDocumentError] = useState()
  const [isLoadingDocumentPdf, setIsLoadingDocumentPdf] = useState(true)

  useEffect(() => {
    const getShowDoc = async () => {
      try {
        await fetchDocumentByID(id)
        await fetchDocumentPdf()
      } catch (errorMessage) {
        setDocumentError(
          `There was an issue loading the document: ${errorMessage}`
        )
      }
    }

    if (id) {
      getShowDoc()
    }

    return clearDocument
  }, [id, fetchDocumentByID, fetchDocumentPdf, clearDocument])

  const fetchDocumentPdf = useCallback(async () => {
    setIsLoadingDocumentPdf(true)
    const blob = await fetchFileBlob({ url: `v1/pdfs/document/${id}.pdf` })
    if (blob) {
      setPdfBlob(blob)
    }
    setIsLoadingDocumentPdf(false)
  }, [id])

  if (documentError) {
    return <div className="centered-content">{documentError}</div>
  }

  if (!document || isLoadingDocumentPdf) {
    return (
      <LoadingSpinner>
        <p>Document Loading...</p>
      </LoadingSpinner>
    )
  }

  return (
    <>
      <PageTitle title={`${document.title} - View Document`} forceUpdate />
      <DocumentCard
        document={document}
        fileName={snakeCase(document.title)}
        pdfBlob={pdfBlob}
      />
    </>
  )
}

DocumentShow.propTypes = exact(propTypes)
DocumentShow.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    document: selectors.document(state),
  }
}

const mapDispatchToProps = {
  fetchDocumentByID: apiActions.fetchDocumentByID,
  clearDocument: actions.clearDocument,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DocumentShow
)
