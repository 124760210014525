import React from 'react'
import PropTypes from 'prop-types'
import { IonSpinner } from '@ionic/react'

const propTypes = {
  children: PropTypes.node,
}

const defaultProps = {
  children: null,
}

function LoadingSpinner({ children, ...rest }) {
  return (
    <div className="centered-content">
      {children}
      <IonSpinner {...rest} />
    </div>
  )
}

LoadingSpinner.propTypes = propTypes
LoadingSpinner.defaultProps = defaultProps

export default React.memo(LoadingSpinner)
