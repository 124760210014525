import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors } from '../reducer'
import {
  isPlatform,
  IonAccordionGroup,
  IonIcon,
  IonItem,
  IonLabel,
} from '@ionic/react'
import {
  ProfitPlanTableDesktopSection,
  ProfitPlanTableMobileSection,
} from '../components'
import { alertCircleOutline } from 'ionicons/icons'
import { StandaloneSelectInput, LoadingSpinner } from 'components'
import * as apiActions from 'api-actions'
import { concat, values, isEmpty, compact } from 'lodash'
import * as Types from 'types'
import {
  PROFIT_PLAN_BASE_FILTERS,
  PROFIT_PLAN_COLUMN,
  PROFIT_PLAN_SECTION,
  BUSINESS_WHAT_IF_COLUMNS,
  PERSONAL_WHAT_IF_COLUMNS,
  WHAT_IF_FILTER,
} from 'config'

const propTypes = {
  currentUser: Types.user.isRequired,
  fetchProfitPlan: PropTypes.func.isRequired,
  profitPlan: Types.profitPlan,
  businessWhatIfData: PropTypes.arrayOf(Types.businessWhatIfItem),
  personalWhatIfData: PropTypes.arrayOf(Types.personalWhatIfItem),
}

const defaultProps = {
  profitPlan: null,
  businessWhatIfData: null,
  personalWhatIfData: null,
}

const WHAT_IF_MOBILE_SECTION_NAMES = [
  ...BUSINESS_WHAT_IF_COLUMNS,
  ...PERSONAL_WHAT_IF_COLUMNS,
].map((col) => col.name)

const PROFIT_PLAN_SECTION_NAMES = values(PROFIT_PLAN_SECTION)

const createProfitPlanFilters = (profitPlan) => {
  if (isEmpty(profitPlan.whatIf)) return PROFIT_PLAN_BASE_FILTERS

  return compact([
    ...PROFIT_PLAN_BASE_FILTERS,
    profitPlan.whatIf.businessScenario && {
      name: 'What If: Business',
      value: WHAT_IF_FILTER.BUSINESS_WHAT_IF,
    },
    profitPlan.whatIf.personalScenario && {
      name: 'What If: Personal',
      value: WHAT_IF_FILTER.PERSONAL_WHAT_IF,
    },
  ])
}

function ProfitPlan({
  currentUser,
  fetchProfitPlan,
  profitPlan,
  businessWhatIfData,
  personalWhatIfData,
}) {
  const [error, setError] = useState()
  const [filterBy, setFilterBy] = useState(PROFIT_PLAN_COLUMN.WEEKLY.value)
  const [openPanels, setOpenPanels] = useState(
    concat(PROFIT_PLAN_SECTION_NAMES, WHAT_IF_MOBILE_SECTION_NAMES)
  )

  const isWhatIfFilter = values(WHAT_IF_FILTER).includes(filterBy)
  const isExtendedFilter = [
    PROFIT_PLAN_COLUMN.CENTS_PER_MILE.value,
    PROFIT_PLAN_COLUMN.REVENUE_PERCENTAGE.value,
  ].includes(filterBy)

  useEffect(() => {
    const requestProfitPlan = async () => {
      try {
        await fetchProfitPlan()
      } catch (errorMessage) {
        setError(errorMessage)
      }
    }

    requestProfitPlan()
  }, [fetchProfitPlan])

  if (error) {
    const noProfitPlan = error.includes("Couldn't find active Plan_Header__c")
    return (
      <div className="centered-content">
        <IonIcon icon={alertCircleOutline} />
        {noProfitPlan ? (
          <h2>
            If you would like to set up a Profit Plan, please contact your
            Consultant
          </h2>
        ) : (
          <h2>There was an issue loading your Profit Plan</h2>
        )}
      </div>
    )
  }

  if (!profitPlan) {
    return <LoadingSpinner />
  }

  return (
    <div className="profit-plan-wrapper">
      <h2>
        {profitPlan.name}
        {currentUser.fullName && (
          <>
            <br />
            <strong>FOR {currentUser.fullName.toUpperCase()}</strong>
          </>
        )}
      </h2>
      {isPlatform('desktop') ? (
        <IonAccordionGroup
          multiple={true}
          value={openPanels}
          onIonChange={(e) => {
            setOpenPanels(e.detail.value)
          }}
        >
          <ProfitPlanTableDesktopSection
            data={profitPlan.miles}
            name={PROFIT_PLAN_SECTION.MILES}
          />
          <ProfitPlanTableDesktopSection
            data={profitPlan.revenue}
            name={PROFIT_PLAN_SECTION.REVENUE}
            isCurrency={true}
          />
          <ProfitPlanTableDesktopSection
            data={profitPlan.variable}
            name={PROFIT_PLAN_SECTION.VARIABLE}
            isCurrency={true}
          />
          <ProfitPlanTableDesktopSection
            data={profitPlan.fixed}
            name={PROFIT_PLAN_SECTION.FIXED}
            isCurrency={true}
          />
          <ProfitPlanTableDesktopSection
            data={concat(
              profitPlan.personalObligations,
              profitPlan.estimatedCash
            )}
            name={PROFIT_PLAN_SECTION.PERSONAL_OBLIGATIONS}
            isCurrency={true}
          />
          <ProfitPlanTableDesktopSection
            data={profitPlan.breakeven}
            name={PROFIT_PLAN_SECTION.BREAKEVEN_POINT}
            isCurrency={true}
          />
          <ProfitPlanTableDesktopSection
            data={concat(
              profitPlan.perDiem,
              profitPlan.federalTaxes,
              profitPlan.stateTaxes
            )}
            name={PROFIT_PLAN_SECTION.PER_DIEM}
            isCurrency={true}
          />
          {(businessWhatIfData || personalWhatIfData) && (
            <>
              <h2 className="font-bold">What If Scenarios</h2>
              {businessWhatIfData && (
                <ProfitPlanTableDesktopSection
                  data={businessWhatIfData}
                  name={PROFIT_PLAN_SECTION.BUSINESS_WHAT_IF}
                  doNotApplyRowTotalsStyle={true}
                />
              )}
              {personalWhatIfData && (
                <ProfitPlanTableDesktopSection
                  data={personalWhatIfData}
                  name={PROFIT_PLAN_SECTION.PERSONAL_WHAT_IF}
                  doNotApplyRowTotalsStyle={true}
                />
              )}
            </>
          )}
        </IonAccordionGroup>
      ) : (
        <div className="profit-plan-mobile-wrapper">
          <div className="filter-form">
            <div className="filter-items">
              <IonItem>
                <IonLabel>Filter By</IonLabel>
                <StandaloneSelectInput
                  name="filter"
                  value={filterBy}
                  onIonChange={(e) => {
                    setFilterBy(e.detail.value)
                  }}
                  aria-label="Select a filter type"
                  options={createProfitPlanFilters(profitPlan)}
                />
              </IonItem>
            </div>
          </div>
          <IonAccordionGroup
            multiple={true}
            value={openPanels}
            onIonChange={(e) => {
              setOpenPanels(e.detail.value)
            }}
          >
            {!isWhatIfFilter && (
              <>
                {!isExtendedFilter && (
                  <ProfitPlanTableMobileSection
                    name={PROFIT_PLAN_SECTION.MILES}
                    data={profitPlan.miles}
                    filterBy={filterBy}
                  />
                )}
                <ProfitPlanTableMobileSection
                  name={PROFIT_PLAN_SECTION.REVENUE}
                  data={profitPlan.revenue}
                  filterBy={filterBy}
                  isCurrency={true}
                />
                <ProfitPlanTableMobileSection
                  name={PROFIT_PLAN_SECTION.VARIABLE}
                  data={profitPlan.variable}
                  filterBy={filterBy}
                  isCurrency={true}
                />
                <ProfitPlanTableMobileSection
                  name={PROFIT_PLAN_SECTION.FIXED}
                  data={profitPlan.fixed}
                  filterBy={filterBy}
                  isCurrency={true}
                />
                <ProfitPlanTableMobileSection
                  name={PROFIT_PLAN_SECTION.PERSONAL_OBLIGATIONS}
                  data={concat(
                    profitPlan.personalObligations,
                    profitPlan.estimatedCash
                  )}
                  filterBy={filterBy}
                  isCurrency={true}
                />
                <ProfitPlanTableMobileSection
                  name={PROFIT_PLAN_SECTION.BREAKEVEN_POINT}
                  data={profitPlan.breakeven}
                  filterBy={filterBy}
                  isCurrency={true}
                />
                <ProfitPlanTableMobileSection
                  name={PROFIT_PLAN_SECTION.PER_DIEM}
                  data={concat(
                    profitPlan.perDiem,
                    profitPlan.federalTaxes,
                    profitPlan.stateTaxes
                  )}
                  filterBy={filterBy}
                  isCurrency={true}
                />
              </>
            )}
            {filterBy === WHAT_IF_FILTER.BUSINESS_WHAT_IF &&
              BUSINESS_WHAT_IF_COLUMNS.map(({ name, value }) => (
                <ProfitPlanTableMobileSection
                  key={value}
                  name={name}
                  data={businessWhatIfData}
                  filterBy={value}
                  isCurrency={true}
                  doNotApplyRowTotalsStyle={true}
                />
              ))}
            {filterBy === WHAT_IF_FILTER.PERSONAL_WHAT_IF &&
              PERSONAL_WHAT_IF_COLUMNS.map(({ name, value }) => (
                <ProfitPlanTableMobileSection
                  key={value}
                  name={name}
                  data={personalWhatIfData}
                  filterBy={value}
                  isCurrency={true}
                  doNotApplyRowTotalsStyle={true}
                />
              ))}
          </IonAccordionGroup>
        </div>
      )}
    </div>
  )
}

ProfitPlan.propTypes = exact(propTypes)
ProfitPlan.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
    profitPlan: selectors.profitPlan(state),
    businessWhatIfData: selectors.businessWhatIfData(state),
    personalWhatIfData: selectors.personalWhatIfData(state),
  }
}

const mapDispatchToProps = {
  fetchProfitPlan: apiActions.fetchProfitPlan,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ProfitPlan)
