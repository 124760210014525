import { useRef, useLayoutEffect } from 'react'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { isNativeApp } from 'utils'
import { PERMISSION } from 'config'
import { format } from 'date-fns'

function usePhotoGallery({ onNeedsPermission }) {
  const onNeedsPermissionCallback = useRef(onNeedsPermission)
  useLayoutEffect(() => {
    onNeedsPermissionCallback.current = onNeedsPermission
  })

  const getDocumentPhoto = async () => {
    try {
      const photo = await getPhoto()
      return photo
    } catch (e) {
      // Unfortunately there are no status codes or additional metadata to reference,
      // so we have to rely on checking error string to know if the user pressed back
      if (/cancell?ed/i.test(e.message)) {
        return
      }

      // On Desktop, the PWA Element that shows the camera will not throw an error
      // if permissions are denied. Instead the UI will just show "No camera found"
      const permissionStates = await Camera.checkPermissions()
      const needsPermission = Object.values(permissionStates).some(
        (state) => state === PERMISSION.DENIED
      )
      if (needsPermission && onNeedsPermissionCallback.current) {
        return onNeedsPermissionCallback.current()
      }

      throw e
    }
  }

  return {
    getDocumentPhoto,
  }
}

async function getPhoto() {
  const source = isNativeApp() ? CameraSource.Prompt : CameraSource.Camera
  const photo = await Camera.getPhoto({
    resultType: CameraResultType.Uri,
    source,
    correctOrientation: true,
    quality: 100,
  })

  const today = new Date()

  const fileName = `${format(today, 'M-d-yyyy-T')}.${photo.format}`
  return { ...photo, fileName }
}

export default usePhotoGallery
