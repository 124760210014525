import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import * as apiActions from 'api-actions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { LoadingSpinner, PaginatedItems } from 'components'
import { TransactionCard, TransactionLineItem } from '../components'
import { ReportCriteriaForm } from '../forms'
import { first, isEmpty } from 'lodash'
import {
  IonList,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
} from '@ionic/react'
import {
  CELL_FORMATTING,
  MONTHS,
  YTD_OPTION,
  MILES_CATEGORY_ACCOUNT_NUMBERS,
} from 'config'
import { selectors } from '../reducer'
import { getMonth } from 'date-fns'

const propTypes = {
  fetchTransactionsByCategory: PropTypes.func.isRequired,
  financialPerformanceYears: PropTypes.arrayOf(PropTypes.string).isRequired,
  categoryTransactions: PropTypes.arrayOf(Types.transaction),
}

const defaultProps = {
  categoryTransactions: null,
}

function CategoryTransactions({
  fetchTransactionsByCategory,
  categoryTransactions,
  financialPerformanceYears,
}) {
  const history = useHistory()
  const { categoryInternalAccountNumber } = useParams()
  const { search, pathname } = useLocation()
  const searchParams = new URLSearchParams(search)
  const year = searchParams.get('year')
  const period = searchParams.get('period')

  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const parentPathname = first(pathname.split(`/transactions`))

  const isMilesAmount = MILES_CATEGORY_ACCOUNT_NUMBERS.includes(
    categoryInternalAccountNumber
  )
  const formatting = isMilesAmount
    ? CELL_FORMATTING.UNITS
    : CELL_FORMATTING.CURRENCY

  const formatTransactions = useCallback(
    (transactions) =>
      transactions.map((transaction) => {
        const formattedAmount = transaction.amount.toLocaleString(
          'en-US',
          formatting
        )
        return { formattedAmount, ...transaction }
      }),
    [formatting]
  )

  const filterTransactions = useCallback(
    (transactions) => {
      if (!period || period === YTD_OPTION.periodSelectorValue)
        return transactions

      return transactions.filter((transaction) => {
        const transactionMonthIndex = getMonth(new Date(transaction.date))
        return transactionMonthIndex === MONTHS.indexOf(period)
      })
    },
    [period]
  )

  const filteredAndFormattedTransactions = useMemo(() => {
    if (!categoryTransactions) return []

    const formattedTransactions = formatTransactions(categoryTransactions)
    return filterTransactions(formattedTransactions)
  }, [categoryTransactions, formatTransactions, filterTransactions])

  useEffect(() => {
    const requestTransactions = async () => {
      setIsLoading(true)
      setError()
      try {
        await fetchTransactionsByCategory(categoryInternalAccountNumber, year)
      } catch (errorMessage) {
        setError(
          `There was an issue loading transactions for ${year}: ${errorMessage}`
        )
      }
      setIsLoading(false)
    }

    if (year) {
      requestTransactions()
    }
  }, [categoryInternalAccountNumber, year, fetchTransactionsByCategory])

  const total = filteredAndFormattedTransactions
    .reduce((sum, transaction) => {
      return sum + transaction.amount
    }, 0)
    .toLocaleString('en-US', formatting)

  const calculateCount = () => {
    if (isLoading) return 0
    return filteredAndFormattedTransactions.length
  }

  return (
    <div className="balance-sheet-transactions-list">
      <header>
        <h2>Report Criteria</h2>
      </header>
      <div className="criteria-form-flex">
        <ReportCriteriaForm
          initialValues={{ year, period }}
          handleSubmit={({ year, period }) => {
            const monthParam = period ? `&period=${period}` : ''
            history.push(`${pathname}?year=${year}${monthParam}`)
            return true
          }}
          availableYearOptions={financialPerformanceYears}
          hidePeriodSelect={!period}
          hideBeginningOption
        />
        <p>{`Transactions found: ${calculateCount()}`}</p>
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : error ? (
        <IonCard>
          <div className="centered-content">
            <p>{error}</p>
          </div>
        </IonCard>
      ) : isEmpty(filteredAndFormattedTransactions) ? (
        <IonCard className="no-table-data">
          <div className="centered-content">
            <p>No Transactions Found</p>
          </div>
        </IonCard>
      ) : (
        <>
          <IonList className="list-style-table ion-hide-md-down">
            <IonItem className="list-style-table-header">
              <IonGrid>
                <IonRow>
                  <IonCol>Date</IonCol>
                  <IonCol>Source</IonCol>
                  <IonCol>Details</IonCol>
                  <IonCol className="right-aligned-cell">Amount</IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
            <PaginatedItems
              items={filteredAndFormattedTransactions}
              itemComponent={({ item }) => (
                <TransactionLineItem
                  parentPathname={parentPathname}
                  transaction={item}
                />
              )}
            />
          </IonList>
          <div className="ion-hide-md-up">
            {filteredAndFormattedTransactions.map((transaction) => (
              <TransactionCard
                key={transaction.id}
                parentPathname={parentPathname}
                transaction={transaction}
              />
            ))}
          </div>
          <div className="custom-ending-balance-toast fixed">
            <span>Total:</span>
            <span>{total}</span>
          </div>
        </>
      )}
    </div>
  )
}

CategoryTransactions.propTypes = exact(propTypes)
CategoryTransactions.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    categoryTransactions: selectors.categoryTransactions(state),
    financialPerformanceYears: selectors.financialPerformanceYears(state),
  }
}

const mapDispatchToProps = {
  fetchTransactionsByCategory: apiActions.fetchTransactionsByCategory,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CategoryTransactions
)
