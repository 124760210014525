import React from 'react'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { IonList, IonItem, IonLabel } from '@ionic/react'
import { CELL_FORMATTING, APP_PATH, CURRENT_YEAR } from 'config'
import { findRowByName } from 'utils'

const propTypes = {
  profitAndLoss: Types.profitAndLoss,
}

const defaultProps = {
  profitAndLoss: null,
}

function MetricsList({ profitAndLoss }) {
  const metricsList = [
    {
      name: `${CURRENT_YEAR} YTD Revenue`,
      value: profitAndLoss
        ? findRowByName(profitAndLoss.revenue, 'Total Revenue')?.yearToDate ?? 0
        : null,
      routerLink: APP_PATH.FINANCIAL_PERFORMANCE.PROFIT_AND_LOSS,
    },
    {
      name: `${CURRENT_YEAR} YTD Expenses`,
      value: profitAndLoss
        ? findRowByName(profitAndLoss.fixed, 'Total Expenses')?.yearToDate ?? 0
        : null,
      routerLink: APP_PATH.FINANCIAL_PERFORMANCE.PROFIT_AND_LOSS,
    },
  ]

  return (
    <IonList className="metrics-list">
      {metricsList.map((item) => {
        const formattedValue = item.value?.toLocaleString(
          'en-US',
          CELL_FORMATTING.CURRENCY
        )

        return (
          <IonItem
            key={item.name}
            routerLink={item.routerLink}
            detail="true"
            color="primary"
          >
            <IonLabel>{item.name}</IonLabel>
            <p>{formattedValue ?? '...'}</p>
          </IonItem>
        )
      })}
    </IonList>
  )
}

MetricsList.propTypes = exact(propTypes)
MetricsList.defaultProps = defaultProps

export default React.memo(MetricsList)
