import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonRow,
  IonTitle,
  IonIcon,
  IonButton,
} from '@ionic/react'
import { closeOutline } from 'ionicons/icons'

const propTypes = {
  trigger: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string,
}

const defaultProps = {
  buttonText: 'OK',
}

function InfoModal({ trigger, title, children, buttonText, ...rest }) {
  const ref = useRef()

  const closeModal = () => {
    ref.current?.dismiss()
  }

  return (
    <IonModal id="info-modal" ref={ref} trigger={trigger} {...rest}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="light" onClick={closeModal} aria-label="Close">
              <IonIcon icon={closeOutline} slot="icon-only" color="dark" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">{children}</IonContent>
      <IonRow className="ion-justify-children-center">
        <IonButton color="secondary" expand="block" onClick={closeModal}>
          {buttonText}
        </IonButton>
      </IonRow>
    </IonModal>
  )
}

InfoModal.propTypes = propTypes
InfoModal.defaultProps = defaultProps

export default React.memo(InfoModal)
