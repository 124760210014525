import React from 'react'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { IonIcon, IonRouterLink } from '@ionic/react'
import { APP_PATH } from 'config'
import { pinOutline } from 'ionicons/icons'

const propTypes = {
  routerDirection: Types.routerDirection,
}

const defaultProps = {
  routerDirection: 'forward',
}

function MileageTrackerLink({ routerDirection }) {
  return (
    <IonRouterLink
      routerLink={APP_PATH.MILEAGE_TRACKER.TRIP_TRACKER}
      routerDirection={routerDirection}
      className="mileage-button"
    >
      <IonIcon icon={pinOutline} aria-hidden="true" />
      Mileage Tracker
    </IonRouterLink>
  )
}

MileageTrackerLink.propTypes = exact(propTypes)
MileageTrackerLink.defaultProps = defaultProps

export default React.memo(MileageTrackerLink)
