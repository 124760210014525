import React from 'react'
import PropTypes from 'prop-types'
import { IonCheckbox, IonNote } from '@ionic/react'
import { withFormikAdapter } from 'utils'
import classNames from 'classnames'

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  error: PropTypes.string,
  isTouched: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
}

const defaultProps = {
  error: '',
}

function CheckboxInput({ name, label, value, error, isTouched, ...rest }) {
  return (
    <div className="error-wrapper">
      <IonCheckbox
        name={name}
        checked={value}
        className={classNames({
          'ion-invalid': error,
          'ion-touched': isTouched,
        })}
        justify="start"
        {...rest}
      >
        {label}
      </IonCheckbox>
      <IonNote className="error-text">{error}</IonNote>
    </div>
  )
}

CheckboxInput.propTypes = propTypes
CheckboxInput.defaultProps = defaultProps

export default withFormikAdapter()(CheckboxInput)
