import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  IonCard,
  IonButton,
  IonCardHeader,
  IonCardTitle,
  IonSpinner,
  IonIcon,
} from '@ionic/react'
import { LoadingSpinner, InfoModal } from 'components'
import { useToast, useMileageTracker } from 'hooks'
import { CELL_FORMATTING } from 'config'
import { multiply, isNil } from 'lodash'
import { SaveTripErrorModal } from '../components'
import { helpCircleOutline } from 'ionicons/icons'

const propTypes = {
  fetchCurrentDeductionRate: PropTypes.func.isRequired,
  currentDeductionRate: PropTypes.number,
}

const defaultProps = {
  currentDeductionRate: null,
}

function CurrentTrackingCard({
  currentDeductionRate,
  fetchCurrentDeductionRate,
}) {
  const {
    trip,
    handleStart,
    handleStop,
    isSavingTrip,
    isSettingWatcher,
    unsavedTrip,
    saveTrip,
    clearUnsavedTrip,
  } = useMileageTracker()
  const [isLoading, setIsLoading] = useState(false)
  const { showWarningToast } = useToast()

  useEffect(() => {
    const requestCurrentDeductionRate = async () => {
      try {
        setIsLoading(true)
        await fetchCurrentDeductionRate()
      } catch (error) {
        showWarningToast({
          message: `There was an issue getting the current deduction rate. You are still able to track your trips but savings won't show in real-time.`,
        })
      } finally {
        setIsLoading(false)
      }
    }

    requestCurrentDeductionRate()
  }, [fetchCurrentDeductionRate, showWarningToast])

  const displaySavings = () => {
    if (!trip || isNil(trip.miles)) return '--'
    if (isNil(currentDeductionRate)) return 'N/A'

    return multiply(trip.miles, currentDeductionRate).toLocaleString(
      'en-US',
      CELL_FORMATTING.CURRENCY
    )
  }

  if (isLoading) return <LoadingSpinner />

  return (
    <>
      <IonCard className="ion-padding-small">
        <IonCardHeader>
          <IonCardTitle>Current Tracking</IonCardTitle>
        </IonCardHeader>
        <div className="time-tracking">
          <dl>
            <div className="column">
              <dt>
                <span>Miles</span>
              </dt>
              <dd>
                {trip?.miles.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                }) ?? '--'}
              </dd>
            </div>
            <div className="column">
              <dt>
                <span>Start Time</span>
              </dt>
              <dd>
                {trip?.startTime.toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                }) ?? '--'}
              </dd>
            </div>
            <div className="column">
              <dt>
                <IonButton
                  id="open-savings-info-modal"
                  fill="clear"
                  aria-label="Learn more about mileage deduction savings"
                >
                  Savings
                  <IonIcon icon={helpCircleOutline} />
                </IonButton>
              </dt>
              <dd>{displaySavings()}</dd>
            </div>
          </dl>
        </div>
        {isSavingTrip ? (
          <IonButton disabled size="large" expand="block">
            Saving Trip...
          </IonButton>
        ) : (
          <IonButton
            onClick={() => {
              if (trip) handleStop()
              else handleStart()
            }}
            disabled={isSettingWatcher}
            color={trip ? 'danger' : 'success'}
            size="large"
            expand="block"
          >
            {trip ? 'Stop Tracking' : 'Start Tracking'}
            {isSettingWatcher && <IonSpinner />}
          </IonButton>
        )}
      </IonCard>
      <SaveTripErrorModal
        trip={unsavedTrip}
        onSaveTrip={() => {
          saveTrip({ ...unsavedTrip })
          clearUnsavedTrip() // clearing to dismiss the modal
        }}
        onDiscardTrip={clearUnsavedTrip}
      />
      <InfoModal
        trigger="open-savings-info-modal"
        title="Savings Disclaimer"
        className="savings-info-modal"
      >
        <p>
          The "Savings" amount is calculated by multiplying trip miles by the
          standard mileage rate set by the IRS. Your total "Savings" amount is a
          deductible business expense used to lower your taxable income instead
          of calculating actual vehicle expenses. The "Savings" is not an amount
          that you can subtract directly from your taxes owed.
        </p>
      </InfoModal>
    </>
  )
}

CurrentTrackingCard.propTypes = exact(propTypes)
CurrentTrackingCard.defaultProps = defaultProps

export default CurrentTrackingCard
