import {
  createRequest,
  createPostRequest,
  createPatchRequest,
  createDeleteRequest,
} from 'lp-redux-api'
import { PER_DIEM_ALLOCATION, LATEST_DOCS_LIMIT, CURRENT_YEAR } from 'config'
import {
  clearAccount,
  clearSessionToken,
  setSessionToken,
  setAccount,
} from 'auth'

// Unauthorized Actions

export const logIn = createPostRequest('LOG_IN', (params) => ({
  url: '/users/sign_in',
  body: { user: { ...params } },
  skipAuthorization: true,
  onSuccess: (res) => {
    const user = res.data.attributes
    if (params.rememberMe) {
      setAccount(params.email)
    }
    setSessionToken(user.bearerToken)
    return user
  },
}))

export const impersonateUser = createRequest(
  'IMPERSONATE',
  (constituentId, query) => ({
    url: `users/impersonate/${constituentId}`,
    query,
    skipAuthorization: true,
  })
)

export const sendForgotPasswordEmail = createPostRequest(
  'SEND_FORGOT_PASSWORD_EMAIL',
  (params) => ({
    url: '/users/password',
    body: { user: { ...params } },
    skipAuthorization: true,
  })
)

export const updatePassword = createPatchRequest(
  'UPDATE_PASSWORD',
  (params) => ({
    url: '/users/password',
    body: { user: { ...params } },
    skipAuthorization: true,
  })
)

// Authorized Actions

export const logOut = createDeleteRequest('LOG_OUT', () => ({
  url: '/users/sign_out',
  onSuccess: (res) => {
    clearAccount()
    clearSessionToken()
    return res
  },
}))

export const fetchUserProfile = createRequest('FETCH_USER_PROFILE', () => ({
  url: '/v1/users/current_user_details',
}))

export const getTaxEstimateOptions = createRequest(
  'GET_TAX_ESTIMATE_OPTIONS',
  () => ({
    url: '/v1/tax_estimates/options',
  })
)

export const fetchDocumentByID = createRequest(
  'FETCH_DOCUMENT_BY_ID',
  (id) => ({
    url: `/v1/documents/${id}`,
  })
)

export const fetchLatestDocs = createRequest('FETCH_LATEST_DOCS', () => ({
  url: `/v1/documents?limit=${LATEST_DOCS_LIMIT}`,
}))

export const fetchDocuments = createRequest('FETCH_DOCUMENTS', () => ({
  url: '/v1/documents',
}))

export const postDocument = createPostRequest('POST_DOCUMENT', (formData) => ({
  url: '/v1/documents',
  body: formData,
  decamelizeBody: false,
  overrideHeaders: true,
}))

export const fetchProfitPlan = createRequest('FETCH_PROFIT_PLAN', () => ({
  url: `/v1/profit_plans`,
}))

export const fetchTaxReturns = createRequest('FETCH_TAX_RETURNS', () => ({
  url: '/v1/tax_returns',
}))

export const fetchConsultantContact = createRequest(
  'FETCH_CONSULTANT_CONTACT',
  () => ({
    url: '/v1/consultant',
  })
)

export const fetchPerDiem = createRequest('FETCH_PER_DIEMS', (year) => ({
  url: `/v1/per_diems/${year}`,
}))

export const fetchHomePerDiem = createRequest('FETCH_HOME_PER_DIEM', () => ({
  url: `/v1/per_diems/${CURRENT_YEAR}`,
}))

export const createPerDiemDay = createPostRequest(
  'CREATE_PER_DIEM_DAY',
  (date, allocationSelection) => ({
    url: '/v1/per_diem_entries',
    body: {
      per_diem_entry: {
        effective_date: date,
        owner_operator_full_count:
          allocationSelection === PER_DIEM_ALLOCATION.FULL_DAY ? 1 : 0,
        owner_operator_partial_count:
          allocationSelection === PER_DIEM_ALLOCATION.PARTIAL_DAY ? 1 : 0,
        spouse_driver_full_count: 0,
        spouse_driver_partial_count: 0,
        spouse_rider_full_count: 0,
        spouse_rider_partial_count: 0,
      },
    },
  })
)

export const updatePerDiemDay = createPatchRequest(
  'UPDATE_PER_DIEM_DAY',
  (allocationSelection, id) => ({
    url: `/v1/per_diem_entries/${id}`,
    body: {
      per_diem_entry: {
        owner_operator_full_count:
          allocationSelection === PER_DIEM_ALLOCATION.FULL_DAY ? 1 : 0,
        owner_operator_partial_count:
          allocationSelection === PER_DIEM_ALLOCATION.PARTIAL_DAY ? 1 : 0,
        spouse_driver_full_count: 0,
        spouse_driver_partial_count: 0,
        spouse_rider_full_count: 0,
        spouse_rider_partial_count: 0,
      },
    },
  })
)

export const clearPerDiemDay = createDeleteRequest(
  'CLEAR_PER_DIEM_DAY',
  (id) => {
    return {
      url: `/v1/per_diem_entries/${id}`,
    }
  }
)

export const fetchProfitAndLoss = createRequest(
  'FETCH_PROFIT_AND_LOSS',
  (year) => ({
    url: `/v1/profit_and_loss_statements/${year}`,
  })
)

export const fetchHomeProfitAndLoss = createRequest(
  'FETCH_HOME_PROFIT_AND_LOSS',
  () => ({
    url: `/v1/profit_and_loss_statements/${CURRENT_YEAR}`,
  })
)

export const fetchBalanceSheet = createRequest(
  'FETCH_BALANCE_SHEET',
  (year) => ({
    url: `/v1/balance_sheets/${year}`,
  })
)

export const fetchTransactionsByCategory = createRequest(
  'FETCH_TRANSACTIONS_BY_CATEGORY',
  (categoryInternalAccountNumber, year) => ({
    url: `/v1/category/${categoryInternalAccountNumber}/year/${year}/transactions`,
  })
)

export const fetchFinancialPerformanceYearOptions = createRequest(
  'FETCH_FINANCIAL_PERFORMANCE_YEAR_OPTIONS',
  () => ({
    url: '/v1/financial_performance_year_options',
  })
)

export const fetchTransactionsBatch = createRequest(
  'FETCH_TRANSACTIONS_BATCH',
  (batchId) => ({
    url: `/v1/transaction_batches/${batchId}`,
  })
)

export const fetchCurrentMileageDeductionRate = createRequest(
  'FETCH_CURRENT_MILEAGE_DEDUCTION_RATE',
  () => ({
    url: '/v1/mileage_deduction_rates/current',
  })
)

export const fetchTrips = createRequest('FETCH_TRIPS', () => ({
  url: '/v1/trips',
}))

export const fetchTrip = createRequest('FETCH_TRIP', (id) => ({
  url: `/v1/trips/${id}`,
}))

export const createTrip = createPostRequest('CREATE_TRIP', (params) => ({
  url: '/v1/trips',
  body: params,
}))

export const updateTrip = createPatchRequest('UPDATE_TRIP', (params, id) => ({
  url: `/v1/trips/${id}`,
  body: params,
}))

export const deleteTrip = createDeleteRequest('DELETE_TRIP', (id) => ({
  url: `/v1/trips/${id}`,
  onSuccess: () => id,
}))

export const postFlinksData = createPostRequest(
  'POST_FLINKS_DATA',
  (params) => ({
    url: '/v1/flinks',
    body: params,
  })
)

export const fetchHomeMileageSummary = createRequest(
  'FETCH_HOME_MILEAGE_SUMMARY',
  () => ({
    url: `/v1/trips/summary/${CURRENT_YEAR}`,
  })
)

export const fetchMileageSummary = createRequest(
  'FETCH_MILEAGE_SUMMARY',
  () => ({
    url: '/v1/trips/summary',
  })
)
