import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  IonDatetime,
  IonLabel,
  IonModal,
  IonDatetimeButton,
  IonNote,
} from '@ionic/react'
import { withFormikAdapter, getTimeZone, formatISOInTimeZone } from 'utils'
import { useFormikContext } from 'formik'
import { formatInTimeZone } from 'date-fns-tz'

const propTypes = {
  name: PropTypes.string.isRequired,
  labelComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  error: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  timeZone: PropTypes.string,
  displayTimeZone: PropTypes.bool,
  onIonChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

const defaultProps = {
  labelComponent: IonLabel,
  error: '',
  label: '',
  timeZone: getTimeZone(),
  displayTimeZone: false,
  value: null,
}

function ModalDateInput({
  name,
  label,
  labelComponent: LabelComponent,
  error,
  timeZone,
  displayTimeZone,
  onIonChange,
  value,
  ...rest
}) {
  const datetimeId = `datetime-${name}`
  const {
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
    ...inputProps
  } = rest
  const labelId = label && !ariaLabel ? `label-${name}` : undefined
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    /* If an initial date value isn't provided for an input component using IonDatetimeButton,
  	which is frequently preferred so that Ionic defaults to the current date,
  	make sure Formik has the current date stored as a value for the input
  	since the input button will appear already filled with the current date. */
    if (!value) {
      setFieldValue(name, formatISOInTimeZone(new Date(), timeZone), false)
    }
  }, [value, name, timeZone, setFieldValue])

  return (
    <>
      <div className="error-wrapper">
        {label && <LabelComponent id={labelId}>{label}</LabelComponent>}
        <div className="date-time-button-wrapper">
          <IonDatetimeButton
            datetime={datetimeId}
            aria-label={ariaLabel}
            aria-labelledby={ariaLabelledby ?? labelId}
          />
          {displayTimeZone && (
            <span>{formatInTimeZone(new Date(), timeZone, 'zzz')}</span>
          )}
        </div>
        <IonNote className="error-text">{error}</IonNote>
      </div>
      <IonModal keepContentsMounted={true}>
        <IonDatetime
          name={name}
          id={datetimeId}
          onIonChange={(e) => {
            // Include time zone in the formatted string value (ionic date-time ignores time zone values)
            return onIonChange(formatISOInTimeZone(e.detail.value, timeZone))
          }}
          value={value === '' ? null : value}
          {...inputProps}
        />
      </IonModal>
    </>
  )
}

ModalDateInput.propTypes = propTypes
ModalDateInput.defaultProps = defaultProps

export default withFormikAdapter()(ModalDateInput)
