import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Form, Formik } from 'formik'
import { SubmitButton, TextInput } from 'components'
import * as Yup from 'yup'
import * as apiActions from 'api-actions'
import { useToast } from 'hooks'
import { useIonRouter } from '@ionic/react'
import { APP_PATH } from 'config'

const propTypes = {
  sendForgotPasswordEmail: PropTypes.func.isRequired,
}
const defaultProps = {}

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Email must be valid')
    .required('Email is required'),
})

function PasswordEmailForm({ sendForgotPasswordEmail }) {
  const router = useIonRouter()
  const { showSuccessToast, showFailureToast } = useToast()

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async ({ email }) => {
        try {
          await sendForgotPasswordEmail({ email })
          showSuccessToast({
            message:
              'Submitted! If your email address is in our system, you will receive an email with a password reset link shortly. Be sure to check your spam and junk folders. If you did not receive a reset link, please contact ITHelp@atbs.com.',
            duration: 12000,
          })
          return router.push(APP_PATH.SIGN_IN, 'back', 'pop')
        } catch {
          return showFailureToast({
            message:
              'There was an issue submitting your email address. Please try again.',
          })
        }
      }}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form className="sign-in-form ion-padding" noValidate>
          <TextInput
            name="email"
            type="email"
            inputmode="email"
            autocomplete="email"
          />
          <SubmitButton color="secondary" submitting={isSubmitting}>
            Submit
          </SubmitButton>
        </Form>
      )}
    </Formik>
  )
}

PasswordEmailForm.propTypes = propTypes
PasswordEmailForm.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  sendForgotPasswordEmail: apiActions.sendForgotPasswordEmail,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PasswordEmailForm
)
