import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { IonButton, IonIcon, IonLabel } from '@ionic/react'
import { addCircleOutline } from 'ionicons/icons'

const propTypes = {
  setIsOpen: PropTypes.func.isRequired,
}

const defaultProps = {}

function UploadFAB({ setIsOpen }) {
  return (
    <IonButton
      className="ion-upload-fab"
      onClick={() => setIsOpen(true)}
      type="button"
    >
      <IonIcon icon={addCircleOutline} slot="start"></IonIcon>
      <IonLabel slot="end">Upload Documents</IonLabel>
    </IonButton>
  )
}

UploadFAB.propTypes = exact(propTypes)
UploadFAB.defaultProps = defaultProps

export default React.memo(UploadFAB)
