import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { IonIcon, IonItem, IonMenuToggle } from '@ionic/react'
import { MENU_ICON } from 'config'

const propTypes = {
  menuItem: Types.menuItem.isRequired,
  handleClick: PropTypes.func.isRequired,
  href: PropTypes.string,
}

const defaultProps = {
  href: undefined, // must be undefined to be rendered as a focusable button
}

function NavMenuItem({ menuItem, handleClick, href }) {
  const { id, icon, name, path } = menuItem

  return (
    <IonMenuToggle>
      <IonItem
        onClick={handleClick}
        button={true} // adds hover effect and ability to tab focus
        id={id}
        routerLink={path}
        lines="none"
        detail={false}
        href={href}
        target={href && '_blank'}
        rel={href && 'noreferrer'}
      >
        {icon && <IonIcon slot="start" icon={MENU_ICON[icon]} />}
        {name}
      </IonItem>
    </IonMenuToggle>
  )
}

NavMenuItem.propTypes = exact(propTypes)
NavMenuItem.defaultProps = defaultProps

export default React.memo(NavMenuItem)
