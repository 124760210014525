import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { IonIcon } from '@ionic/react'
import { documentTextOutline } from 'ionicons/icons'

const propTypes = {
  children: PropTypes.node,
  theme: PropTypes.string,
}

const defaultProps = {
  children: <span>No Documents</span>,
  theme: 'light',
}

function NoDocumentContent({ children, theme }) {
  return (
    <div className={`centered-content no-doc-content ${theme}`}>
      <div className="ion-padding">
        <div className="icon-circle-bg no-documents">
          <IonIcon icon={documentTextOutline} aria-hidden="true" size="large" />
        </div>
        {children}
      </div>
    </div>
  )
}

NoDocumentContent.propTypes = exact(propTypes)
NoDocumentContent.defaultProps = defaultProps

export default React.memo(NoDocumentContent)
