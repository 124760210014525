import { getMonth, getYear } from 'date-fns'
import { MONTHS } from 'config'

function getMonthsToDate(year) {
  const today = new Date()
  const currentMonth = getMonth(today)
  const currentYear = getYear(today).toString()
  const monthsToDate = MONTHS.slice(0, currentMonth + 1)
  return year === currentYear ? monthsToDate : MONTHS
}

export default getMonthsToDate
