import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import {
  CELL_FORMATTING,
  TOTAL_REVENUE_CATEGORY,
  REVENUE_CHART_CATEGORY,
} from 'config'
import * as Types from 'types'
import { CustomLegend, CustomTooltip } from '../components'
import { map, find, some } from 'lodash'

const propTypes = {
  data: PropTypes.arrayOf(Types.profitAndLossRecord).isRequired,
  period: PropTypes.string.isRequired,
}

const defaultProps = {}

function ProfitLossRevenueDonutChart({ data, period }) {
  const totalRevenue =
    find(data, { name: TOTAL_REVENUE_CATEGORY })?.[`${period}Amount`] ?? 0

  const filteredData = data.filter(
    (category) => category.name !== TOTAL_REVENUE_CATEGORY
  )
  const chartData = filteredData.map((category) => ({
    name: category.name,
    value: category[`${period}Amount`] ?? 0,
  }))

  const chartColors = map(REVENUE_CHART_CATEGORY, 'color')
  const hasValues = some(chartData, (category) => category.value > 0)

  return (
    <>
      <ResponsiveContainer width="100%" minHeight="350px" minWidth={340}>
        <PieChart>
          {hasValues ? (
            <>
              <Tooltip
                content={({ active, payload }) => (
                  <CustomTooltip
                    {...{
                      active,
                      payload,
                      formatter: CELL_FORMATTING.CURRENCY,
                    }}
                  />
                )}
              />
              <Pie
                data={chartData}
                dataKey="value"
                outerRadius={90}
                innerRadius={40}
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={entry.name}
                    fill={chartColors[index % chartColors.length]}
                  />
                ))}
              </Pie>
              <Legend
                iconType="circle"
                formatter={(value, entry) => (
                  <CustomLegend {...{ value, entry }} />
                )}
                payload={map(
                  REVENUE_CHART_CATEGORY,
                  ({ displayName: value, color }) => ({
                    value,
                    type: 'circle',
                    id: value,
                    color,
                  })
                )}
              />
            </>
          ) : (
            <Pie
              data={[{ name: 'No Data', value: 1 }]}
              outerRadius={90}
              innerRadius={60}
              fill="#eeeeee"
            />
          )}
        </PieChart>
      </ResponsiveContainer>
      <p className="chart-footer">{`Total Revenue: ${totalRevenue.toLocaleString(
        'en-US',
        CELL_FORMATTING.CURRENCY
      )}`}</p>
    </>
  )
}

ProfitLossRevenueDonutChart.propTypes = exact(propTypes)
ProfitLossRevenueDonutChart.defaultProps = defaultProps

export default React.memo(ProfitLossRevenueDonutChart)
