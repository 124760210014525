import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { startCase, find, map, camelCase } from 'lodash'
import { CELL_FORMATTING, REVENUE_CHART_CATEGORY } from 'config'
import * as Types from 'types'
import { CustomLegend, CustomTooltip } from '../components'
import { getMonthsToDate } from 'utils'

const propTypes = {
  data: PropTypes.arrayOf(Types.profitAndLossRecord).isRequired,
  year: PropTypes.string.isRequired,
}

const defaultProps = {}

function ProfitLossRevenueStackedBarGraph({ data, year }) {
  const months = getMonthsToDate(year)
  const mileagePercentageData = find(
    data,
    (category) =>
      category.categoryId === REVENUE_CHART_CATEGORY.MILEAGE_PERCENTAGE.sfid
  )
  const fuelSurchargeData = find(
    data,
    (category) =>
      category.categoryId === REVENUE_CHART_CATEGORY.FUEL_SURCHARGE.sfid
  )
  const accessorialOtherData = find(
    data,
    (category) =>
      category.categoryId === REVENUE_CHART_CATEGORY.ACCESSORIAL_OTHER.sfid
  )
  const formattedYearToDateData = months.map((month) => ({
    name: startCase(month),
    mileagePercentage: mileagePercentageData?.[`${month}Amount`] ?? 0,
    fuelSurcharge: fuelSurchargeData?.[`${month}Amount`] ?? 0,
    accessorialOther: accessorialOtherData?.[`${month}Amount`] ?? 0,
  }))

  return (
    <ResponsiveContainer width="100%" minHeight={400} minWidth={348}>
      <ComposedChart
        data={formattedYearToDateData}
        margin={{ left: 30, right: 30, bottom: 15 }}
        stackOffset="sign"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" angle={-90} textAnchor="end" dx={-5} dy={5} />
        <YAxis
          tickFormatter={(tick) =>
            tick.toLocaleString('en-US', CELL_FORMATTING.CURRENCY)
          }
          allowDecimals={false}
        />
        <Tooltip
          content={({ active, payload }) => (
            <CustomTooltip
              {...{
                active,
                payload,
                formatter: CELL_FORMATTING.CURRENCY,
              }}
            />
          )}
        />
        <Legend
          iconType="circle"
          formatter={(value, entry) => <CustomLegend {...{ value, entry }} />}
          wrapperStyle={{ paddingTop: '90px' }}
          payload={map(
            REVENUE_CHART_CATEGORY,
            ({ displayName: value, color }) => ({
              value,
              type: 'circle',
              id: value,
              color,
            })
          )}
        />
        {map(REVENUE_CHART_CATEGORY, ({ displayName, color }) => (
          <Bar
            key={displayName}
            dataKey={camelCase(displayName)}
            stackId="a"
            fill={color}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

ProfitLossRevenueStackedBarGraph.propTypes = exact(propTypes)
ProfitLossRevenueStackedBarGraph.defaultProps = defaultProps

export default React.memo(ProfitLossRevenueStackedBarGraph)
