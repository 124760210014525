import React from 'react'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { IonItem } from '@ionic/react'

const propTypes = {
  file: Types.file.isRequired,
}

const defaultProps = {}

const FileLineItem = ({ file }) => {
  return (
    <IonItem lines="none">
      <div className="file-line-item">
        <h3>{file.fileName}</h3>
        <p>{file.comment || 'No Note'}</p>
      </div>
    </IonItem>
  )
}

FileLineItem.propTypes = exact(propTypes)
FileLineItem.defaultProps = defaultProps

export default React.memo(FileLineItem)
