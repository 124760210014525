import { handleActions } from 'redux-actions'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
import { handleSuccess, setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import { set, get } from 'lodash/fp'
import { toNumber, orderBy } from 'lodash'
import * as actions from './actions'

const reducerKey = 'mileage-tracker'
const slice = 'root.mileage-tracker'

const initialState = {}

const serializeTrip = (trip) => ({
  ...trip,
  miles: toNumber(trip.miles),
  savings: toNumber(trip.savings),
})

const reducer = handleActions(
  {
    [actions.clearTrip]: unsetState('trip'),
    [apiActions.fetchCurrentMileageDeductionRate]: handleSuccess(
      (state, action) => {
        return set(
          'currentDeductionRate',
          toNumber(action.payload.data?.standardRate),
          state
        )
      }
    ),
    [apiActions.fetchTrip]: handleSuccess((state, action) => {
      const rawTrip = action.payload.data
      return set('trip', serializeTrip(rawTrip), state)
    }),
    [apiActions.fetchTrips]: handleSuccess((state, action) => {
      const rawTrips = action.payload.data
      const trips = rawTrips.map(({ attributes }) => serializeTrip(attributes))
      return set('trips', trips, state)
    }),
    [apiActions.updateTrip]: handleSuccess((state, action) => {
      const rawTrip = action.payload.data
      const trips = get('trips', state) || []
      const updatedTrips = trips.map((trip) => {
        if (trip.id === rawTrip.id) return serializeTrip(rawTrip)
        return trip
      })
      return set('trips', orderBy(updatedTrips, 'startTime', 'desc'), state)
    }),
    [apiActions.deleteTrip]: handleSuccess((state, action) => {
      const id = action.payload.data
      const trips = get('trips', state) || []
      return set(
        'trips',
        trips.filter((trip) => trip.id !== id),
        state
      )
    }),
    [apiActions.fetchMileageSummary]: setOnSuccess(
      'mileageSummary',
      (action) => ({
        totalSavings: toNumber(action.payload.data.totalSavings),
        totalMiles: toNumber(action.payload.data.totalMiles),
      })
    ),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  currentDeductionRate: select('currentDeductionRate'),
  trips: select('trips'),
  trip: select('trip'),
  mileageSummary: select('mileageSummary'),
}

export { reducer, selectors, reducerKey }
