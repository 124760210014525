import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  IonModal,
  IonToolbar,
  IonHeader,
  IonContent,
  IonTitle,
  IonButton,
} from '@ionic/react'
import { formatInTimeZone } from 'date-fns-tz'

const propTypes = {
  trip: Types.trip,
  onSaveTrip: PropTypes.func.isRequired,
  onDiscardTrip: PropTypes.func.isRequired,
}

const defaultProps = {
  trip: null,
}

const formatTripDate = (date, timeZone) => {
  const dateTime = date.toLocaleTimeString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })
  const abbreviatedTimeZone = formatInTimeZone(date, timeZone, 'zzz')
  return `${dateTime} ${abbreviatedTimeZone}`
}

function SaveTripErrorModal({ trip, onSaveTrip, onDiscardTrip }) {
  return (
    <IonModal id="save-error-modal" isOpen={!!trip} backdropDismiss={false}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Oops, something went wrong.</IonTitle>
        </IonToolbar>
      </IonHeader>
      {trip && (
        <IonContent>
          <div className="save-error-modal-inner">
            <p>There was an issue saving your trip.</p>
            <p>
              <strong>Please: </strong>Take a screenshot of the trip summary and
              manually add it when you have a stable connection.
            </p>
            <dl>
              <dt>Start Time</dt>
              <dd>{formatTripDate(trip.startTime, trip.startTimeZone)}</dd>
              <dt>End Time</dt>
              <dd>{formatTripDate(trip.endTime, trip.endTimeZone)}</dd>
              <dt>Miles</dt>
              <dd>
                {trip.miles.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}
              </dd>
            </dl>
            <div className="centered">
              <p>Or</p>
              <IonButton color="secondary" onClick={onSaveTrip}>
                Try saving again
              </IonButton>
              <IonButton className="text-button" onClick={onDiscardTrip}>
                Discard the trip
              </IonButton>
            </div>
          </div>
        </IonContent>
      )}
    </IonModal>
  )
}

SaveTripErrorModal.propTypes = exact(propTypes)
SaveTripErrorModal.defaultProps = defaultProps

export default SaveTripErrorModal
