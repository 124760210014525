import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  PieChart,
  Pie,
  Label,
  Tooltip,
  Cell,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import * as Types from 'types'
import {
  MILES_CHART_CATEGORY,
  TOTAL_MILES_CATEGORY,
  CELL_FORMATTING,
} from 'config'
import { CustomLegend, CustomTooltip } from '../components'
import { find, map, some } from 'lodash'

const propTypes = {
  data: PropTypes.arrayOf(Types.profitAndLossRecord).isRequired,
  period: PropTypes.string.isRequired,
}

const defaultProps = {}

function ProfitLossMilesDonutChart({ data, period }) {
  const totalMiles =
    find(data, { name: TOTAL_MILES_CATEGORY })?.[`${period}Amount`] ?? 0

  const filteredData = data.filter(
    (category) => category.name !== TOTAL_MILES_CATEGORY
  )
  const chartData = filteredData.map((category) => ({
    name: category.name,
    categoryId: category.categoryId,
    value: category[`${period}Amount`] ?? 0,
  }))

  const chartColors = map(MILES_CHART_CATEGORY, 'color')
  const hasValues = some(chartData, (category) => category.value > 0)

  const loadedMiles =
    find(
      chartData,
      (category) =>
        category.categoryId === MILES_CHART_CATEGORY.LOADED_MILES.sfid
    )?.value ?? 0

  const emptyMiles =
    find(
      chartData,
      (category) =>
        category.categoryId === MILES_CHART_CATEGORY.EMPTY_MILES.sfid
    )?.value ?? 0
  const percentEmpty = (emptyMiles / (loadedMiles + emptyMiles)).toLocaleString(
    'en-US',
    CELL_FORMATTING.PERCENTAGE
  )

  return (
    <>
      <ResponsiveContainer width="100%" minHeight="350px" minWidth={340}>
        <PieChart>
          {hasValues ? (
            <>
              <Tooltip
                content={({ active, payload }) => (
                  <CustomTooltip {...{ active, payload }} />
                )}
              />
              <Pie
                data={chartData}
                dataKey="value"
                outerRadius={90}
                innerRadius={60}
                label={false}
                labelLine={false}
              >
                <Label
                  value={percentEmpty}
                  position="center"
                  className="miles-chart-percent-label"
                />
                {chartData.map((entry, index) => (
                  <Cell
                    key={entry.name}
                    fill={chartColors[index % chartColors.length]}
                  />
                ))}
              </Pie>
            </>
          ) : (
            <Pie
              data={[{ name: 'No Data', value: 1 }]}
              outerRadius={90}
              innerRadius={60}
              fill="#eeeeee"
            />
          )}
          <Legend
            iconType="circle"
            formatter={(value, entry) => <CustomLegend {...{ value, entry }} />}
            payload={map(
              MILES_CHART_CATEGORY,
              ({ displayName: value, color }) => ({
                value,
                type: 'circle',
                id: value,
                color,
              })
            )}
          />
        </PieChart>
      </ResponsiveContainer>
      <p className="chart-footer">{`Total Miles: ${totalMiles.toLocaleString()}`}</p>
    </>
  )
}

ProfitLossMilesDonutChart.propTypes = exact(propTypes)
ProfitLossMilesDonutChart.defaultProps = defaultProps

export default React.memo(ProfitLossMilesDonutChart)
