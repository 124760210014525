import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonIcon,
} from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import { APP_PATH } from 'config'

const propTypes = {
  title: PropTypes.string,
  resetDocument: PropTypes.func.isRequired,
}
const defaultProps = {
  title: null,
}

function IonFlowHeader({ title, resetDocument }) {
  return (
    <IonHeader className="ion-no-border">
      <IonToolbar className="flow-header">
        <IonButtons slot="start">
          <IonButton
            onClick={resetDocument}
            fill="clear"
            routerLink={APP_PATH.HOME}
            routerDirection="root"
            aria-label="Exit upload process"
          >
            <IonIcon icon={closeOutline} slot="icon-only" color="light" />
          </IonButton>
        </IonButtons>
        {title && <IonTitle className="ion-text-center">{title}</IonTitle>}
      </IonToolbar>
    </IonHeader>
  )
}

IonFlowHeader.propTypes = exact(propTypes)
IonFlowHeader.defaultProps = defaultProps

export default React.memo(IonFlowHeader)
