import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { Form, Formik } from 'formik'
import { SubmitButton, SelectInput } from 'components'
import { IonItem, IonLabel } from '@ionic/react'
import * as Yup from 'yup'
import exact from 'prop-types-exact'
import { YTD_OPTION, BEGINNING } from 'config'
import { compact, toString } from 'lodash'
import { getMonthsToDate } from 'utils'

const propTypes = {
  initialValues: Types.yearPeriodFormValues.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  availableYearOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  hidePeriodSelect: PropTypes.bool,
  hideYearToDateOption: PropTypes.bool,
  hideBeginningOption: PropTypes.bool,
}

const defaultProps = {
  hidePeriodSelect: false,
  hideYearToDateOption: false,
  hideBeginningOption: false,
}

function ReportCriteriaForm({
  initialValues,
  handleSubmit,
  availableYearOptions,
  hidePeriodSelect,
  hideYearToDateOption,
  hideBeginningOption,
}) {
  const validationSchema = Yup.object({
    year: Yup.string().required('Must select a year'),
    period: !hidePeriodSelect && Yup.string().required('Must select a period'),
  })

  const getAvailablePeriodOptions = (selectedYear) => {
    const months = getMonthsToDate(selectedYear)
    const beginningOption = !hideBeginningOption && BEGINNING
    const yearToDateOption =
      !hideYearToDateOption && YTD_OPTION.periodSelectorValue
    return compact([beginningOption, ...months, yearToDateOption])
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting, values }) => (
        <Form className="filter-form">
          <div className="filter-items">
            <IonItem>
              <IonLabel>Year</IonLabel>
              <SelectInput
                name="year"
                options={availableYearOptions.map(toString)}
                label={false}
                aria-label="Select a year"
                placeholder="Select Year"
              />
            </IonItem>
            {!hidePeriodSelect && (
              <IonItem>
                <IonLabel>Period</IonLabel>
                <SelectInput
                  name="period"
                  options={getAvailablePeriodOptions(values.year)}
                  disabled={!values.year}
                  label={false}
                  aria-label="Select a period"
                  placeholder="Select Period"
                />
              </IonItem>
            )}
          </div>
          <SubmitButton color="default" submitting={isSubmitting}>
            Apply
          </SubmitButton>
        </Form>
      )}
    </Formik>
  )
}

ReportCriteriaForm.propTypes = exact(propTypes)
ReportCriteriaForm.defaultProps = defaultProps

export default React.memo(ReportCriteriaForm)
