import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  PROFIT_LOSS_NESTING_SUBROW_YTD_NAME_SETTINGS,
  PROFIT_LOSS_SECTION,
} from 'config'
import { TableSection } from '../components'
import { map } from 'lodash'
import { useSynchronizedScroll } from 'hooks'

const propTypes = {
  tableData: Types.tableData.isRequired,
  year: PropTypes.string.isRequired,
}

const defaultProps = {}

const getTableContainer = (el) => el.parentElement
function ProfitLossYTDDesktopTable({ tableData, year }) {
  useSynchronizedScroll('table', getTableContainer)

  return map(PROFIT_LOSS_SECTION, (sectionName) => (
    <TableSection
      key={sectionName}
      label={sectionName}
      columns={PROFIT_LOSS_NESTING_SUBROW_YTD_NAME_SETTINGS({
        year,
        isCurrency: sectionName !== PROFIT_LOSS_SECTION.MILES,
      })}
      data={tableData[sectionName]}
    />
  ))
}

ProfitLossYTDDesktopTable.propTypes = exact(propTypes)
ProfitLossYTDDesktopTable.defaultProps = defaultProps

export default React.memo(ProfitLossYTDDesktopTable)
