import React from 'react'
import { useField } from 'formik'
import { startCase } from 'lodash'

// Returns a new display name for a Component combining the Base Component name with the name of the HOC.
function wrapDisplayName(BaseComponent, hocName) {
  return `${hocName}(${getDisplayName(BaseComponent)})`
}

// Helper
function getDisplayName(Component) {
  if (typeof Component === 'string') {
    return Component
  }

  if (!Component) {
    return undefined
  }

  return Component.displayName || Component.name || 'Component'
}

function isEvent(value) {
  return !!(value && value.stopPropagation && value.preventDefault)
}

function composeEventHandlers({
  fieldName,
  customEventHandler,
  defaultEventHandler,
}) {
  return function onEvent(value) {
    // Create a fake event if necessary since formik expects an event object
    const changeEvent = isEvent(value)
      ? value
      : { target: { name: fieldName, value } }

    // Set the value of the checkbox to its checked state to allow it to be controlled
    if (changeEvent.detail?.checked != undefined) {
      changeEvent.target.value = changeEvent.detail.checked
    }

    defaultEventHandler(changeEvent)

    // Call custom event handler
    if (customEventHandler) customEventHandler(changeEvent)
  }
}

function withFormikAdapter() {
  return (IonInputComponent) => {
    function WrappedInputComponent({
      onChange,
      onBlur,
      name,
      type,
      label,
      ...rest
    }) {
      const [field, meta] = useField({
        name,
        type, // type must be defined in useField for checked prop to be passed into field
        ...rest,
      })
      const labelText = label === false ? null : label ?? startCase(name)

      const onChangeEventHandler = composeEventHandlers({
        fieldName: field.name,
        customEventHandler: onChange,
        defaultEventHandler: field.onChange,
      })

      return (
        <IonInputComponent
          name={field.name}
          value={field.value}
          type={type}
          label={labelText}
          onIonInput={onChangeEventHandler}
          onIonChange={onChangeEventHandler}
          onIonBlur={composeEventHandlers({
            fieldName: field.name,
            customEventHandler: onBlur,
            defaultEventHandler: field.onBlur,
          })}
          isTouched={meta.touched}
          error={meta.touched ? meta.error : null}
          {...rest}
        />
      )
    }
    WrappedInputComponent.displayName = wrapDisplayName(
      IonInputComponent,
      'withFormikAdapter'
    )
    return WrappedInputComponent
  }
}

export default withFormikAdapter
