import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  FINANCIAL_PERFORMANCE_TABLE_COLUMN,
  BALANCE_SHEET_BASE_COLUMN_SETTINGS,
  FINANCIAL_PERFORMANCE_SECTION,
} from 'config'
import { TableRowCell, TableSection } from '../components'
import { map, toUpper, snakeCase } from 'lodash'

const propTypes = {
  data: Types.balanceSheet.isRequired,
  period: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
}

const defaultProps = {}

function BalanceSheetMobileTable({ data, period, year }) {
  const baseColumnSettings = BALANCE_SHEET_BASE_COLUMN_SETTINGS()
  const nameColumn = {
    header: FINANCIAL_PERFORMANCE_TABLE_COLUMN.NAME.name,
    accessorKey: FINANCIAL_PERFORMANCE_TABLE_COLUMN.NAME.value,
    Cell: ({ cell, row }) => <TableRowCell {...{ cell, row, data, year }} />,
  }

  const monthlyColumnSetting = baseColumnSettings.find(
    (column) => column.accessorKey === `${period}Amount`
  )
  monthlyColumnSetting.header = ''
  const columns = [nameColumn, monthlyColumnSetting]

  return (
    <div className="ion-hide-md-up">
      {map(data, (categories, sectionName) => (
        <TableSection
          key={sectionName}
          label={FINANCIAL_PERFORMANCE_SECTION[toUpper(snakeCase(sectionName))]}
          columns={columns}
          data={categories}
          disableColumnPinning
        />
      ))}
    </div>
  )
}

BalanceSheetMobileTable.propTypes = exact(propTypes)
BalanceSheetMobileTable.defaultProps = defaultProps

export default React.memo(BalanceSheetMobileTable)
