import { differenceInMinutes } from 'date-fns'

const DOC_KEY = 'atbs_shared_document'
const INTENT_KEY = 'atbs_intent_checked'
const TIMEOUT_MINUTES = 10

export const setSharedDocument = (doc) => {
  sessionStorage.setItem(
    DOC_KEY,
    JSON.stringify({ time: Date.now(), payload: doc })
  )
}

export const getSharedDocument = () => {
  const document = sessionStorage.getItem(DOC_KEY)
  if (!document) return
  const { time, payload } = JSON.parse(document)
  // If the user takes a long time to log in, then the share request is treated
  // as abandoned.
  if (differenceInMinutes(new Date(), new Date(time)) > TIMEOUT_MINUTES) {
    clearSharedDocument()
    return
  }

  return payload
}

export const clearSharedDocument = () => {
  sessionStorage.removeItem(DOC_KEY)
}

export const setIntentChecked = (val = true) => {
  sessionStorage.setItem(INTENT_KEY, val)
}

export const getIntentChecked = () => {
  return sessionStorage.getItem(INTENT_KEY) === 'true'
}
