import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { IonButton, IonButtons, IonIcon } from '@ionic/react'
import { barChartOutline, listOutline } from 'ionicons/icons'
import { DATA_VIEW_TYPE } from 'config'

const propTypes = {
  setSelectedDataViewType: PropTypes.func.isRequired,
  isChartDataViewType: PropTypes.bool.isRequired,
}

const defaultProps = {}

function DataViewTypeButtons({ setSelectedDataViewType, isChartDataViewType }) {
  return (
    <IonButtons slot="end" className="ion-hide-md-up">
      <IonButton
        onClick={() => setSelectedDataViewType(DATA_VIEW_TYPE.CHART)}
        color={isChartDataViewType ? 'secondary' : 'light'}
        aria-label="Charts"
        fill="clear"
      >
        <IonIcon slot="icon-only" icon={barChartOutline} />
      </IonButton>
      <IonButton
        onClick={() => setSelectedDataViewType(DATA_VIEW_TYPE.TABLE)}
        color={!isChartDataViewType ? 'secondary' : 'light'}
        aria-label="Tables"
        fill="clear"
      >
        <IonIcon slot="icon-only" icon={listOutline} />
      </IonButton>
    </IonButtons>
  )
}

DataViewTypeButtons.propTypes = exact(propTypes)
DataViewTypeButtons.defaultProps = defaultProps

export default React.memo(DataViewTypeButtons)
