import { useState, useEffect } from 'react'
import { useIonViewWillEnter, isPlatform } from '@ionic/react'
import { registerPlugin } from '@capacitor/core'
import { LocalNotifications } from '@capacitor/local-notifications'
import { useToast } from 'hooks'
import { getTimeZone } from 'utils'
import {
  getLocalStorageTrip,
  getLocalStoragePosition,
  getLocalStorageWatcherId,
  setLocalStorageTrip,
  setLocalStoragePosition,
  setLocalStorageWatcherId,
  clearLocalStorageTrip,
  clearLocalStoragePosition,
  clearLocalStorageWatcherId,
} from '../services/mileageTrackerStorage'
import { APP_PATH, PERMISSION } from 'config'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as apiActions from 'api-actions'

const BackgroundGeolocation = registerPlugin('BackgroundGeolocation')

const GEOLOCATION_OPTIONS = {
  backgroundMessage:
    'Please make sure your device is connected to a power source or its battery is fully charged.',
  backgroundTitle: 'The app is tracking your location in the background.',
  requestPermissions: true,
  distanceFilter: 10, // in meters
}

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 3958.8 // Radius of the earth in miles
  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in miles
  return d
}

const deg2rad = (deg) => {
  return deg * (Math.PI / 180)
}

const initialTrip = {
  miles: 0,
  startTime: null,
  endTime: null,
  startTimeZone: null,
  endTimeZone: null,
}

function useMileageTracker() {
  const [trip, setTrip] = useState(null)
  const [isSettingWatcher, setIsSettingWatcher] = useState(false)
  const [isSavingTrip, setIsSavingTrip] = useState(false)
  const [unsavedTrip, setUnsavedTrip] = useState(null)
  const { showFailureToast, showWarningToast, showSuccessToast } = useToast()
  const history = useHistory()
  const dispatch = useDispatch()

  useIonViewWillEnter(() => {
    const existingTrip = getLocalStorageTrip()
    if (existingTrip) setTrip(existingTrip)
    else resetWatchData()
  })

  useEffect(() => {
    // Subscribe to changes in local storage (relies on window.dispatchEvent) to ensure the local state 'trip' is synced to the local storage version
    const syncTrip = () => {
      setTrip(getLocalStorageTrip())
    }
    window.addEventListener('storage', syncTrip)

    return () => {
      window.removeEventListener('storage', syncTrip)
    }
  }, [])

  const clearTrip = () => {
    clearLocalStorageTrip()
  }

  const setTripStart = () => {
    const existingTrip = getLocalStorageTrip()
    if (existingTrip) return

    const newTrip = {
      ...initialTrip,
      startTime: new Date(),
      startTimeZone: getTimeZone(),
    }
    setLocalStorageTrip(newTrip)
  }

  const resetWatchData = () => {
    const watcherId = getLocalStorageWatcherId()
    if (watcherId) BackgroundGeolocation.removeWatcher({ id: watcherId })
    clearLocalStoragePosition()
    clearLocalStorageWatcherId()
  }

  const showFailureToastForStartTracking = (errorMessage) => {
    showFailureToast({
      message: `There was an issue starting your trip: ${errorMessage}. Please try again.`,
      callback: () => handleStart(),
    })
  }

  const updateMileage = (location) => {
    const previousLocation = getLocalStoragePosition()
    if (previousLocation) {
      const distance = calculateDistance(
        previousLocation.latitude,
        previousLocation.longitude,
        location.latitude,
        location.longitude
      )
      if (distance > 0) {
        const existingTrip = getLocalStorageTrip()
        const updatedTrip = {
          ...existingTrip,
          miles: existingTrip.miles + distance,
        }
        setLocalStorageTrip(updatedTrip)
      }
    }
  }

  const checkNotificationPermissions = async () => {
    if (!isPlatform('android')) return true

    // Android 13+ needs permission to show notification to the user that their location is being used in the background
    let permissions = await LocalNotifications.checkPermissions()
    if (permissions.display === PERMISSION.PROMPT) {
      permissions = await LocalNotifications.requestPermissions()
    }
    return permissions.display !== PERMISSION.DENIED
  }

  const handleStart = async () => {
    try {
      const hasPermission = await checkNotificationPermissions()
      if (!hasPermission) {
        history.push(APP_PATH.MILEAGE_TRACKER.NOTIFICATION_PERMISSION_NEEDED)
        return
      }

      setIsSettingWatcher(true)
      const watcherId = await BackgroundGeolocation.addWatcher(
        GEOLOCATION_OPTIONS,
        (location, error) => {
          setIsSettingWatcher(false)
          if (error) {
            // Despite the error state, the plugin seems to add the watcher anyway, so manually remove it:
            resetWatchData()
            if (error.code === 'NOT_AUTHORIZED') {
              history.push(APP_PATH.MILEAGE_TRACKER.LOCATION_PERMISSION_NEEDED)
              return
            }
            return showFailureToastForStartTracking(error.message)
          }
          setTripStart()
          if (location) {
            updateMileage(location)
            setLocalStoragePosition(location)
          }
        }
      )
      setLocalStorageWatcherId(watcherId)
    } catch (error) {
      showFailureToastForStartTracking(error.message)
      setIsSettingWatcher(false)
    }
  }

  const handleStop = () => {
    const existingTrip = getLocalStorageTrip()
    if (existingTrip.miles === 0) {
      showWarningToast({
        message: 'Trip was not saved since 0 miles were tracked.',
      })
      resetWatchData()
      clearTrip()
      return
    }
    const updatedTrip = {
      ...existingTrip,
      endTime: new Date(),
      endTimeZone: getTimeZone(),
    }
    setLocalStorageTrip(updatedTrip)
    resetWatchData()
    saveTrip(updatedTrip)
  }

  const saveTrip = async (trip) => {
    try {
      setIsSavingTrip(true)
      const savedTrip = await dispatch(apiActions.createTrip(trip))
      showSuccessToast({
        message:
          'Your trip has been successfully saved! You may add a note and/or edit the trip.',
        duration: 6000,
      })
      history.push(`${APP_PATH.MILEAGE_TRACKER.EDIT_TRIP}/${savedTrip.id}`)
    } catch {
      setUnsavedTrip(trip)
    } finally {
      setIsSavingTrip(false)
      clearTrip()
    }
  }

  return {
    trip,
    handleStart,
    handleStop,
    isSavingTrip,
    isSettingWatcher,
    unsavedTrip,
    saveTrip,
    clearUnsavedTrip: () => setUnsavedTrip(null),
  }
}

export default useMileageTracker
