function validReferrer() {
  const referrerDomain = document.referrer.split('/')[2]
  const approvedDomains = process.env.APPROVED_REFERRER_DOMAINS?.split(',')

  return (
    !!referrerDomain &&
    !!approvedDomains &&
    approvedDomains.includes(referrerDomain)
  )
}

export default validReferrer
