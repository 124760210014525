import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  IonCard,
  IonCardContent,
  IonList,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { selectors } from '../reducer'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  DocumentStatusLineItem,
  NoDocumentContent,
  LoadingSpinner,
  PaginatedItems,
} from 'components'
import { isEmpty, map, startCase } from 'lodash'
import { DOCUMENT_CATEGORY } from 'config'

const propTypes = {
  fetchDocuments: PropTypes.func.isRequired,
  documentFilter: PropTypes.string.isRequired,
  setDocumentFilter: PropTypes.func.isRequired,
  filteredDocs: PropTypes.arrayOf(Types.doc),
}

const defaultProps = {
  filteredDocs: null,
}

function AllDocuments({
  fetchDocuments,
  documentFilter,
  setDocumentFilter,
  filteredDocs,
}) {
  const [error, setError] = useState()

  useEffect(() => {
    const requestDocuments = async () => {
      try {
        await fetchDocuments()
      } catch (errorMessage) {
        setError(`There was an issue fetching your documents: ${errorMessage}`)
      }
    }

    requestDocuments()
  }, [fetchDocuments])

  if (error) {
    return <div className="centered-content">{error}</div>
  }

  if (!filteredDocs) {
    return <LoadingSpinner />
  }

  return (
    <div className="all-documents-wrapper documents-list-wrapper">
      <IonSegment
        onIonChange={(e) => setDocumentFilter(e.detail.value)}
        value={documentFilter}
        mode="ios"
      >
        {map(DOCUMENT_CATEGORY, (category) => (
          <IonSegmentButton key={category} value={category}>
            <IonLabel>{startCase(category)}</IonLabel>
          </IonSegmentButton>
        ))}
      </IonSegment>
      <IonCard>
        <IonCardContent className="ion-text-center">
          <IonList>
            {isEmpty(filteredDocs) ? (
              <NoDocumentContent />
            ) : (
              <PaginatedItems
                key={documentFilter}
                items={filteredDocs}
                itemComponent={({ item }) => (
                  <DocumentStatusLineItem doc={item} />
                )}
              />
            )}
          </IonList>
        </IonCardContent>
      </IonCard>
    </div>
  )
}

AllDocuments.propTypes = exact(propTypes)
AllDocuments.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    documentFilter: selectors.documentFilter(state),
    filteredDocs: selectors.filteredDocs(state),
  }
}

const mapDispatchToProps = {
  fetchDocuments: apiActions.fetchDocuments,
  setDocumentFilter: actions.setDocumentFilter,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AllDocuments
)
