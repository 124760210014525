import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  SubmitButton,
  SelectInput,
  DatetimeInput,
  InfoModal,
  LoadingSpinner,
} from 'components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { PER_DIEM_ALLOCATION, OPEN_PER_DIEM_INFO_MODAL } from 'config'
import { values } from 'lodash'
import { IonLabel } from '@ionic/react'

const perDiemAllocationValues = values(PER_DIEM_ALLOCATION)

const propTypes = {
  yearOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  highlightedDates: PropTypes.arrayOf(Types.highlightedDateConfig).isRequired,
  isFetchingCalendarData: PropTypes.bool.isRequired,
  onYearChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    year: PropTypes.string.isRequired,
    dayAllocation: PropTypes.oneOf(perDiemAllocationValues).isRequired,
  }).isRequired,
}

const defaultProps = {}

const validationSchema = Yup.object({
  year: Yup.string().required('Year must be selected'),
  selectedDates: Yup.array()
    .of(Yup.date())
    .min(1, 'At least one date must be selected')
    .required('At least one date must be selected'),
  dayAllocation: Yup.string()
    .oneOf(perDiemAllocationValues)
    .required('Allocation amount is required'),
})

function PerDiemForm({
  yearOptions,
  highlightedDates,
  initialValues,
  isFetchingCalendarData,
  onYearChange,
  handleSubmit,
}) {
  const yearLabelId = 'year-label-id'
  const allocationLabelId = 'allocation-label-id'
  const dateLabelId = 'date-label-id'

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        enableReinitialize={true}
      >
        {({ isSubmitting, values: formValues }) => (
          <div className="calendar-block">
            <Form>
              <div>
                <IonLabel id={yearLabelId}>
                  <strong>1.</strong> Select Year
                </IonLabel>
                <SelectInput
                  name="year"
                  options={yearOptions}
                  label={false}
                  aria-labelledby={yearLabelId}
                  expand="block"
                  onChange={(e) => onYearChange(e.detail.value)}
                />
              </div>
              <div className="day-allocation">
                <IonLabel id={allocationLabelId}>
                  <strong>2.</strong> Select Status
                </IonLabel>
                <SelectInput
                  name="dayAllocation"
                  options={perDiemAllocationValues}
                  label={false}
                  aria-labelledby={allocationLabelId}
                  placeholder="Select a day allocation"
                  expand="block"
                />
              </div>
              <div className="date-selection">
                <IonLabel id={dateLabelId}>
                  <strong>3.</strong> Pick one or more days
                </IonLabel>
                {isFetchingCalendarData ? (
                  <div className="loading-container">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <DatetimeInput
                    name="selectedDates"
                    label={false}
                    aria-labelledby={dateLabelId}
                    presentation="date"
                    multiple={true}
                    yearValues={formValues.year}
                    highlightedDates={highlightedDates}
                  />
                )}
                <div className="calendar-key">
                  <div className="partial-day-key">
                    <div className="key-circle light-gold" />
                    <span>Partial Day</span>
                  </div>
                  <div className="full-day-key">
                    <div className="key-circle gold" />
                    <span>Full Day</span>
                  </div>
                  <div className="selected-day-key">
                    <div className="key-circle grey" />
                    <span>Selected Date(s)</span>
                  </div>
                </div>
              </div>
              <div>
                <SubmitButton color="secondary" submitting={isSubmitting} />
              </div>
            </Form>
          </div>
        )}
      </Formik>

      <InfoModal
        trigger={OPEN_PER_DIEM_INFO_MODAL}
        title="Notes & Disclosures"
        buttonText="Got It"
        className="per-diem-info-modal"
      >
        <p>
          Per Diem is a valuable tax deduction for business owners. This tool is
          designed to help you keep track of your days on the road in order to
          calculate your annual per diem tax deduction. This is not an official
          tax document. The actual dollar amount deducted from your tax returns
          will likely differ from the amount displayed in the app.
        </p>
      </InfoModal>
    </div>
  )
}

PerDiemForm.propTypes = exact(propTypes)
PerDiemForm.defaultProps = defaultProps

export default React.memo(PerDiemForm)
