import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { IonLoading } from '@ionic/react'

const propTypes = {
  spinner: PropTypes.string,
}
const defaultProps = {
  spinner: 'crescent',
}

function LoadingModal({ spinner, ...rest }) {
  return <IonLoading isOpen={true} spinner={spinner} {...rest} />
}

LoadingModal.propTypes = exact(propTypes)
LoadingModal.defaultProps = defaultProps

export default React.memo(LoadingModal)
