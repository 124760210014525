import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  BalanceSheetBarChart,
  BalanceSheetDesktopTable,
  BalanceSheetMobileTable,
  Disclaimer,
} from '../components'
import { ReportCriteriaForm } from '../forms'
import {
  IonButton,
  IonIcon,
  IonCard,
  IonCardTitle,
  IonCardContent,
} from '@ionic/react'
import { barChartOutline, listOutline } from 'ionicons/icons'
import { isPlatform } from '@ionic/core'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { LoadingSpinner, DownloadCsvButton } from 'components'
import { startCase, first, toString } from 'lodash'
import { YTD_OPTION, DATA_VIEW_TYPE } from 'config'

const propTypes = {
  fetchBalanceSheet: PropTypes.func.isRequired,
  financialPerformanceYears: PropTypes.arrayOf(PropTypes.string).isRequired,
  balanceSheet: Types.balanceSheet,
  balanceSheetChartPeriods: PropTypes.arrayOf(Types.balanceSheetChartPeriod),
}

const defaultProps = {
  balanceSheet: null,
  balanceSheetChartPeriods: null,
}

function BalanceSheet({
  fetchBalanceSheet,
  financialPerformanceYears,
  balanceSheet,
  balanceSheetChartPeriods,
}) {
  const isMobile = !isPlatform('desktop')
  const latestYear = first(financialPerformanceYears)
  const [year, setYear] = useState(latestYear)
  const [period, setPeriod] = useState(YTD_OPTION.periodSelectorValue)
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [selectedDataViewType, setSelectedDataViewType] = useState(
    DATA_VIEW_TYPE.CHART
  )
  const isChartDataViewType = selectedDataViewType === DATA_VIEW_TYPE.CHART
  const isTableDataViewType = selectedDataViewType === DATA_VIEW_TYPE.TABLE
  const showCharts = isChartDataViewType || isMobile

  useEffect(() => {
    requestBalanceSheetYear(latestYear)
  }, [latestYear, requestBalanceSheetYear])

  const requestBalanceSheetYear = useCallback(
    async (year) => {
      setIsLoading(true)
      setError()
      try {
        await fetchBalanceSheet(year)
      } catch (errorMessage) {
        setError(
          `There was an issue loading balance sheet data for ${year}: ${errorMessage}`
        )
      }
      setIsLoading(false)
    },
    [fetchBalanceSheet]
  )

  const filterBalanceSheetChartPeriods = () => {
    if (!balanceSheetChartPeriods) return
    if (period === YTD_OPTION.periodSelectorValue)
      return balanceSheetChartPeriods
    return balanceSheetChartPeriods.filter(
      (chartData) => chartData.period === period
    )
  }

  return (
    <div>
      <header>
        <h2>Report Criteria</h2>
      </header>
      <div className="criteria-form-flex">
        <ReportCriteriaForm
          initialValues={{ year: toString(year), period }}
          handleSubmit={(values) => {
            setPeriod(values.period)
            if (values.year === year) return true

            setYear(values.year)
            return requestBalanceSheetYear(values.year)
          }}
          availableYearOptions={financialPerformanceYears}
          hidePeriodSelect={isTableDataViewType}
        />
        {!isLoading && !error && (
          <div className="right-content">
            <DownloadCsvButton
              url={`/v1/balance_sheets/${year}.csv`}
              fileName={`balance-sheet-${year}.csv`}
            />
            <div className="mode-selector ion-hide-md-down ion-justify-content-end">
              <IonButton
                onClick={() => setSelectedDataViewType(DATA_VIEW_TYPE.CHART)}
                color={isChartDataViewType && 'tertiary'}
                aria-label="Switch to charts"
                fill={isChartDataViewType ? 'solid' : 'outline'}
              >
                <IonIcon slot="icon-only" icon={barChartOutline} />
              </IonButton>
              <IonButton
                onClick={() => setSelectedDataViewType(DATA_VIEW_TYPE.TABLE)}
                color={isTableDataViewType && 'tertiary'}
                aria-label="Switch to tables"
                fill={isTableDataViewType ? 'solid' : 'outline'}
              >
                <IonIcon slot="icon-only" icon={listOutline} />
              </IonButton>
            </div>
          </div>
        )}
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : error ? (
        <IonCard>
          <IonCardContent>
            <p>{error}</p>
          </IonCardContent>
        </IonCard>
      ) : (
        <>
          {showCharts ? (
            <>
              <IonCard className="chart">
                <IonCardTitle>{startCase(period)}</IonCardTitle>
                <BalanceSheetBarChart
                  data={filterBalanceSheetChartPeriods()}
                  period={period}
                />
              </IonCard>
              {period !== YTD_OPTION.periodSelectorValue && (
                <BalanceSheetMobileTable
                  data={balanceSheet}
                  year={year}
                  period={period}
                />
              )}
            </>
          ) : (
            <BalanceSheetDesktopTable data={balanceSheet} year={year} />
          )}
          <Disclaimer />
        </>
      )}
    </div>
  )
}

BalanceSheet.propTypes = exact(propTypes)
BalanceSheet.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    balanceSheet: selectors.balanceSheet(state),
    balanceSheetChartPeriods: selectors.balanceSheetChartPeriods(state),
    financialPerformanceYears: selectors.financialPerformanceYears(state),
  }
}

const mapDispatchToProps = {
  fetchBalanceSheet: apiActions.fetchBalanceSheet,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  BalanceSheet
)
