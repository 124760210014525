import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from '@ionic/react'
import { MaterialTable } from 'components'
import { getTableBodyRowProps } from 'utils'
import { FINANCIAL_PERFORMANCE_TABLE_COLUMN } from 'config'

const propTypes = {
  label: PropTypes.string,
  columns: PropTypes.arrayOf(Types.materialTableColumn).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([Types.balanceSheetRecord, Types.profitAndLossRecord])
  ).isRequired,
  doNotApplyRowTotalsStyle: PropTypes.bool,
  disableColumnPinning: PropTypes.bool,
}

const defaultProps = {
  label: null,
  doNotApplyRowTotalsStyle: false,
  disableColumnPinning: false,
}

function TableSection({
  label,
  columns,
  data,
  doNotApplyRowTotalsStyle,
  disableColumnPinning,
}) {
  return (
    <div className="table-section-wrapper">
      <IonCard>
        {label && (
          <IonCardHeader>
            <IonCardTitle>{label}</IonCardTitle>
          </IonCardHeader>
        )}
        <IonCardContent>
          <MaterialTable
            columns={columns}
            data={data}
            enableExpanding={true}
            enableExpandAll={false}
            initialState={{
              columnPinning: disableColumnPinning
                ? {}
                : {
                    left: [FINANCIAL_PERFORMANCE_TABLE_COLUMN.NAME.value],
                  },
              columnVisibility: { 'mrt-row-expand': false },
            }}
            getSubRows={(originalRow) => originalRow.children}
            defaultColumn={{
              minSize: 25,
              maxSize: 9001,
              size: 100,
            }}
            muiTableHeadCellProps={{
              align: 'right',
              sx: {
                border: 'none',
                py: 0,
                px: '12px',
                color: 'text.secondary',
                fontWeight: 'regular',
              },
            }}
            muiTableBodyRowProps={({ row }) => {
              if (doNotApplyRowTotalsStyle) return

              return getTableBodyRowProps({ row })
            }}
          />
        </IonCardContent>
      </IonCard>
    </div>
  )
}

TableSection.propTypes = exact(propTypes)
TableSection.defaultProps = defaultProps

export default React.memo(TableSection)
