import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { IonItem, IonGrid, IonRow, IonCol } from '@ionic/react'
import { Link } from 'react-router-dom'

const propTypes = {
  parentPathname: PropTypes.string.isRequired,
  transaction: Types.transaction.isRequired,
}

const defaultProps = {}

function TransactionLineItem({ parentPathname, transaction }) {
  return (
    <IonItem className="balance-sheet-transactions-card">
      <IonGrid>
        <IonRow>
          <IonCol>{transaction.date}</IonCol>
          <IonCol>{transaction.source}</IonCol>
          <IonCol>
            <Link to={`${parentPathname}/batch/${transaction.batchId}`}>
              View Document Transactions
            </Link>
          </IonCol>
          <IonCol className="right-aligned-cell">
            {transaction.formattedAmount}
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  )
}

TransactionLineItem.propTypes = exact(propTypes)
TransactionLineItem.defaultProps = defaultProps

export default React.memo(TransactionLineItem)
