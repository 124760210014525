import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { getMonthsToDate } from 'utils'
import { find, startCase, map } from 'lodash'
import { TOTAL_EXPENSES_CATEGORY, CELL_FORMATTING } from 'config'
import { CustomLegend, CustomTooltip } from '../components'

const propTypes = {
  data: Types.profitAndLossExpenses.isRequired,
  year: PropTypes.string.isRequired,
}
const defaultProps = {}

const ExpenseDataType = {
  FIXED: 'fixed',
  VARIABLE: 'variable',
}

const ExpenseChartConfig = {
  [ExpenseDataType.FIXED]: {
    displayName: 'Fixed Costs',
    color: 'var(--ion-color-danger-tint)',
  },
  [ExpenseDataType.VARIABLE]: {
    displayName: 'Variable Costs',
    color: 'var(--ion-color-success)',
  },
}

function ProfitLossExpensesBarGraph({ data, year }) {
  const months = getMonthsToDate(year)
  const fixedExpenses = find(data.fixed, (category) => {
    return category.name === TOTAL_EXPENSES_CATEGORY.TOTAL_FIXED_EXPENSES
  })
  const variableExpenses = find(data.variable, (category) => {
    return category.name === TOTAL_EXPENSES_CATEGORY.TOTAL_VARIABLE_EXPENSES
  })
  const formattedYearToDateData = months.map((month) => ({
    name: startCase(month),
    [ExpenseDataType.FIXED]: fixedExpenses?.[`${month}Amount`] ?? 0,
    [ExpenseDataType.VARIABLE]: variableExpenses?.[`${month}Amount`] ?? 0,
  }))

  return (
    <ResponsiveContainer width="100%" minHeight={400} minWidth={348}>
      <ComposedChart
        data={formattedYearToDateData}
        margin={{ left: 30, right: 30, bottom: 15 }}
        stackOffset="sign"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" angle={-90} textAnchor="end" dx={-5} dy={5} />
        <YAxis
          tickFormatter={(tick) => tick.toLocaleString()}
          allowDecimals={false}
        />
        <Legend
          iconType="circle"
          formatter={(value, entry) => (
            <CustomLegend value={value} entry={entry} />
          )}
          wrapperStyle={{ paddingTop: '90px' }}
          payload={map(ExpenseChartConfig, (config) => ({
            value: config.displayName,
            type: 'circle',
            id: config.displayName,
            color: config.color,
          }))}
        />
        <Tooltip
          content={({ active, payload }) => (
            <CustomTooltip
              active={active}
              payload={payload}
              formatter={CELL_FORMATTING.CURRENCY}
            />
          )}
        />
        <Bar
          dataKey={ExpenseDataType.FIXED}
          stackId="a"
          fill={ExpenseChartConfig[ExpenseDataType.FIXED].color}
        />
        <Bar
          dataKey={ExpenseDataType.VARIABLE}
          stackId="a"
          fill={ExpenseChartConfig[ExpenseDataType.VARIABLE].color}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

ProfitLossExpensesBarGraph.propTypes = exact(propTypes)
ProfitLossExpensesBarGraph.defaultProps = defaultProps

export default React.memo(ProfitLossExpensesBarGraph)
