import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { IonCard, useIonRouter } from '@ionic/react'
import { subHours } from 'date-fns'
import { useToast } from 'hooks'
import { APP_PATH } from 'config'
import { TripForm } from '../forms'
import { getTimeZone, formatISOInTimeZone } from 'utils'

const userTimeZone = getTimeZone()

const propTypes = {
  createTrip: PropTypes.func.isRequired,
}

const defaultProps = {}

function AddTrip({ createTrip }) {
  const { showSuccessToast, showFailureToast } = useToast()
  const router = useIonRouter()

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true)
      await createTrip({
        startTime: values.from,
        startTimeZone: userTimeZone,
        endTime: values.to,
        endTimeZone: userTimeZone,
        miles: values.miles,
        note: values.note,
      })
      showSuccessToast({
        message: 'Created successfully!',
        duration: 3000,
      })
      router.push(APP_PATH.MILEAGE_TRACKER.ALL_TRIPS, 'back', 'pop')
    } catch (e) {
      showFailureToast({
        message: 'There was an issue saving your trip. Please try again.',
        callback: () => handleSubmit(values, { setSubmitting }),
      })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className="add-trip-wrapper">
      <IonCard className="ion-padding-small">
        <TripForm
          initialValues={{
            from: formatISOInTimeZone(subHours(new Date(), 1), userTimeZone),
            to: formatISOInTimeZone(new Date(), userTimeZone),
            miles: '',
            note: '',
          }}
          handleSubmit={handleSubmit}
        />
      </IonCard>
    </div>
  )
}

AddTrip.propTypes = exact(propTypes)
AddTrip.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  createTrip: apiActions.createTrip,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddTrip)
