import write_blob from 'capacitor-blob-writer'
import { isPlatform } from '@ionic/react'
import { Filesystem, Directory } from '@capacitor/filesystem'
import { FileOpener } from '@awesome-cordova-plugins/file-opener'
import isWebsite from './isWebsite'
import { last } from 'lodash'
import { MIME_TYPE } from 'config'

async function downloadFileFromBlob({ blob, fileName, setError }) {
  const newBlob = new Blob([blob])

  if (isWebsite()) {
    const blobUrl = window.URL.createObjectURL(newBlob)

    const link = document.createElement('a')
    link.href = blobUrl
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)

    window.URL.revokeObjectURL(blobUrl)
  } else {
    const targetDirectory = isPlatform('ios')
      ? Directory.Documents
      : Directory.External
    const deviceSavePath = `downloads/${fileName}`

    try {
      await write_blob({
        path: deviceSavePath,
        blob: newBlob,
        directory: targetDirectory,
        recursive: true,
        on_fallback(error) {
          setError(error.message)
        },
      })

      const getUriResult = await Filesystem.getUri({
        directory: targetDirectory,
        path: deviceSavePath,
      })

      const path = getUriResult.uri
      const fileExtension = last(fileName.split('.'))
      const mimeType = MIME_TYPE[fileExtension.toUpperCase()]

      if (isPlatform('ios')) {
        return FileOpener.open(path, mimeType)
      }
      return FileOpener.showOpenWithDialog(path, mimeType)
    } catch (e) {
      setError(e.message)
    }
  }
}

export default downloadFileFromBlob
