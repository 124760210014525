import React from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import { SubmitButton, SelectInput } from 'components'
import * as Yup from 'yup'
import exact from 'prop-types-exact'
import { downloadFileWithFetch } from 'utils'
import * as Types from 'types'

const propTypes = {
  taxEstimateOptions: PropTypes.arrayOf(Types.taxEstimateOption).isRequired,
}

const defaultProps = {}

const validationSchema = Yup.object({
  quarter: Yup.string().required('Must select a tax quarter'),
})

const formatOptions = (options) => {
  return options.map(({ attributes: { year, quarter } }) => ({
    name: `${year}-Q${quarter}`,
    value: `${year}_${quarter}`,
  }))
}

function QuarterlyTaxEstimatesForm({ taxEstimateOptions }) {
  return (
    <Formik
      initialValues={{ quarter: '' }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors }) => {
        const [year, quarter] = values.quarter.split('_')
        const desiredFileName = `tax-estimate-${year}-Q${quarter}.pdf`
        const url = `v1/pdfs/tax_estimate/${year}/${quarter}.pdf`
        const params = {
          url,
          fileName: desiredFileName,
          setError: (errorMessage) =>
            setErrors({
              quarter: `There was an error fetching your estimate: ${errorMessage}`,
            }),
        }
        await downloadFileWithFetch(params)
      }}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form className="filter-form">
          <SelectInput
            name="quarter"
            options={formatOptions(taxEstimateOptions)}
            label={false}
            aria-label="Select a year and tax quarter"
            placeholder="Select a year and tax quarter"
          />
          <SubmitButton color="default" submitting={isSubmitting}>
            Apply
          </SubmitButton>
        </Form>
      )}
    </Formik>
  )
}

QuarterlyTaxEstimatesForm.propTypes = exact(propTypes)
QuarterlyTaxEstimatesForm.defaultProps = defaultProps

export default React.memo(QuarterlyTaxEstimatesForm)
