import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { IonList } from '@ionic/react'
import { SignInHeader } from '../components'
import PasswordEmailForm from '../forms/PasswordEmailForm'

const propTypes = {}

const defaultProps = {}

function ForgotPassword() {
  return (
    <IonList>
      <SignInHeader title="Forgot Password">
        <div>
          <p>
            Did you forget your password? Please enter your email below to
            receive a password reset link.
          </p>
        </div>
      </SignInHeader>
      <PasswordEmailForm />
    </IonList>
  )
}

ForgotPassword.propTypes = exact(propTypes)
ForgotPassword.defaultProps = defaultProps

export default React.memo(ForgotPassword)
