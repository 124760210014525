import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { QuarterlyTaxEstimatesForm } from '../forms'
import { HelpFooter } from '../components'
import { IonItem, IonIcon, IonLabel, IonSpinner } from '@ionic/react'
import { openOutline } from 'ionicons/icons'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import { isEmpty } from 'lodash'
import { IRS_PAYMENT_URL } from 'config'

const propTypes = {
  getTaxEstimateOptions: PropTypes.func.isRequired,
  taxEstimateOptions: PropTypes.arrayOf(Types.taxEstimateOption),
}

const defaultProps = {
  taxEstimateOptions: null,
}

function QuarterlyTaxEstimates({ getTaxEstimateOptions, taxEstimateOptions }) {
  const [error, setError] = useState()

  useEffect(() => {
    const requestTaxEstimateOptions = async () => {
      try {
        await getTaxEstimateOptions()
      } catch (errorMessage) {
        setError(
          `There was an issue loading your Quarterly Tax Estimates: ${errorMessage}`
        )
      }
    }

    requestTaxEstimateOptions()
  }, [getTaxEstimateOptions])

  return (
    <div>
      <header>
        <h2>Quarterly Tax Estimates</h2>
      </header>
      {!taxEstimateOptions ? (
        <IonSpinner />
      ) : error ? (
        <p>{error}</p>
      ) : isEmpty(taxEstimateOptions) ? (
        <p>You have no Quarterly Tax Estimates.</p>
      ) : (
        <QuarterlyTaxEstimatesForm taxEstimateOptions={taxEstimateOptions} />
      )}
      <IonItem
        target="_blank"
        rel="noreferrer"
        href={IRS_PAYMENT_URL}
        className="item-link"
        lines="none"
        expand="block"
        detail="false"
      >
        <IonIcon color="primary" icon={openOutline} aria-hidden />
        <IonLabel>Make IRS Payment</IonLabel>
      </IonItem>
      <HelpFooter />
    </div>
  )
}

QuarterlyTaxEstimates.propTypes = exact(propTypes)
QuarterlyTaxEstimates.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    taxEstimateOptions: selectors.taxEstimateOptions(state),
  }
}

const mapDispatchToProps = {
  getTaxEstimateOptions: apiActions.getTaxEstimateOptions,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  QuarterlyTaxEstimates
)
