import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { useParams } from 'react-router-dom'
import { LoadingSpinner, DocumentCard } from 'components'
import { IonCol, IonGrid, IonRow } from '@ionic/react'
import { fetchFileBlob } from 'utils'
import { TransactionsBatchDetails } from '../components'
import { snakeCase } from 'lodash'

const propTypes = {
  fetchTransactionsBatch: PropTypes.func.isRequired,
  clearTransactionsBatch: PropTypes.func.isRequired,
  fetchDocumentByID: PropTypes.func.isRequired,
  transactionsBatch: Types.transactionsBatch,
  document: Types.doc,
  clearDocument: PropTypes.func.isRequired,
}

const defaultProps = {
  transactionsBatch: null,
  document: null,
}

function TransactionsBatch({
  fetchTransactionsBatch,
  clearTransactionsBatch,
  fetchDocumentByID,
  transactionsBatch,
  document,
  clearDocument,
}) {
  const documentGuid = transactionsBatch?.documentGuid
  const [isLoadingDocument, setIsLoadingDocument] = useState(false)
  const [isLoadingDocumentPdf, setIsLoadingDocumentPdf] = useState(true)
  const [transactionsError, setTransactionsError] = useState()
  const [documentError, setDocumentError] = useState()
  const [pdfBlob, setPdfBlob] = useState()
  const { batchId } = useParams()

  useEffect(() => {
    const requestBatchTransactions = async () => {
      try {
        await fetchTransactionsBatch(batchId)
      } catch (errorMessage) {
        setTransactionsError(
          `There was an issue loading transactions for batch #${batchId}: ${errorMessage}`
        )
      }
    }

    requestBatchTransactions()

    return clearTransactionsBatch
  }, [batchId, fetchTransactionsBatch, clearTransactionsBatch])

  useEffect(() => {
    const requestDocument = async () => {
      try {
        await fetchDocumentByID(documentGuid)
      } catch (errorMessage) {
        setDocumentError(
          `There was an issue loading the document: ${errorMessage}`
        )
      }
      setIsLoadingDocument(false)
    }

    if (documentGuid) {
      setIsLoadingDocument(true)
      requestDocument()
    }

    return clearDocument
  }, [documentGuid, fetchDocumentByID, clearDocument])

  useEffect(() => {
    const requestPdf = async () => {
      try {
        const blob = await fetchFileBlob({
          url: `v1/pdfs/transaction_batch_document/${batchId}.pdf`,
        })
        if (blob) {
          setPdfBlob(blob)
        }
      } catch (errorMessage) {
        setDocumentError(
          `There was an issue loading the document PDF: ${errorMessage}`
        )
      }
      setIsLoadingDocumentPdf(false)
    }

    requestPdf()
  }, [batchId])

  if (transactionsError) return <p>{transactionsError}</p>
  if (!transactionsBatch) return <LoadingSpinner />

  return (
    <IonGrid>
      <IonRow>
        <IonCol size="12" sizeMd="6">
          {documentError ? (
            <p>{documentError}</p>
          ) : isLoadingDocument || isLoadingDocumentPdf ? (
            // Wrap in div to keep spinner at top of view
            <div>
              <LoadingSpinner>
                <p>Document Loading...</p>
              </LoadingSpinner>
            </div>
          ) : (
            <DocumentCard
              document={document}
              pdfBlob={pdfBlob}
              fileName={document ? snakeCase(document.title) : batchId}
            />
          )}
        </IonCol>
        <IonCol size="12" sizeMd="5">
          <TransactionsBatchDetails transactionsBatch={transactionsBatch} />
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

TransactionsBatch.propTypes = propTypes
TransactionsBatch.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    transactionsBatch: selectors.transactionsBatch(state),
    document: selectors.document(state),
  }
}

const mapDispatchToProps = {
  fetchTransactionsBatch: apiActions.fetchTransactionsBatch,
  fetchDocumentByID: apiActions.fetchDocumentByID,
  clearTransactionsBatch: actions.clearTransactionsBatch,
  clearDocument: actions.clearDocument,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TransactionsBatch
)
