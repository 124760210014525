import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonList,
} from '@ionic/react'
import { FileLineItem, DocumentViewer, NoDocumentContent } from 'components'
import { format, parseISO } from 'date-fns'
import { DOCUMENT_PROCESSED_STATUS } from 'config'

const propTypes = {
  fileName: PropTypes.string.isRequired,
  document: Types.doc,
  pdfBlob: PropTypes.object,
}

const defaultProps = {
  document: null,
  pdfBlob: null,
}

function DocumentCard({ fileName, document, pdfBlob }) {
  return (
    <div className="document-show-wrapper">
      <IonCard>
        <div className="document-show-preview-pane">
          {pdfBlob ? (
            <DocumentViewer
              fileName={fileName}
              status={document?.status || DOCUMENT_PROCESSED_STATUS}
              pdfBlob={pdfBlob}
            />
          ) : (
            <NoDocumentContent theme="dark">
              <p>No document preview available.</p>
              {document && document.status !== DOCUMENT_PROCESSED_STATUS && (
                <p>
                  It should be available after your document is fully processed.
                </p>
              )}
            </NoDocumentContent>
          )}
        </div>

        {document && (
          <>
            <IonCardHeader>
              <IonCardTitle>
                <div className="card-title-row">
                  <h2 className="card-title">{document.title}</h2>
                </div>
              </IonCardTitle>
              <IonCardSubtitle>
                <div className="block-card-row">
                  <div>
                    {format(parseISO(document.createdAt), 'MM-dd-yyyy')}
                  </div>
                </div>
              </IonCardSubtitle>
            </IonCardHeader>

            <IonCardContent>
              <IonList className="files-list">
                {document.files.map((file) => {
                  return <FileLineItem key={file.id} file={file} />
                })}
              </IonList>
            </IonCardContent>
          </>
        )}
      </IonCard>
    </div>
  )
}

DocumentCard.propTypes = exact(propTypes)
DocumentCard.defaultProps = defaultProps

export default React.memo(DocumentCard)
