import {
  ACCEPTED_FILE_TYPES,
  ACCEPTED_FILE_TYPES_COPY,
  FILE_SIZE_LIMIT,
  FILE_SIZE_LIMIT_COPY,
} from 'config'

/**
 * @name validateFile
 * @param {Object} file
 * @param {Object} file.blob - A blob representation of the file
 * @param {string} file.mimeType - The mimetype of the file
 * @returns {[string]} Returns an array of error messages
 */
function validateFile(file) {
  let errors = []
  if (!isAcceptedFileType(file)) {
    errors.push(ACCEPTED_FILE_TYPES_COPY)
  }
  if (!isAcceptedFileSize(file)) {
    errors.push(FILE_SIZE_LIMIT_COPY)
  }

  return errors
}

// ---- HELPERS -----
function isAcceptedFileType(file) {
  return ACCEPTED_FILE_TYPES.includes(file.mimeType)
}

function isAcceptedFileSize(file) {
  return file.blob.size <= FILE_SIZE_LIMIT
}

export default validateFile
