import React from 'react'
import exact from 'prop-types-exact'
import { CURRENT_YEAR } from 'config'

const propTypes = {}

const defaultProps = {}

function Footer() {
  return (
    <footer className="page-footer">
      <div className="inner">
        <ul>
          <li>
            <a href="tel:888-640-4829">(888) 640-4829</a>
          </li>
          <li>
            <a href="https://www.atbs.com/" target="_blank" rel="noreferrer">
              www.ATBS.com
            </a>
          </li>
          <li>
            <p>600 12th Street, Suite 200, Golden, CO 80401</p>
          </li>
          <li>
            <p>Copyright © {CURRENT_YEAR} ATBS</p>
          </li>
        </ul>
        <ul>
          <li>
            <a
              href="https://www.atbs.com/mobile-app"
              target="_blank"
              rel="noreferrer"
            >
              Help
            </a>
          </li>
          <li>
            <a
              href="https://www.atbs.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

Footer.propTypes = exact(propTypes)
Footer.defaultProps = defaultProps

export default React.memo(Footer)
