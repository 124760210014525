import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { RecentDocumentsCard, ConsultantContactCard } from '../components'

const propTypes = {
  fetchLatestDocs: PropTypes.func.isRequired,
  fetchConsultantContact: PropTypes.func.isRequired,
  latestDocs: PropTypes.arrayOf(Types.doc),
}

const defaultProps = {
  latestDocs: null,
}

function LimitedUserHome({
  fetchLatestDocs,
  fetchConsultantContact,
  latestDocs,
}) {
  return (
    <div className="home-dashboard-wrapper no-margin-top">
      <RecentDocumentsCard
        fetchLatestDocs={fetchLatestDocs}
        latestDocs={latestDocs}
      />
      <ConsultantContactCard fetchConsultantContact={fetchConsultantContact} />
    </div>
  )
}

LimitedUserHome.propTypes = propTypes
LimitedUserHome.defaultProps = defaultProps

export default React.memo(LimitedUserHome)
