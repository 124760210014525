import React from 'react'
import PropTypes from 'prop-types'
import {
  IonItem,
  IonDatetime,
  IonLabel,
  IonPopover,
  IonDatetimeButton,
  IonNote,
} from '@ionic/react'
import { withFormikAdapter } from 'utils'
import classnames from 'classnames'

const propTypes = {
  name: PropTypes.string.isRequired,
  labelComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  error: PropTypes.string,
  isTouched: PropTypes.bool,
  label: PropTypes.string.isRequired,
}

const defaultProps = {
  labelComponent: IonLabel,
}

function PopoverDateInput({
  name,
  label,
  labelComponent: LabelComponent,
  error,
  isTouched,
  ...rest
}) {
  const datetimeId = `datetime-${name}`

  return (
    <React.Fragment>
      <IonItem>
        <div className="error-wrapper">
          <IonDatetimeButton
            className={classnames({
              'ion-invalid': error,
              'ion-touched': isTouched,
            })}
            id={datetimeId}
            datetime={datetimeId}
          />
          <LabelComponent slot="end">{label}</LabelComponent>
          <IonNote className="error-text datetime-error-text">{error}</IonNote>
        </div>
      </IonItem>
      <IonPopover trigger={datetimeId} keepContentsMounted={true}>
        <IonDatetime name={name} id={datetimeId} {...rest} />
      </IonPopover>
    </React.Fragment>
  )
}

PopoverDateInput.propTypes = propTypes
PopoverDateInput.defaultProps = defaultProps

export default withFormikAdapter()(PopoverDateInput)
