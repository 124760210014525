import React from 'react'
import exact from 'prop-types-exact'
import {
  IonCard,
  IonRouterLink,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  isPlatform,
  IonImg,
} from '@ionic/react'
import { APP_STORE_LINK } from 'config'
import AppleStore from 'images/apple-store.png'
import GooglePlay from 'images/google-play.png'

const propTypes = {}
const defaultProps = {}

const AppleAppStore = () => (
  <IonRouterLink
    href={APP_STORE_LINK.APPLE}
    target="_blank"
    aria-label="Visit the ATBS app on the Apple App Store"
  >
    <IonImg src={AppleStore} alt="" />
  </IonRouterLink>
)

const GooglePlayStore = () => (
  <IonRouterLink
    href={APP_STORE_LINK.GOOGLE}
    target="_blank"
    aria-label="Visit the ATBS app on the Google Play Store"
  >
    <IonImg src={GooglePlay} alt="" />
  </IonRouterLink>
)

const TrackingUnavailable = () => {
  return (
    <IonCard className="card-flex ion-padding">
      <div>
        <IonCardHeader>
          <IonCardTitle>
            Mileage tracking is only available on the mobile app!
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p>The feature can be used only on the app.</p>
          <p>
            Please try it on the app or download the app from the app store.
          </p>
        </IonCardContent>
      </div>
      <div className="items-flex">
        {isPlatform('mobile') ? (
          isPlatform('ios') ? (
            <AppleAppStore />
          ) : (
            <GooglePlayStore />
          )
        ) : (
          <>
            <AppleAppStore />
            <GooglePlayStore />
          </>
        )}
      </div>
    </IonCard>
  )
}

TrackingUnavailable.propTypes = exact(propTypes)
TrackingUnavailable.defaultProps = defaultProps

export default TrackingUnavailable
