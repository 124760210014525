import { Filesystem } from '@capacitor/filesystem'
import { FilePicker } from '@capawesome/capacitor-file-picker'
import { convertBase64ToBlob, validateFile } from 'utils'
import { ACCEPTED_FILE_TYPES, PLUGIN_ERROR_MESSAGE } from 'config'
import { first, isEmpty, startsWith } from 'lodash'
import { useToast } from 'hooks'

function useFileSystem() {
  const { showFailureToast } = useToast()

  const checkPermissions = async () => {
    const permissions = await Filesystem.checkPermissions()
    return permissions
  }

  const pickFiles = async ({ acceptedTypes }) => {
    try {
      const { files } = await FilePicker.pickFiles({
        types: acceptedTypes,
        limit: 1,
        readData: true,
      })
      return files.map(setFileBlob)
    } catch (error) {
      if (startsWith(error.message, PLUGIN_ERROR_MESSAGE.PICKFILES_CANCELED))
        return
      throw error
    }
  }

  const selectDocumentFile = async () => {
    const files = await pickFiles({ acceptedTypes: ACCEPTED_FILE_TYPES })

    if (!isEmpty(files)) {
      const file = first(files)
      const failureCallback = () => selectDocumentFile()

      const showRetryFailureToast = (message) => {
        return showFailureToast({
          message,
          callback: failureCallback,
          duration: 12000,
        })
      }

      if (!file.blob) {
        return showRetryFailureToast(
          'There was an issue accepting this file. Please try again or choose a new file.'
        )
      }

      const errors = validateFile(file)
      if (!isEmpty(errors)) {
        return showRetryFailureToast(first(errors)) // only show first error (for now)
      }

      return file
    }
  }

  return {
    checkPermissions,
    pickFiles,
    selectDocumentFile,
  }
}

// Helpers
function setFileBlob(file) {
  if (file.blob || !file.data) return file

  file.blob = convertBase64ToBlob(file.data, file.mimeType)
  return file
}

export default useFileSystem
