import { handleActions } from 'redux-actions'
import { selectorForSlice } from 'lp-redux-utils'
import { setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'

const reducerKey = 'per-diem'
const slice = 'root.per-diem'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchPerDiem]: setOnSuccess('perDiem'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  perDiem: select('perDiem'),
}

export { reducer, selectors, reducerKey }
