import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
} from '@ionic/react'
import { mail, call } from 'ionicons/icons'
import { LoadingSpinner } from 'components'

const propTypes = {
  fetchConsultantContact: PropTypes.func.isRequired,
}

const defaultProps = {}

function ConsultantContactCard({ fetchConsultantContact }) {
  const [isLoading, setIsLoading] = useState(true)
  const [consultant, setConsultant] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    const fetchConsultant = async () => {
      try {
        const consultant = await fetchConsultantContact()
        setConsultant(consultant)
      } catch (errorMessage) {
        setError(`There was an issue loading your Consultant: ${errorMessage}`)
      }
      setIsLoading(false)
    }

    fetchConsultant()
  }, [fetchConsultantContact])

  return (
    <IonCard className="consultant-card">
      {isLoading ? (
        <LoadingSpinner />
      ) : error ? (
        <IonCardContent>
          <p>{error}</p>
        </IonCardContent>
      ) : (
        <>
          <IonCardHeader>
            <div className="initials-circle">
              <span>{consultant.initials}</span>
            </div>
            <div>
              <IonCardSubtitle>Business Consultant</IonCardSubtitle>
              <IonCardTitle>{consultant.fullName}</IonCardTitle>
            </div>
          </IonCardHeader>
          <IonCardContent>
            {consultant.email && (
              <>
                <IonButton
                  className="ion-hide-md-up"
                  color="medium"
                  href={`mailto:${consultant.email}`}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={`Email your consultant at ${consultant.email}`}
                >
                  <IonIcon slot="icon-only" icon={mail} />
                </IonButton>
                <IonButton
                  className="ion-hide-md-down"
                  color="medium"
                  href={`mailto:${consultant.email}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <IonIcon slot="start" icon={mail} />
                  {consultant.email}
                </IonButton>
              </>
            )}
            {consultant.phone && (
              <>
                <IonButton
                  className="ion-hide-md-up"
                  color="medium"
                  href={`tel:${consultant.phone}`}
                  aria-label={`Call your consultant at ${consultant?.phone}`}
                >
                  <IonIcon slot="icon-only" icon={call} />
                </IonButton>
                <IonButton
                  className="ion-hide-md-down"
                  color="medium"
                  href={`tel:${consultant.phone}`}
                >
                  <IonIcon slot="start" icon={call} />
                  {consultant.phone}
                </IonButton>
              </>
            )}
          </IonCardContent>
        </>
      )}
    </IonCard>
  )
}

ConsultantContactCard.propTypes = exact(propTypes)
ConsultantContactCard.defaultProps = defaultProps

export default React.memo(ConsultantContactCard)
