import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { IonAlert } from '@ionic/react'

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
}

const defaultProps = {
  cancelText: 'Cancel',
  confirmText: 'Confirm',
}

function ConfirmationAlert({
  isOpen,
  onClose,
  title,
  message,
  cancelText,
  confirmText,
  onConfirm,
}) {
  return (
    <IonAlert
      id="warning-alert"
      isOpen={isOpen}
      onDidDismiss={onClose}
      header={title}
      message={message}
      buttons={[
        {
          text: cancelText,
          role: 'cancel',
          handler: onClose,
          cssClass: 'alert-button-cancel',
        },
        {
          text: confirmText,
          role: 'confirm',
          handler: onConfirm,
          cssClass: 'alert-button-confirm',
        },
      ]}
    />
  )
}

ConfirmationAlert.propTypes = exact(propTypes)
ConfirmationAlert.defaultProps = defaultProps

export default React.memo(ConfirmationAlert)
