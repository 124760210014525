export function getLocalStorageTrip() {
  const trip = localStorage.getItem('trip')
  if (!trip) return

  const parsedTrip = JSON.parse(trip)
  return {
    ...parsedTrip,
    startTime: parseDate(parsedTrip.startTime),
    endTime: parseDate(parsedTrip.endTime),
  }
}

export function setLocalStorageTrip(trip) {
  localStorage.setItem('trip', JSON.stringify(trip))
  // For event listener in useTripStorage:
  window.dispatchEvent(new Event('storage')) // an event must be manually dispatched since the window _storage_ event does not work on the same browsing context
}

export function clearLocalStorageTrip() {
  localStorage.removeItem('trip')
  window.dispatchEvent(new Event('storage'))
}

export function getLocalStoragePosition() {
  const position = localStorage.getItem('position')
  if (!position) return

  return JSON.parse(position)
}

export function setLocalStoragePosition(position) {
  localStorage.setItem('position', JSON.stringify(position))
}

export function clearLocalStoragePosition() {
  localStorage.removeItem('position')
}

export function getLocalStorageWatcherId() {
  const watcherId = localStorage.getItem('watcherId')
  if (!watcherId) return

  return JSON.parse(watcherId)
}

export function setLocalStorageWatcherId(id) {
  localStorage.setItem('watcherId', JSON.stringify(id))
}

export function clearLocalStorageWatcherId() {
  localStorage.removeItem('watcherId')
}

// private

function parseDate(string) {
  if (!string) return null
  return new Date(Date.parse(string))
}
