import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { IonGrid, IonRow, IonCol, IonCard, IonCardTitle } from '@ionic/react'
import {
  ProfitLossMilesDonutChart,
  ProfitLossRevenueDonutChart,
  ProfitLossExpensesDonutChart,
  ProfitLossMilesStackedBarGraph,
  ProfitLossRevenueStackedBarGraph,
  TableCard,
  ProfitLossExpensesBarGraph,
  ProfitLossNetIncomeBarGraph,
} from '../components'
import { YTD_OPTION } from 'config'

const propTypes = {
  isRideShareUser: PropTypes.bool.isRequired,
  period: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  netIncomeData: PropTypes.arrayOf(Types.profitAndLossRecord).isRequired,
  milesData: PropTypes.arrayOf(Types.profitAndLossRecord).isRequired,
  revenueData: PropTypes.arrayOf(Types.profitAndLossRecord).isRequired,
  expensesData: Types.profitAndLossExpenses.isRequired,
  liabilitiesData: PropTypes.arrayOf(Types.profitAndLossRecord).isRequired,
}

const defaultProps = {}

function ProfitAndLossCharts({
  isRideShareUser,
  period,
  year,
  netIncomeData,
  milesData,
  revenueData,
  expensesData,
  liabilitiesData,
}) {
  const isYearToDate = period === YTD_OPTION.periodSelectorValue

  return (
    <IonGrid className="ion-justify-content-center charts">
      {!isRideShareUser && (
        <IonRow>
          <IonCol>
            <IonCard className="chart">
              <IonCardTitle>Miles</IonCardTitle>
              {isYearToDate ? (
                <ProfitLossMilesStackedBarGraph data={milesData} year={year} />
              ) : (
                <ProfitLossMilesDonutChart data={milesData} period={period} />
              )}
            </IonCard>
          </IonCol>
          <IonCol>
            <IonCard className="chart">
              <IonCardTitle>Revenue</IonCardTitle>
              {isYearToDate ? (
                <ProfitLossRevenueStackedBarGraph
                  data={revenueData}
                  year={year}
                />
              ) : (
                <ProfitLossRevenueDonutChart
                  data={revenueData}
                  period={period}
                />
              )}
            </IonCard>
          </IonCol>
        </IonRow>
      )}
      {isYearToDate && (
        <IonRow>
          {!isRideShareUser && (
            <IonCol>
              <IonCard className="chart">
                <IonCardTitle>Total Expenses</IonCardTitle>
                <ProfitLossExpensesBarGraph data={expensesData} year={year} />
              </IonCard>
            </IonCol>
          )}
          <IonCol>
            <IonCard className="chart">
              <IonCardTitle>Net Income</IonCardTitle>
              <ProfitLossNetIncomeBarGraph data={netIncomeData} year={year} />
            </IonCard>
          </IonCol>
        </IonRow>
      )}
      {!isYearToDate && (
        <IonRow>
          {!isRideShareUser && (
            <IonCol>
              <IonCard className="chart">
                <IonCardTitle>Expenses</IonCardTitle>
                <ProfitLossExpensesDonutChart
                  data={expensesData}
                  period={period}
                />
              </IonCard>
            </IonCol>
          )}
          <IonCol className="tables-with-totals">
            <TableCard
              title="Net Income"
              data={netIncomeData}
              period={period}
            />
            {!isRideShareUser && (
              <TableCard
                title="Liabilities"
                data={liabilitiesData}
                period={period}
              />
            )}
          </IonCol>
        </IonRow>
      )}
    </IonGrid>
  )
}

ProfitAndLossCharts.propTypes = exact(propTypes)
ProfitAndLossCharts.defaultProps = defaultProps

export default React.memo(ProfitAndLossCharts)
