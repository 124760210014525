import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { isEmpty, startCase } from 'lodash'

const propTypes = {
  active: PropTypes.bool.isRequired,
  formatter: Types.cellFormatter,
  payload: PropTypes.arrayOf(Types.tooltipPayload),
}

const defaultProps = {
  payload: null,
  formatter: {},
}

function CustomTooltip({ active, payload, formatter }) {
  if (!active || isEmpty(payload)) return null

  return (
    <div className="custom-tooltip">
      {payload.map((category) => {
        const background =
          category.stroke ??
          category.fill ??
          category.payload.fill ??
          category.payload.stroke

        return (
          <div key={category.name} className="tooltip-row">
            <div style={{ background }} className="tooltip-color-swatch" />
            <p className="label">
              {startCase(category.name)}:{' '}
              {category.value.toLocaleString('en-US', formatter)}
            </p>
          </div>
        )
      })}
    </div>
  )
}

CustomTooltip.propTypes = exact(propTypes)
CustomTooltip.defaultProps = defaultProps

export default React.memo(CustomTooltip)
