import { handleActions } from 'redux-actions'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
import { setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import { createSelector } from 'reselect'
import { startCase, isEmpty, compact } from 'lodash'
import {
  BEGINNING,
  MONTHS,
  BS_CHART_TOTAL_CATEGORY,
  BREAKEVEN_POINT_CATEGORY,
} from 'config'
import * as actions from './actions'

const reducerKey = 'financial-performance'
const slice = 'root.financial-performance'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchProfitPlan]: setOnSuccess('profitPlan'),
    [apiActions.fetchProfitAndLoss]: setOnSuccess('profitAndLoss'),
    [apiActions.fetchBalanceSheet]: setOnSuccess('balanceSheet'),
    [apiActions.fetchFinancialPerformanceYearOptions]: setOnSuccess(
      'financialPerformanceYearOptions'
    ),
    [apiActions.fetchTransactionsByCategory]: setOnSuccess('rawTransactions'),
    [apiActions.fetchTransactionsBatch]: setOnSuccess('transactionsBatch'),
    [apiActions.fetchDocumentByID]: setOnSuccess('document'),
    [actions.clearDocument]: unsetState('document'),
    [actions.clearTransactionsBatch]: unsetState('transactionsBatch'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  profitPlan: select('profitPlan'),
  profitAndLoss: select('profitAndLoss'),
  balanceSheet: select('balanceSheet'),
  financialPerformanceYearOptions: select('financialPerformanceYearOptions'),
  rawTransactions: select('rawTransactions'),
  profitAndLossMiles: select('profitAndLoss.miles'),
  profitAndLossRevenue: select('profitAndLoss.revenue'),
  profitAndLossLiability: select('profitAndLoss.liability'),
  transactionsBatch: select('transactionsBatch'),
  document: select('document'),
}

// Computed

selectors.financialPerformanceYears = createSelector(
  [selectors.financialPerformanceYearOptions],
  (financialPerformanceYearOptions) => {
    if (!financialPerformanceYearOptions) return null
    return financialPerformanceYearOptions.map(({ attributes }) =>
      attributes.year.toString()
    )
  }
)

selectors.balanceSheetChartPeriods = createSelector(
  [selectors.balanceSheet],
  (balanceSheet) => {
    if (!balanceSheet) return null
    const { assets, liability } = balanceSheet

    const formattedChartData = [BEGINNING, ...MONTHS].map((period) => {
      const totalAssets = assets.find(
        (category) => category.name === BS_CHART_TOTAL_CATEGORY.TOTAL_ASSETS
      )[`${period}Amount`]
      const totalLiabilities = liability.find(
        (category) =>
          category.name === BS_CHART_TOTAL_CATEGORY.TOTAL_LIABILITIES
      )[`${period}Amount`]

      return {
        displayName: startCase(period),
        period,
        totalAssets,
        totalLiabilities,
      }
    })

    return formattedChartData
  }
)

selectors.categoryTransactions = createSelector(
  [selectors.rawTransactions],
  (rawTransactions) => {
    if (!rawTransactions) return null

    return rawTransactions.map(({ id, attributes }) => ({ id, ...attributes }))
  }
)

selectors.profitAndLossExpenses = createSelector(
  [selectors.profitAndLoss],
  (profitAndLoss) => {
    if (!profitAndLoss) return null
    return {
      fixed: profitAndLoss.fixed,
      variable: profitAndLoss.variable,
    }
  }
)

selectors.businessWhatIfData = createSelector(
  [selectors.profitPlan],
  (profitPlan) => {
    if (!profitPlan || isEmpty(profitPlan.whatIf?.businessScenario)) return null

    const { currentProfitPlan, milesPerGallonIncrease, milesPerWeekIncrease } =
      profitPlan.whatIf.businessScenario
    return compact([
      currentProfitPlan && {
        name: 'Current Profit Plan',
        ...currentProfitPlan,
      },
      milesPerGallonIncrease && {
        name: `Increase Fuel Economy by ${milesPerGallonIncrease.whatIfAmount} MPG`,
        ...milesPerGallonIncrease,
      },
      milesPerWeekIncrease && {
        name: `Increase Miles/Revenue by ${milesPerWeekIncrease.whatIfAmount} miles/wk`,
        ...milesPerWeekIncrease,
      },
    ])
  }
)

selectors.personalWhatIfData = createSelector(
  [selectors.profitPlan],
  (profitPlan) => {
    if (!profitPlan || isEmpty(profitPlan.whatIf?.personalScenario)) return null

    const {
      personalExpensesReductionPerMonth,
      milesToBreakeven,
      revenueToBreakeven,
    } = profitPlan.whatIf.personalScenario
    return compact([
      personalExpensesReductionPerMonth && {
        name: `Reduce Personal Expenses by $${personalExpensesReductionPerMonth.whatIfAmount}/Month`,
        ...personalExpensesReductionPerMonth,
      },
      milesToBreakeven && {
        name: BREAKEVEN_POINT_CATEGORY.MILES,
        ...milesToBreakeven,
      },
      revenueToBreakeven && {
        name: BREAKEVEN_POINT_CATEGORY.REVENUE,
        ...revenueToBreakeven,
      },
    ])
  }
)

export { reducer, selectors, reducerKey }
