import React from 'react'
import PropTypes from 'prop-types'
import {
  IonItem,
  IonLabel,
  IonRadioGroup,
  IonRadio,
  IonNote,
} from '@ionic/react'
import { startCase } from 'lodash'
import { withFormikAdapter } from 'utils'
import * as Types from 'types'

const propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(Types.inputOption).isRequired,
  labelComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
}

const defaultProps = {
  labelComponent: IonLabel,
}

function RadioInput({
  name,
  label,
  labelComponent: LabelComponent,
  error,
  options,
  ...rest
}) {
  return (
    <IonItem>
      <LabelComponent slot="end">{label}</LabelComponent>
      <div className="error-wrapper">
        <IonRadioGroup name={name} {...rest}>
          {options.map((option) => {
            const { name, value } = option
            return (
              <IonRadio
                slot="start"
                value={value || option}
                key={value || option}
              >
                {name || startCase(option)}
              </IonRadio>
            )
          })}
        </IonRadioGroup>
        <IonNote className="error-text">{error}</IonNote>
      </div>
    </IonItem>
  )
}

RadioInput.propTypes = propTypes
RadioInput.defaultProps = defaultProps

export default withFormikAdapter()(RadioInput)
