import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { IonModal, IonNav, isPlatform } from '@ionic/react'
import UploadModalPageTypeContent from './UploadModalPageTypeContent'
import { values } from 'lodash'
import { UPLOAD_MODAL_BREAKPOINT } from 'config'

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  addFileToDocument: PropTypes.func.isRequired,
  setUploadedPhoto: PropTypes.func.isRequired,
}

const defaultProps = {}

function UploadModal({
  isOpen,
  setIsOpen,
  addFileToDocument,
  setUploadedPhoto,
}) {
  const isDesktop = isPlatform('desktop')
  const modalRef = useRef()
  const changeModalHeight = (breakpointSize) => {
    if (!modalRef.current || isDesktop) return

    modalRef.current.setCurrentBreakpoint(breakpointSize)
  }

  return (
    <IonModal
      isOpen={isOpen}
      onWillDismiss={() => setIsOpen(false)}
      className="fab-modal"
      breakpoints={values(UPLOAD_MODAL_BREAKPOINT)}
      initialBreakpoint={UPLOAD_MODAL_BREAKPOINT.TALL}
      ref={modalRef}
      handle={false}
    >
      <IonNav
        root={() => (
          <UploadModalPageTypeContent
            setIsOpen={setIsOpen}
            changeModalHeight={changeModalHeight}
            addFileToDocument={addFileToDocument}
            setUploadedPhoto={setUploadedPhoto}
          />
        )}
      />
    </IonModal>
  )
}

UploadModal.propTypes = exact(propTypes)
UploadModal.defaultProps = defaultProps

export default React.memo(UploadModal)
