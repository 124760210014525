import { isTotalRow, isMasterTotalRow } from 'utils'

function getTableBodyRowProps({ row }) {
  const tableRowName = row.original.name || row.original.description
  if (!tableRowName) return

  const isMasterRow = isMasterTotalRow(tableRowName)
  if (!isTotalRow(tableRowName) && !isMasterRow) return

  return {
    sx: {
      td: {
        fontWeight: 'var(--ion-font-weight-bold)',
        backgroundColor: isMasterRow
          ? 'var(--ion-color-master-row)'
          : 'var(--ion-color-total-row)',
      },
    },
  }
}

export default getTableBodyRowProps
