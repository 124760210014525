// App-wide authentication logic goes here.

const TOKEN_NAME = 'bearerToken'
const REMEMBER_ME_ACCOUNT = 'rememberMeAccount'

function setSessionToken(token) {
  if (token) localStorage.setItem(TOKEN_NAME, token)
}

function getSessionToken() {
  return localStorage.getItem(TOKEN_NAME)
}

function clearSessionToken() {
  return localStorage.removeItem(TOKEN_NAME)
}

function hasActiveSession() {
  return getSessionToken() !== null
}

function setAccount(account) {
  localStorage.setItem(REMEMBER_ME_ACCOUNT, account)
}

function getAccount() {
  return localStorage.getItem(REMEMBER_ME_ACCOUNT)
}

function clearAccount() {
  return localStorage.removeItem(REMEMBER_ME_ACCOUNT)
}

export {
  setAccount,
  getAccount,
  clearAccount,
  setSessionToken,
  getSessionToken,
  clearSessionToken,
  hasActiveSession,
}
