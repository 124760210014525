import { useLocation } from 'react-router-dom'
import {
  APP_PATH,
  INACTIVE_ACCESS_HOME_PATH,
  USER_TYPE,
  LIMITED_USER_ALLOWED_PATHS,
} from 'config'

function useRestrictedAccessRedirectPath(currentUser) {
  const { pathname } = useLocation()
  if (!currentUser) return

  const { clientCustomerType, isLimited, isActive } = currentUser

  // inactive users can only view tax-returns and resources
  if (
    !isActive &&
    ![APP_PATH.TAX.RETURNS, APP_PATH.RESOURCES].includes(pathname)
  ) {
    return INACTIVE_ACCESS_HOME_PATH
  }

  // only ENT users can view balance sheets
  if (
    pathname === APP_PATH.FINANCIAL_PERFORMANCE.BALANCE_SHEET &&
    clientCustomerType !== USER_TYPE.ENT
  ) {
    return APP_PATH.HOME
  }

  // only PRO and ENT user can view profit plans
  if (
    pathname === APP_PATH.FINANCIAL_PERFORMANCE.PROFIT_PLAN &&
    ![USER_TYPE.PRO, USER_TYPE.ENT].includes(clientCustomerType)
  ) {
    return APP_PATH.HOME
  }

  // limited users can only access certain paths
  if (
    isLimited &&
    !includesPathnameInPaths(pathname, LIMITED_USER_ALLOWED_PATHS)
  ) {
    return APP_PATH.HOME
  }

  // ride share users cannot access per diem
  if (clientCustomerType === USER_TYPE.RIDE && pathname === APP_PATH.PER_DIEM) {
    return APP_PATH.HOME
  }

  // only ride share users can access mileage tracking
  if (
    clientCustomerType !== USER_TYPE.RIDE &&
    pathname.startsWith(APP_PATH.MILEAGE_TRACKER.ROOT)
  ) {
    return APP_PATH.HOME
  }

  // only ENT users can connect bank via Flinks
  if (
    clientCustomerType !== USER_TYPE.ENT &&
    pathname === APP_PATH.CONNECT_BANK
  ) {
    return APP_PATH.HOME
  }

  // only non-ride share users can access tutorials
  if (
    clientCustomerType === USER_TYPE.RIDE &&
    pathname === APP_PATH.TUTORIALS
  ) {
    return APP_PATH.HOME
  }

  return null
}

// Helpers

function includesPathnameInPaths(pathname, paths) {
  return paths.some((path) => pathname.includes(path))
}

export default useRestrictedAccessRedirectPath
