import React from 'react'
import exact from 'prop-types-exact'
import * as Types from 'types'
import { IonIcon, IonText, IonButton } from '@ionic/react'
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from 'capacitor-native-settings'
import { PERMISSIONS_SPLASH } from 'config'

const propTypes = {
  type: Types.permissionType.isRequired,
}

const defaultProps = {}

function PermissionsSplash({ type }) {
  const openAppSettings = () => {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    })
  }
  const { icon, title, body } = PERMISSIONS_SPLASH[type]

  return (
    <div className="splash-wrapper ion-padding">
      <div className="flex-wrapper">
        <div className="icon-circle-bg">
          <IonIcon icon={icon} aria-hidden={true} size="large" />
        </div>
        <h2 className="secondary">{title}</h2>
        <IonText className="ion-text-center">{body}</IonText>
      </div>
      <div className="links">
        <IonButton
          fill="clear"
          color="light"
          size="default"
          onClick={openAppSettings}
        >
          Open Settings
        </IonButton>
        <IonButton
          fill="clear"
          color="light"
          size="default"
          href="https://www.atbs.com/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Read our privacy agreement
        </IonButton>
      </div>
    </div>
  )
}

PermissionsSplash.propTypes = exact(propTypes)
PermissionsSplash.defaultProps = defaultProps

export default React.memo(PermissionsSplash)
