import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { startCase, find, camelCase } from 'lodash'
import { MILES_CHART_CATEGORY, MILES_BAR_GRAPH_CATEGORIES } from 'config'
import * as Types from 'types'
import { CustomLegend, CustomTooltip } from '../components'
import { getMonthsToDate } from 'utils'

const propTypes = {
  data: PropTypes.arrayOf(Types.profitAndLossRecord).isRequired,
  year: PropTypes.string.isRequired,
}

const defaultProps = {}

function ProfitLossMilesStackedBarGraph({ data, year }) {
  const months = getMonthsToDate(year)
  const loadedMilesData = find(
    data,
    (category) => category.categoryId === MILES_CHART_CATEGORY.LOADED_MILES.sfid
  )
  const emptyMilesData = find(
    data,
    (category) => category.categoryId === MILES_CHART_CATEGORY.EMPTY_MILES.sfid
  )
  const formattedYearToDateData = months.map((month) => ({
    name: startCase(month),
    loadedMiles: loadedMilesData?.[`${month}Amount`] ?? 0,
    emptyMiles: emptyMilesData?.[`${month}Amount`] ?? 0,
  }))

  return (
    <ResponsiveContainer width="100%" minHeight={400} minWidth={348}>
      <ComposedChart
        data={formattedYearToDateData}
        margin={{ left: 30, right: 30, bottom: 15 }}
        stackOffset="sign"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" angle={-90} textAnchor="end" dx={-5} dy={5} />
        <YAxis
          tickFormatter={(tick) => tick.toLocaleString()}
          allowDecimals={false}
        />
        <Tooltip
          content={({ active, payload }) => (
            <CustomTooltip {...{ active, payload }} />
          )}
        />
        <Legend
          iconType="circle"
          formatter={(value, entry) => <CustomLegend {...{ value, entry }} />}
          wrapperStyle={{ paddingTop: '90px' }}
          payload={MILES_BAR_GRAPH_CATEGORIES.map(
            ({ displayName: value, color }) => ({
              value,
              type: 'circle',
              id: value,
              color,
            })
          )}
        />
        {MILES_BAR_GRAPH_CATEGORIES.map(({ displayName, color }) => (
          <Bar
            key={displayName}
            dataKey={camelCase(displayName)}
            stackId="a"
            fill={color}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

ProfitLossMilesStackedBarGraph.propTypes = exact(propTypes)
ProfitLossMilesStackedBarGraph.defaultProps = defaultProps

export default React.memo(ProfitLossMilesStackedBarGraph)
