import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { ReportCriteriaForm } from '../forms'
import { IonCard, IonButton, IonIcon, IonCardContent } from '@ionic/react'
import { barChartOutline, listOutline } from 'ionicons/icons'
import { isPlatform } from '@ionic/core'
import {
  ProfitLossYTDDesktopTable,
  ProfitLossMonthlyDesktopTable,
  ProfitLossMobileTable,
  Disclaimer,
  ProfitAndLossCharts,
} from '../components'
import { first, toString, concat } from 'lodash'
import * as apiActions from 'api-actions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { LoadingSpinner, DownloadCsvButton } from 'components'
import { selectors } from '../reducer'
import { selectors as globalSelectors } from 'global-reducer'
import {
  DATA_VIEW_TYPE,
  YTD_OPTION,
  PROFIT_LOSS_SECTION,
  NET_INCOME_NAMES,
  USER_TYPE,
} from 'config'

const propTypes = {
  fetchProfitAndLoss: PropTypes.func.isRequired,
  selectedDataViewType: PropTypes.string.isRequired,
  setSelectedDataViewType: PropTypes.func.isRequired,
  financialPerformanceYears: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentUser: Types.user.isRequired,
  profitAndLossMiles: PropTypes.arrayOf(Types.profitAndLossRecord),
  profitAndLossRevenue: PropTypes.arrayOf(Types.profitAndLossRecord),
  profitAndLossExpenses: Types.profitAndLossExpenses,
  profitAndLossLiability: PropTypes.arrayOf(Types.profitAndLossRecord),
}

const defaultProps = {
  profitAndLossMiles: null,
  profitAndLossRevenue: null,
  profitAndLossExpenses: null,
  profitAndLossLiability: null,
}

function ProfitAndLoss({
  fetchProfitAndLoss,
  selectedDataViewType,
  setSelectedDataViewType,
  financialPerformanceYears,
  currentUser,
  profitAndLossMiles,
  profitAndLossRevenue,
  profitAndLossExpenses,
  profitAndLossLiability,
}) {
  const isMobile = !isPlatform('desktop')
  const latestYear = first(financialPerformanceYears)
  const [year, setYear] = useState(latestYear)
  const [period, setPeriod] = useState(YTD_OPTION.periodSelectorValue)
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const isChartDataViewType = selectedDataViewType === DATA_VIEW_TYPE.CHART
  const isTableDataViewType = selectedDataViewType === DATA_VIEW_TYPE.TABLE
  const isYearToDate = period === YTD_OPTION.periodSelectorValue

  const isRideShareUser = currentUser.clientCustomerType === USER_TYPE.RIDE

  useEffect(() => {
    requestProfitAndLossYear(latestYear)
  }, [latestYear, requestProfitAndLossYear])

  const requestProfitAndLossYear = useCallback(
    async (year) => {
      setIsLoading(true)
      setError()
      try {
        await fetchProfitAndLoss(year)
      } catch (errorMessage) {
        setError(
          `There was an issue loading profit and loss data for ${year}: ${errorMessage}`
        )
      }
      setIsLoading(false)
    },
    [fetchProfitAndLoss]
  )

  const tableData = {
    [PROFIT_LOSS_SECTION.MILES]: profitAndLossMiles,
    [PROFIT_LOSS_SECTION.REVENUE]: profitAndLossRevenue,
    [PROFIT_LOSS_SECTION.VARIABLE_EXPENSES]: profitAndLossExpenses?.variable,
    [PROFIT_LOSS_SECTION.FIXED_EXPENSES]: profitAndLossExpenses?.fixed,
    [PROFIT_LOSS_SECTION.LIABILITIES]: profitAndLossLiability,
  }

  const netIncomeData = concat(
    profitAndLossRevenue,
    profitAndLossExpenses?.fixed
  ).filter((row) => NET_INCOME_NAMES.includes(row?.name))

  return (
    <div>
      <header>
        <h2>Report Criteria</h2>
      </header>
      <div className="criteria-form-flex">
        <ReportCriteriaForm
          initialValues={{ year: toString(year), period }}
          handleSubmit={(values) => {
            setPeriod(values.period)
            if (values.year === year) return true

            setYear(values.year)
            return requestProfitAndLossYear(values.year)
          }}
          availableYearOptions={financialPerformanceYears}
          hideBeginningOption
        />
        {!isLoading && !error && (
          <div className="right-content">
            <DownloadCsvButton
              url={`/v1/profit_and_loss_statements/${year}.csv`}
              fileName={`profit-and-loss-${year}.csv`}
            />
            <div className="mode-selector ion-hide-md-down ion-justify-content-end">
              <IonButton
                onClick={() => setSelectedDataViewType(DATA_VIEW_TYPE.CHART)}
                color={isChartDataViewType && 'tertiary'}
                aria-label="Switch to charts"
                fill={isChartDataViewType ? 'solid' : 'outline'}
              >
                <IonIcon slot="icon-only" icon={barChartOutline} />
              </IonButton>
              <IonButton
                onClick={() => setSelectedDataViewType(DATA_VIEW_TYPE.TABLE)}
                color={isTableDataViewType && 'tertiary'}
                aria-label="Switch to tables"
                fill={isTableDataViewType ? 'solid' : 'outline'}
              >
                <IonIcon slot="icon-only" icon={listOutline} />
              </IonButton>
            </div>
          </div>
        )}
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : error ? (
        <IonCard>
          <IonCardContent>
            <p>{error}</p>
          </IonCardContent>
        </IonCard>
      ) : (
        <>
          {isChartDataViewType && (
            <ProfitAndLossCharts
              isRideShareUser={isRideShareUser}
              period={period}
              year={year}
              netIncomeData={netIncomeData}
              milesData={profitAndLossMiles}
              revenueData={profitAndLossRevenue}
              expensesData={profitAndLossExpenses}
              liabilitiesData={profitAndLossLiability}
            />
          )}
          {isTableDataViewType && (
            <>
              {isMobile ? (
                <ProfitLossMobileTable
                  milesData={profitAndLossMiles}
                  revenueData={profitAndLossRevenue}
                  expensesData={profitAndLossExpenses}
                  liabilitiesData={profitAndLossLiability}
                  year={year}
                  period={period}
                  hideNonAmountData={isRideShareUser}
                />
              ) : isYearToDate ? (
                <ProfitLossYTDDesktopTable tableData={tableData} year={year} />
              ) : (
                <ProfitLossMonthlyDesktopTable
                  tableData={tableData}
                  period={period}
                  year={year}
                  hideNonAmountData={isRideShareUser}
                />
              )}
            </>
          )}
          <Disclaimer />
        </>
      )}
    </div>
  )
}

ProfitAndLoss.propTypes = exact(propTypes)
ProfitAndLoss.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    profitAndLossMiles: selectors.profitAndLossMiles(state),
    profitAndLossRevenue: selectors.profitAndLossRevenue(state),
    profitAndLossExpenses: selectors.profitAndLossExpenses(state),
    financialPerformanceYears: selectors.financialPerformanceYears(state),
    profitAndLossLiability: selectors.profitAndLossLiability(state),
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {
  fetchProfitAndLoss: apiActions.fetchProfitAndLoss,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ProfitAndLoss
)
