import { handleActions } from 'redux-actions'
import { selectorForSlice } from 'lp-redux-utils'
import { setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import { createSelector } from 'reselect'
import { toNumber } from 'lodash'

const reducerKey = 'home'
const slice = 'root.home'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchLatestDocs]: setOnSuccess('rawDocuments'),
    [apiActions.fetchHomeProfitAndLoss]: setOnSuccess('profitAndLoss'),
    [apiActions.fetchHomePerDiem]: setOnSuccess('perDiem'),
    [apiActions.fetchHomeMileageSummary]: setOnSuccess('savings', (action) =>
      toNumber(action.payload.data.totalSavings)
    ),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  rawDocuments: select('rawDocuments'),
  profitAndLoss: select('profitAndLoss'),
  perDiem: select('perDiem'),
  savings: select('savings'),
}

// Computed

selectors.latestDocs = createSelector(
  [selectors.rawDocuments],
  (rawDocuments) => {
    if (!rawDocuments) return

    return rawDocuments.map(({ attributes }) => attributes)
  }
)

export { reducer, selectors, reducerKey }
