import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  PROFIT_PLAN_COLUMN,
  PROFIT_PLAN_BASE_COLUMN_SETTINGS,
  PROFIT_PLAN_EXTENDED_COLUMN_SETTINGS,
  BUSINESS_WHAT_IF_COLUMN_SETTINGS,
  PERSONAL_WHAT_IF_COLUMN_SETTINGS,
  PROFIT_PLAN_SECTION,
} from 'config'
import { IonAccordion, IonItem, IonLabel } from '@ionic/react'
import { MaterialTable } from 'components'
import { getTableBodyRowProps } from 'utils'

const propTypes = {
  isCurrency: PropTypes.bool,
  data: PropTypes.arrayOf(Types.fullProfitPlanItem).isRequired,
  name: PropTypes.string.isRequired,
  doNotApplyRowTotalsStyle: PropTypes.bool,
}

const defaultProps = {
  isCurrency: false,
  doNotApplyRowTotalsStyle: false,
}

const buildColumns = (name, isCurrency) => {
  if (name === PROFIT_PLAN_SECTION.BUSINESS_WHAT_IF)
    return BUSINESS_WHAT_IF_COLUMN_SETTINGS
  if (name === PROFIT_PLAN_SECTION.PERSONAL_WHAT_IF)
    return PERSONAL_WHAT_IF_COLUMN_SETTINGS

  const baseColumnSettings = PROFIT_PLAN_BASE_COLUMN_SETTINGS(isCurrency)
  if (name === PROFIT_PLAN_SECTION.MILES) return baseColumnSettings
  return [...baseColumnSettings, ...PROFIT_PLAN_EXTENDED_COLUMN_SETTINGS]
}

function ProfitPlanTableDesktopSection({
  data,
  name,
  isCurrency,
  doNotApplyRowTotalsStyle,
}) {
  const columns = buildColumns(name, isCurrency)

  return (
    <div className="section-wrapper">
      <IonAccordion value={name}>
        <IonItem slot="header" color="light">
          <IonLabel className="profit-plan-accordion-section-header-label">
            {name}
          </IonLabel>
        </IonItem>
        <div className="accordion-back" slot="content">
          <MaterialTable
            columns={columns}
            data={data}
            initialState={{
              columnPinning: { left: [PROFIT_PLAN_COLUMN.NAME.value] },
              expanded: true,
            }}
            defaultColumn={{
              minSize: 25,
              maxSize: 9001,
              size: 100,
            }}
            muiTableBodyRowProps={({ row }) => {
              if (doNotApplyRowTotalsStyle) return

              return getTableBodyRowProps({ row })
            }}
          />
        </div>
      </IonAccordion>
    </div>
  )
}

ProfitPlanTableDesktopSection.propTypes = exact(propTypes)
ProfitPlanTableDesktopSection.defaultProps = defaultProps

export default React.memo(ProfitPlanTableDesktopSection)
