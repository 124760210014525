import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { DOCUMENT_STATUS } from 'config'

const propTypes = {
  status: PropTypes.string.isRequired,
}

const defaultProps = {}

function DocumentStatusBadge({ status }) {
  return (
    <div className="status-badge">{DOCUMENT_STATUS[status.toUpperCase()]}</div>
  )
}

DocumentStatusBadge.propTypes = exact(propTypes)
DocumentStatusBadge.defaultProps = defaultProps

export default React.memo(DocumentStatusBadge)
