import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { IonList } from '@ionic/react'
import { SignInHeader } from '../components'
import ResetPasswordForm from '../forms/ResetPasswordForm'

const propTypes = {}

const defaultProps = {}

function ResetPassword() {
  return (
    <IonList>
      <SignInHeader title="Reset Password">
        <div className="sign-in-header-details">
          <p className="password-requirement-list">
            Your new password must include 8 or more characters, a mix of
            uppercase and lowercase letters, numbers, and symbols.
          </p>
        </div>
      </SignInHeader>
      <ResetPasswordForm />
    </IonList>
  )
}

ResetPassword.propTypes = exact(propTypes)
ResetPassword.defaultProps = defaultProps

export default React.memo(ResetPassword)
