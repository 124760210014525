import React from 'react'
import PropTypes from 'prop-types'
import { NavigationMenu } from 'components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from './reducer'
import * as apiActions from 'api-actions'
import * as actions from './actions'
import * as Types from 'types'

const propTypes = {
  children: PropTypes.node.isRequired,
  currentUser: Types.user.isRequired,
  logOut: PropTypes.func.isRequired,
  setIsUploadModalOpen: PropTypes.func.isRequired,
}
const defaultProps = {}

function NavMenuLayout({
  children,
  currentUser,
  logOut,
  setIsUploadModalOpen,
}) {
  return (
    <>
      <NavigationMenu
        logOut={logOut}
        currentUser={currentUser}
        openUploadModal={() => setIsUploadModalOpen(true)}
      />
      {children}
    </>
  )
}

NavMenuLayout.propTypes = propTypes
NavMenuLayout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: selectors.currentUser(state),
  }
}

const mapDispatchToProps = {
  setIsUploadModalOpen: actions.setIsUploadModalOpen,
  logOut: apiActions.logOut,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NavMenuLayout
)
