import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { LoadingSpinner, ConfirmationAlert } from 'components'
import { IonCard, IonButton, IonIcon, useIonRouter } from '@ionic/react'
import { useToast } from 'hooks'
import { APP_PATH } from 'config'
import { useParams } from 'react-router-dom'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { trash } from 'ionicons/icons'
import { TripForm } from '../forms'
import { formatISOInTimeZone } from 'utils'
import { toString } from 'lodash'

const propTypes = {
  fetchTrip: PropTypes.func.isRequired,
  updateTrip: PropTypes.func.isRequired,
  clearTrip: PropTypes.func.isRequired,
  deleteTrip: PropTypes.func.isRequired,
  trip: Types.trip,
}

const defaultProps = {
  trip: null,
}

function EditTrip({ fetchTrip, updateTrip, trip, clearTrip, deleteTrip }) {
  const { showFailureToast, showSuccessToast } = useToast()
  const router = useIonRouter()
  const { tripId } = useParams()
  const [error, setError] = useState(null)
  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false)

  useEffect(() => {
    async function requestTrip() {
      try {
        await fetchTrip(tripId)
      } catch (errorMessage) {
        setError(`There was an issue fetching the trip: ${errorMessage}`)
      }
    }

    requestTrip(tripId)
  }, [fetchTrip, tripId])

  useEffect(() => {
    return () => clearTrip()
  }, [clearTrip])

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true)
      await updateTrip(
        {
          startTime: values.from,
          startTimeZone: trip.startTimeZone,
          endTime: values.to,
          endTimeZone: trip.endTimeZone,
          miles: values.miles,
          note: values.note,
        },
        tripId
      )
      showSuccessToast({
        message: 'Updated successfully!',
        duration: 3000,
      })
      router.push(APP_PATH.MILEAGE_TRACKER.ALL_TRIPS, 'back', 'pop')
    } catch (e) {
      showFailureToast({
        message: 'There was an issue updating your trip. Please try again.',
        callback: () => handleSubmit(values, { setSubmitting }),
      })
    } finally {
      setSubmitting(false)
    }
  }

  const handleDelete = async () => {
    try {
      await deleteTrip(trip.id)
      showSuccessToast({
        message: 'Deleted successfully!',
        duration: 3000,
      })
      router.push(APP_PATH.MILEAGE_TRACKER.ALL_TRIPS, 'back', 'pop')
    } catch (e) {
      showFailureToast({
        message: 'There was an issue deleting your trip. Please try again.',
        callback: () => handleDelete(),
      })
    }
  }

  if (error) return <div className="centered-content">{error}</div>
  if (!trip) return <LoadingSpinner />

  return (
    <div className="add-trip-wrapper">
      <IonCard className="ion-padding-small">
        <TripForm
          initialValues={{
            from: formatISOInTimeZone(trip.startTime, trip.startTimeZone),
            to: formatISOInTimeZone(trip.endTime, trip.endTimeZone),
            miles: toString(trip.miles), // Convert to string for the input
            note: trip.note || '',
          }}
          handleSubmit={handleSubmit}
          startTimeZone={trip.startTimeZone}
          endTimeZone={trip.endTimeZone}
          submitButtonText="Update"
        >
          <div className="delete-button-wrapper">
            <IonButton
              onClick={() => {
                setShowConfirmationAlert(true)
              }}
              fill="clear"
            >
              <IonIcon icon={trash} aria-hidden={true} />
              Delete Trip
            </IonButton>
          </div>
        </TripForm>
      </IonCard>
      <ConfirmationAlert
        isOpen={showConfirmationAlert}
        onClose={() => setShowConfirmationAlert(false)}
        title="Are you sure you want to delete this trip?"
        message="Any changes won't be saved!"
        cancelText="Continue Editing"
        confirmText="Yes, Delete Trip"
        onConfirm={() => {
          setShowConfirmationAlert(false)
          handleDelete()
        }}
      />
    </div>
  )
}

EditTrip.propTypes = exact(propTypes)
EditTrip.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    trip: selectors.trip(state),
  }
}

const mapDispatchToProps = {
  fetchTrip: apiActions.fetchTrip,
  updateTrip: apiActions.updateTrip,
  clearTrip: actions.clearTrip,
  deleteTrip: apiActions.deleteTrip,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditTrip)
