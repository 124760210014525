import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { IonCard, IonList, IonItem, IonLabel } from '@ionic/react'
import { Link } from 'react-router-dom'

const propTypes = {
  parentPathname: PropTypes.string.isRequired,
  transaction: Types.transaction.isRequired,
}

const defaultProps = {}

function TransactionCard({ parentPathname, transaction }) {
  return (
    <IonCard className="list-style-table-card">
      <IonList>
        <IonItem>
          <IonLabel>Date</IonLabel>
          <p>{transaction.date}</p>
        </IonItem>
        <IonItem>
          <IonLabel>Source</IonLabel>
          <p>{transaction.source}</p>
        </IonItem>
        <IonItem>
          <IonLabel>Details</IonLabel>
          <Link to={`${parentPathname}/batch/${transaction.batchId}`}>
            View Document Transactions
          </Link>
        </IonItem>
        <IonItem>
          <IonLabel>Amount</IonLabel>
          <p>{transaction.formattedAmount}</p>
        </IonItem>
      </IonList>
    </IonCard>
  )
}

TransactionCard.propTypes = exact(propTypes)
TransactionCard.defaultProps = defaultProps

export default React.memo(TransactionCard)
