import React from 'react'
import PropTypes from 'prop-types'
// import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Form, Formik } from 'formik'
import { SubmitButton, TextInput } from 'components'
import * as Yup from 'yup'
import YupPassword from 'yup-password'
import * as apiActions from 'api-actions'
import { useLocation, useHistory } from 'react-router-dom'
import { APP_PATH } from 'config'
import { useToast } from 'hooks'

YupPassword(Yup)

// import { selectors } from '../reducer'
// import * as actions from '../actions'
// import * as apiActions from 'api-actions'

const propTypes = {
  updatePassword: PropTypes.func.isRequired,
}
const defaultProps = {}

const validationSchema = Yup.object({
  password: Yup.string()
    .password()
    .minLowercase(1)
    .required('Password is required'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password confirmation is required'),
})

function ResetPasswordForm({ updatePassword }) {
  const history = useHistory()
  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)
  const { showSuccessToast, showFailureToast } = useToast()
  return (
    <Formik
      initialValues={{
        password: '',
        passwordConfirmation: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        try {
          await updatePassword({
            reset_password_token: queryParameters.get('token'),
            password: values.password,
            password_confirmation: values.passwordConfirmation,
          })
          showSuccessToast({
            message: 'Password reset! Please log in with your new credentials.',
          })
          history.push(APP_PATH.SIGN_IN)
        } catch (errorMessage) {
          const knownError =
            /^(please try again)|^(something went wrong)/i.test(errorMessage)

          showFailureToast({
            message: knownError
              ? errorMessage
              : 'Your reset password link is invalid or has been used already. Please submit a new Forgot Password request.',
          })
        }
      }}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form className="sign-in-form ion-padding" noValidate>
          <TextInput
            name="password"
            type="password"
            inputmode="text"
            autocomplete="new-password"
          />
          <TextInput
            name="passwordConfirmation"
            type="password"
            inputmode="text"
            autocomplete="new-password"
          />
          <SubmitButton color="secondary" submitting={isSubmitting}>
            Submit
          </SubmitButton>
        </Form>
      )}
    </Formik>
  )
}

ResetPasswordForm.propTypes = propTypes
ResetPasswordForm.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  updatePassword: apiActions.updatePassword,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ResetPasswordForm
)
