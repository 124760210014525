import PropTypes from 'prop-types'

export const inputOption = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  }),
])

export const photo = PropTypes.shape({
  webPath: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  mimeType: PropTypes.string,
  saved: PropTypes.bool.isRequired,
  fileName: PropTypes.string.isRequired,
})

export const file = PropTypes.shape({
  id: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  documentGuid: PropTypes.string.isRequired,
  fileNumber: PropTypes.number.isRequired,
  comment: PropTypes.string,
})

export const fileForUpload = PropTypes.shape({
  fileName: PropTypes.string.isRequired,
  blob: PropTypes.object.isRequired,
  note: PropTypes.string,
})

export const materialTableColumn = PropTypes.shape({
  header: PropTypes.string,
  accessorKey: PropTypes.string,
  Cell: PropTypes.func,
  maxSize: PropTypes.number,
  headerClassName: PropTypes.string,
})

export const cell = PropTypes.shape({
  id: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  column: materialTableColumn.isRequired,
  renderValue: PropTypes.func.isRequired,
  getContext: PropTypes.func.isRequired,
  getIsAggregated: PropTypes.func.isRequired,
  getIsGrouped: PropTypes.func.isRequired,
  getIsPlaceholder: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
})

export const doc = PropTypes.shape({
  createdAt: PropTypes.string.isRequired,
  guid: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(file),
})

export const user = PropTypes.shape({
  clientCustomerType: PropTypes.string,
  constituentId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  isLimited: PropTypes.bool.isRequired,
  fullName: PropTypes.string.isRequired,
})

export const taxEstimateOption = PropTypes.shape({
  id: PropTypes.string.isRequired,
  attributes: PropTypes.shape({
    quarter: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
  }),
})

export const consultant = PropTypes.shape({
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
})

export const profitPlanLineItem = PropTypes.shape({
  name: PropTypes.string.isRequired,
  weekly: PropTypes.number.isRequired,
  monthly: PropTypes.number.isRequired,
  annual: PropTypes.number.isRequired,
  centsPerMile: PropTypes.number,
  revenuePercent: PropTypes.number,
})

export const businessWhatIfItem = PropTypes.shape({
  name: PropTypes.string,
  annualChange: PropTypes.number.isRequired,
  weeklyChange: PropTypes.number.isRequired,
  weeklyBusVariable: PropTypes.number.isRequired,
  weeklyBusRevenue: PropTypes.number.isRequired,
  weeklyBusNetIncome: PropTypes.number.isRequired,
  whatIfAmount: PropTypes.number,
})

export const personalWhatIfItem = PropTypes.shape({
  name: PropTypes.string,
  annualBreakeven: PropTypes.number,
  annualImprovement: PropTypes.number.isRequired,
  goalBreakeven: PropTypes.number,
  planBreakeven: PropTypes.number,
  weeklyChange: PropTypes.number,
  whatIfAmount: PropTypes.number,
})

export const fullProfitPlanItem = PropTypes.oneOfType([
  profitPlanLineItem,
  businessWhatIfItem,
  personalWhatIfItem,
])

export const profitPlan = PropTypes.shape({
  name: PropTypes.string.isRequired,
  breakeven: PropTypes.arrayOf(profitPlanLineItem).isRequired,
  estimatedCash: PropTypes.arrayOf(profitPlanLineItem).isRequired,
  federalTaxes: PropTypes.arrayOf(profitPlanLineItem).isRequired,
  fixed: PropTypes.arrayOf(profitPlanLineItem).isRequired,
  miles: PropTypes.arrayOf(profitPlanLineItem).isRequired,
  perDiem: PropTypes.arrayOf(profitPlanLineItem).isRequired,
  personalObligations: PropTypes.arrayOf(profitPlanLineItem).isRequired,
  revenue: PropTypes.arrayOf(profitPlanLineItem).isRequired,
  stateTaxes: PropTypes.arrayOf(profitPlanLineItem).isRequired,
  variable: PropTypes.arrayOf(profitPlanLineItem).isRequired,
  whatIf: PropTypes.shape({
    businessScenario: PropTypes.shape({
      currentProfitPlan: businessWhatIfItem,
      milesPerGallonIncrease: businessWhatIfItem,
      milesPerWeekIncrease: businessWhatIfItem,
    }),
    personalScenario: PropTypes.shape({
      personalExpensesReductionPerMonth: personalWhatIfItem,
      milesToBreakeven: personalWhatIfItem,
      revenueToBreakeven: personalWhatIfItem,
    }),
  }).isRequired,
})

export const highlightedDateConfig = PropTypes.shape({
  date: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
})

export const perDiemEntry = PropTypes.shape({
  id: PropTypes.number.isRequired,
  accountId: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  lastUpdated: PropTypes.string,
  effectiveDate: PropTypes.string.isRequired,
  ownerOperatorFullCount: PropTypes.number.isRequired,
  ownerOperatorPartialCount: PropTypes.number.isRequired,
  spouseDriverFullCount: PropTypes.number.isRequired,
  spouseDriverPartialCount: PropTypes.number.isRequired,
  spouseRiderFullCount: PropTypes.number.isRequired,
  spouseRiderPartialCount: PropTypes.number.isRequired,
})

export const perDiem = PropTypes.shape({
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  yearToDateFullDays: PropTypes.number.isRequired,
  yearToDatePartialDays: PropTypes.number.isRequired,
  yearToDateDeductions: PropTypes.number.isRequired,
  perDiemEntries: PropTypes.arrayOf(perDiemEntry).isRequired,
})

export const profitAndLossRow = PropTypes.shape({
  name: PropTypes.string.isRequired,
  januaryAmount: PropTypes.number.isRequired,
  januaryBenchmarkStatus: PropTypes.bool,
  januaryYtdAmount: PropTypes.number,
  januaryAmountPercentOfTotal: PropTypes.number,
  januaryYtdAmountPercentOfTotal: PropTypes.number,
  januaryAmountCentsPerMile: PropTypes.number,
  januaryYtdAmountCentsPerMile: PropTypes.number,
  januaryAmountRevenuePercent: PropTypes.number,
  januaryYtdAmountRevenuePercent: PropTypes.number,
  februaryAmount: PropTypes.number.isRequired,
  februaryBenchmarkStatus: PropTypes.bool,
  februaryYtdAmount: PropTypes.number,
  februaryAmountPercentOfTotal: PropTypes.number,
  februaryYtdAmountPercentOfTotal: PropTypes.number,
  februaryAmountCentsPerMile: PropTypes.number,
  februaryYtdAmountCentsPerMile: PropTypes.number,
  februaryAmountRevenuePercent: PropTypes.number,
  februarYtdyAmountRevenuePercent: PropTypes.number,
  marchAmount: PropTypes.number.isRequired,
  marchBenchmarkStatus: PropTypes.bool,
  marchYtdAmount: PropTypes.number,
  marchAmountPercentOfTotal: PropTypes.number,
  marchYtdAmountPercentOfTotal: PropTypes.number,
  marchAmountCentsPerMile: PropTypes.number,
  marchYtdAmountCentsPerMile: PropTypes.number,
  marchAmountRevenuePercent: PropTypes.number,
  marchAmYtdountRevenuePercent: PropTypes.number,
  aprilAmount: PropTypes.number.isRequired,
  aprilBenchmarkStatus: PropTypes.bool,
  aprilYtdAmount: PropTypes.number,
  aprilAmountPercentOfTotal: PropTypes.number,
  aprilYtdAmountPercentOfTotal: PropTypes.number,
  aprilAmountCentsPerMile: PropTypes.number,
  aprilYtdAmountCentsPerMile: PropTypes.number,
  aprilAmountRevenuePercent: PropTypes.number,
  aprilAmYtdountRevenuePercent: PropTypes.number,
  mayAmount: PropTypes.number.isRequired,
  mayBenchmarkStatus: PropTypes.bool,
  mayYtdAmount: PropTypes.number,
  mayAmountPercentOfTotal: PropTypes.number,
  mayYtdAmountPercentOfTotal: PropTypes.number,
  mayAmountCentsPerMile: PropTypes.number,
  mayYtdAmountCentsPerMile: PropTypes.number,
  mayAmountRevenuePercent: PropTypes.number,
  mayAmouYtdntRevenuePercent: PropTypes.number,
  juneAmount: PropTypes.number.isRequired,
  juneBenchmarkStatus: PropTypes.bool,
  juneYtdAmount: PropTypes.number,
  juneAmountPercentOfTotal: PropTypes.number,
  juneYtdAmountPercentOfTotal: PropTypes.number,
  juneAmountCentsPerMile: PropTypes.number,
  juneYtdAmountCentsPerMile: PropTypes.number,
  juneAmountRevenuePercent: PropTypes.number,
  juneAmoYtduntRevenuePercent: PropTypes.number,
  julyAmount: PropTypes.number.isRequired,
  julyBenchmarkStatus: PropTypes.bool,
  julyYtdAmount: PropTypes.number,
  julyAmountPercentOfTotal: PropTypes.number,
  julyYtdAmountPercentOfTotal: PropTypes.number,
  julyAmountCentsPerMile: PropTypes.number,
  julyYtdAmountCentsPerMile: PropTypes.number,
  julyAmountRevenuePercent: PropTypes.number,
  julyAmoYtduntRevenuePercent: PropTypes.number,
  augustAmount: PropTypes.number.isRequired,
  augustBenchmarkStatus: PropTypes.bool,
  augustYtdAmount: PropTypes.number,
  augustAmountPercentOfTotal: PropTypes.number,
  augustYtdAmountPercentOfTotal: PropTypes.number,
  augustAmountCentsPerMile: PropTypes.number,
  augustYtdAmountCentsPerMile: PropTypes.number,
  augustAmountRevenuePercent: PropTypes.number,
  augustAYtdmountRevenuePercent: PropTypes.number,
  septemberAmount: PropTypes.number.isRequired,
  septemberBenchmarkStatus: PropTypes.bool,
  septemberYtdAmount: PropTypes.number,
  septemberAmountPercentOfTotal: PropTypes.number,
  septemberYtdAmountPercentOfTotal: PropTypes.number,
  septemberAmountCentsPerMile: PropTypes.number,
  septemberYtdAmountCentsPerMile: PropTypes.number,
  septemberAmountRevenuePercent: PropTypes.number,
  septembYtderAmountRevenuePercent: PropTypes.number,
  octoberAmount: PropTypes.number.isRequired,
  octoberBenchmarkStatus: PropTypes.bool,
  octoberYtdAmount: PropTypes.number,
  octoberAmountPercentOfTotal: PropTypes.number,
  octoberYtdAmountPercentOfTotal: PropTypes.number,
  octoberAmountCentsPerMile: PropTypes.number,
  octoberYtdAmountCentsPerMile: PropTypes.number,
  octoberAmountRevenuePercent: PropTypes.number,
  octoberYtdAmountRevenuePercent: PropTypes.number,
  novemberAmount: PropTypes.number.isRequired,
  novemberBenchmarkStatus: PropTypes.bool,
  novemberYtdAmount: PropTypes.number,
  novemberAmountPercentOfTotal: PropTypes.number,
  novemberYtdAmountPercentOfTotal: PropTypes.number,
  novemberAmountCentsPerMile: PropTypes.number,
  novemberYtdAmountCentsPerMile: PropTypes.number,
  novemberAmountRevenuePercent: PropTypes.number,
  novembeYtdrAmountRevenuePercent: PropTypes.number,
  decemberAmount: PropTypes.number.isRequired,
  decemberBenchmarkStatus: PropTypes.bool,
  decemberYtdAmount: PropTypes.number,
  decemberAmountPercentOfTotal: PropTypes.number,
  decemberYtdAmountPercentOfTotal: PropTypes.number,
  decemberAmountCentsPerMile: PropTypes.number,
  decemberYtdAmountCentsPerMile: PropTypes.number,
  decemberAmountRevenuePercent: PropTypes.number,
  decembeYtdrAmountRevenuePercent: PropTypes.number,
  yearToDate: PropTypes.number.isRequired,
  yearToDatePercentOfTotal: PropTypes.number,
  yearToDateCentsPerMile: PropTypes.number,
  yearToDateRevenuePercent: PropTypes.number,
  categoryId: PropTypes.string,
  internalAccountNumber: PropTypes.number,
})

export const profitAndLossRecord = PropTypes.shape({
  children: PropTypes.arrayOf(profitAndLossRow),
  profitAndLossRow,
})

export const profitAndLossExpenses = PropTypes.shape({
  fixed: PropTypes.arrayOf(profitAndLossRecord).isRequired,
  variable: PropTypes.arrayOf(profitAndLossRecord).isRequired,
})

export const profitAndLoss = PropTypes.shape({
  miles: PropTypes.arrayOf(profitAndLossRecord).isRequired,
  revenue: PropTypes.arrayOf(profitAndLossRecord).isRequired,
  liability: PropTypes.arrayOf(profitAndLossRecord).isRequired,
  profitAndLossExpenses,
})

export const balanceSheetChartPeriod = PropTypes.shape({
  displayName: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  totalAssets: PropTypes.number.isRequired,
  totalLiabilities: PropTypes.number.isRequired,
})

export const balanceSheetRow = PropTypes.shape({
  name: PropTypes.string.isRequired,
  beginningAmount: PropTypes.number.isRequired,
  januaryAmount: PropTypes.number.isRequired,
  februaryAmount: PropTypes.number.isRequired,
  marchAmount: PropTypes.number.isRequired,
  aprilAmount: PropTypes.number.isRequired,
  mayAmount: PropTypes.number.isRequired,
  juneAmount: PropTypes.number.isRequired,
  julyAmount: PropTypes.number.isRequired,
  augustAmount: PropTypes.number.isRequired,
  septemberAmount: PropTypes.number.isRequired,
  octoberAmount: PropTypes.number.isRequired,
  novemberAmount: PropTypes.number.isRequired,
  decemberAmount: PropTypes.number.isRequired,
  internalAccountNumber: PropTypes.number,
})

export const balanceSheetRecord = PropTypes.shape({
  children: PropTypes.arrayOf(balanceSheetRow),
  balanceSheetRow,
})

export const balanceSheet = PropTypes.shape({
  assets: PropTypes.arrayOf(balanceSheetRecord).isRequired,
  liability: PropTypes.arrayOf(balanceSheetRecord).isRequired,
  ownersEquity: PropTypes.arrayOf(balanceSheetRecord).isRequired,
})

export const yearPeriodFormValues = PropTypes.shape({
  year: PropTypes.string,
  period: PropTypes.string,
})

export const tableCell = PropTypes.shape({
  renderValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
})

export const tableRow = PropTypes.shape({
  parentId: PropTypes.string,
  originalSubRows: PropTypes.arrayOf(PropTypes.object),
  getIsExpanded: PropTypes.func.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
})

export const transaction = PropTypes.shape({
  id: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  formattedAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  batchId: PropTypes.number.isRequired,
})

export const cellFormatter = PropTypes.shape({
  style: PropTypes.string,
  currency: PropTypes.string,
  minimumFractionDigits: PropTypes.number,
  maximumFractionDigits: PropTypes.number,
})

export const tooltipPayload = PropTypes.shape({
  name: PropTypes.string.isRequired,
  stroke: PropTypes.string,
  fill: PropTypes.string,
  value: PropTypes.number.isRequired,
})

export const legendEntry = PropTypes.shape({
  color: PropTypes.string.isRequired,
})

export const tableData = PropTypes.shape({
  Miles: PropTypes.arrayOf(profitAndLossRecord),
  Revenue: PropTypes.arrayOf(profitAndLossRecord),
  'Variable Expenses': PropTypes.arrayOf(profitAndLossRecord),
  'Fixed Expenses': PropTypes.arrayOf(profitAndLossRecord),
})

export const batchTransaction = PropTypes.shape({
  amount: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
})

export const batchTransactions = PropTypes.shape({
  expense: PropTypes.arrayOf(batchTransaction),
  income: PropTypes.arrayOf(batchTransaction),
  liability: PropTypes.arrayOf(batchTransaction),
  asset: PropTypes.arrayOf(batchTransaction),
  equity: PropTypes.arrayOf(batchTransaction),
})

export const transactionsBatch = PropTypes.shape({
  batchId: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  documentGuid: PropTypes.string.isRequired,
  transactions: batchTransactions.isRequired,
})

export const permissionType = PropTypes.oneOf([
  'notification',
  'location',
  'camera',
])

export const routerDirection = PropTypes.oneOf(['back', 'forward', 'root'])

export const trip = PropTypes.shape({
  id: PropTypes.number,
  miles: PropTypes.number.isRequired,
  savings: PropTypes.number,
  startTimeZone: PropTypes.string.isRequired,
  endTimeZone: PropTypes.string.isRequired,
  startTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
    .isRequired,
  endTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
    .isRequired,
})

export const menuItem = PropTypes.shape({
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  path: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.string,
  injectUserData: PropTypes.bool,
})

export const mileageSummary = PropTypes.shape({
  totalMiles: PropTypes.number.isRequired,
  totalSavings: PropTypes.number.isRequired,
})
