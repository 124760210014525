import { useEffect } from 'react'
/**
 * @name useSynchronizedScroll
 * @description Synchronizes scroll for all elements selected
 * @param {String} selector - the selector to locate the element
 * @param {Function} [iteratee] - function to run on each selected element to refine the selection. Should be memoized using useCallback or defined outside of a render statement.
 */
function useSynchronizedScroll(selector, iteratee) {
  useEffect(() => {
    let scrollingElements = Array.from(document.querySelectorAll(selector))
    if (iteratee) {
      scrollingElements = scrollingElements.map(iteratee)
    }

    let lastKnownScrollPosition = 0
    let ticking = false

    function synchronizeScroll(e) {
      lastKnownScrollPosition = e.target.scrollLeft

      if (!ticking) {
        window.requestAnimationFrame(() => {
          scrollingElements.forEach((element) => {
            if (e.target === element) return

            element.scrollLeft = lastKnownScrollPosition
          })
          ticking = false
        })
      }

      ticking = true
    }

    scrollingElements.forEach((element) => {
      element.addEventListener('scroll', synchronizeScroll)
    })

    return () => {
      scrollingElements.forEach((element) => {
        element.removeEventListener('scroll', synchronizeScroll)
      })
    }
  }, [selector, iteratee])
}

export default useSynchronizedScroll
