import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
} from '@ionic/react'
import { APP_PATH } from 'config'
import { SplashScreen } from '@capacitor/splash-screen'

const propTypes = {
  children: PropTypes.node.isRequired,
  hideBackButton: PropTypes.bool,
}

const defaultProps = {
  hideBackButton: false,
}

function UnauthenticatedLayout({ children, hideBackButton }) {
  useEffect(() => {
    async function hideSplashScreen() {
      await SplashScreen.hide()
    }

    hideSplashScreen()
  }, [])

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        {!hideBackButton && (
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton text="Back" defaultHref={APP_PATH.SIGN_IN} />
            </IonButtons>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent className="sign-in-layout">{children}</IonContent>
    </IonPage>
  )
}

UnauthenticatedLayout.propTypes = exact(propTypes)
UnauthenticatedLayout.defaultProps = defaultProps

export default React.memo(UnauthenticatedLayout)
