import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { IonRouterLink } from '@ionic/react'
import { APP_PATH, OPEN_MILEAGE_TRACKER_INFO_MODAL } from 'config'
import { isNativeApp } from 'utils'
import {
  CurrentTrackingCard,
  NewTripTrackingCard,
  TrackingUnavailable,
} from '../components'
import { InfoModal } from 'components'

const propTypes = {
  fetchCurrentDeductionRate: PropTypes.func.isRequired,
  currentDeductionRate: PropTypes.number,
}
const defaultProps = {
  currentDeductionRate: null,
}

function TripTracker({ currentDeductionRate, fetchCurrentDeductionRate }) {
  return (
    <>
      <NewTripTrackingCard />
      {isNativeApp() ? (
        <CurrentTrackingCard
          currentDeductionRate={currentDeductionRate}
          fetchCurrentDeductionRate={fetchCurrentDeductionRate}
        />
      ) : (
        <TrackingUnavailable />
      )}
      <IonRouterLink
        className="minimal"
        routerLink={APP_PATH.MILEAGE_TRACKER.ADD_TRIP}
      >
        Add Manually
      </IonRouterLink>
      <InfoModal
        trigger={OPEN_MILEAGE_TRACKER_INFO_MODAL}
        title="Notes & Disclosures"
        className="mileage-tracker-info-modal"
      >
        <p>
          Standard Mileage is a valuable tax deduction for gig drivers. This
          tool is designed to help you keep track of your miles driven to
          maximize your annual standard mileage tax deduction. This is not an
          official tax document.
        </p>
      </InfoModal>
    </>
  )
}

TripTracker.propTypes = exact(propTypes)
TripTracker.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentDeductionRate: selectors.currentDeductionRate(state),
  }
}

const mapDispatchToProps = {
  fetchCurrentDeductionRate: apiActions.fetchCurrentMileageDeductionRate,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TripTracker
)
