import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { MaterialReactTable } from 'material-react-table'

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(Types.materialTableColumn).isRequired,
}

const defaultProps = {}

const isHeaderColumn = (col) => ['name', 'description'].includes(col.id)

function MaterialTable({ data, columns, ...rest }) {
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableSorting={false}
      enableBottomToolbar={false}
      enableTopToolbar={false}
      enableGlobalFilter={false}
      muiExpandButtonProps={{ sx: { display: 'none' } }}
      muiTableProps={{ sx: { border: 'none' } }}
      muiTablePaperProps={{ sx: { boxShadow: 'none' } }}
      muiTableHeadCellProps={({ column }) => {
        return {
          align: isHeaderColumn(column) ? 'left' : 'right',
          sx: { border: 'none' },
        }
      }}
      muiTableBodyCellProps={({ column }) => {
        return { align: isHeaderColumn(column) ? 'left' : 'right' }
      }}
      {...rest}
    />
  )
}

MaterialTable.propTypes = propTypes
MaterialTable.defaultProps = defaultProps

export default React.memo(MaterialTable)
