import { handleActions } from 'redux-actions'
import { selectorForSlice } from 'lp-redux-utils'
import { setOnSuccess } from 'lp-redux-api'
// import * as actions from './actions'
import * as apiActions from 'api-actions'

const reducerKey = 'tax'
const slice = 'root.tax'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.getTaxEstimateOptions]: setOnSuccess('taxEstimateOptions'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  taxEstimateOptions: select('taxEstimateOptions'),
}

export { reducer, selectors, reducerKey }
