import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Box } from '@mui/material'
import { CELL_FORMATTING } from 'config'
import { isNil } from 'lodash'
import { Link } from 'react-router-dom'
import { checkmarkCircle, alertCircle } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'

const propTypes = {
  rawValue: PropTypes.number,
  isCurrency: PropTypes.bool,
  isPercentage: PropTypes.bool,
  isUnits: PropTypes.bool,
  linkPath: PropTypes.string,
  hasPositiveBenchmark: PropTypes.bool,
}

const defaultProps = {
  rawValue: null,
  isCurrency: false,
  isPercentage: false,
  isUnits: false,
  linkPath: null,
  hasPositiveBenchmark: null,
}

function CellFormatter({
  rawValue,
  isCurrency,
  isPercentage,
  isUnits,
  linkPath,
  hasPositiveBenchmark,
}) {
  if (isNil(rawValue)) {
    return <Box></Box>
  }

  const getFormatting = () => {
    if (isPercentage) return CELL_FORMATTING.PERCENTAGE
    if (isUnits) return CELL_FORMATTING.UNITS
    if (isCurrency) return CELL_FORMATTING.CURRENCY
    return {}
  }

  const formattedValue = rawValue.toLocaleString('en-US', getFormatting())

  return (
    <Box component="span">
      {!isNil(hasPositiveBenchmark) && (
        <IonIcon
          className="benchmark-icon"
          icon={hasPositiveBenchmark ? checkmarkCircle : alertCircle}
          color={hasPositiveBenchmark ? 'success' : 'danger'}
          aria-label={
            hasPositiveBenchmark
              ? 'Amount is good relative to profit plan benchmark'
              : 'Amount is not good relative to profit plan benchmark'
          }
        />
      )}
      {linkPath ? <Link to={linkPath}>{formattedValue}</Link> : formattedValue}
    </Box>
  )
}

CellFormatter.propTypes = exact(propTypes)
CellFormatter.defaultProps = defaultProps

export default React.memo(CellFormatter)
