import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { IonSpinner } from '@ionic/react'
import { TaxReturnForm } from '../forms'
import { isEmpty } from 'lodash'
import { Browser } from '@capacitor/browser'
import { HelpFooter } from '../components'
import * as apiActions from 'api-actions'

const propTypes = {
  fetchTaxReturns: PropTypes.func.isRequired,
}

const defaultProps = {}

function TaxReturns({ fetchTaxReturns }) {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState()
  const [taxReturns, setTaxReturns] = useState()

  useEffect(() => {
    const requestTaxReturns = async () => {
      try {
        const taxReturns = await fetchTaxReturns()
        setTaxReturns(taxReturns)
      } catch (errorMessage) {
        setError(`There was an issue loading your Tax Returns: ${errorMessage}`)
      }
      setIsLoading(false)
    }

    requestTaxReturns()
  }, [fetchTaxReturns])

  const taxReturnOptions = taxReturns?.map((taxReturn) => {
    if (!taxReturn.attributes) return

    return {
      name: `${taxReturn.attributes.year}-${taxReturn.attributes.docId}`,
      value: taxReturn.attributes.s9Url,
    }
  })

  return (
    <div>
      <header>
        <h2>Report Criteria</h2>
      </header>
      {isLoading ? (
        <IonSpinner />
      ) : error ? (
        <p>{error}</p>
      ) : isEmpty(taxReturns) ? (
        <p>You have no Tax Returns.</p>
      ) : (
        <TaxReturnForm
          taxReturnOptions={taxReturnOptions}
          onSubmit={async ({ taxReturnUrl }) => {
            if (!taxReturnUrl) return
            await Browser.open({ url: taxReturnUrl })
          }}
        />
      )}
      <HelpFooter />
    </div>
  )
}

TaxReturns.propTypes = exact(propTypes)
TaxReturns.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  fetchTaxReturns: apiActions.fetchTaxReturns,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(TaxReturns)
