import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  IonContent,
  IonList,
  IonItem,
  IonMenu,
  IonMenuToggle,
  IonHeader,
  IonButton,
  IonIcon,
} from '@ionic/react'
import { logOutOutline, chevronBackOutline } from 'ionicons/icons'
import {
  MENU_EXTERNAL_LINKS,
  SOCIAL_LINK_ITEMS,
  MENU_ICON,
  INACTIVE_ACCESS_HOME_PATH,
  APP_PATH,
  ANY_ACTIVE_NON_RIDE_USER,
  USER_TYPE,
} from 'config'
import * as Types from 'types'
import { Logo, NavMenuMainLinks } from 'components'

const propTypes = {
  logOut: PropTypes.func.isRequired,
  currentUser: Types.user.isRequired,
  openUploadModal: PropTypes.func.isRequired,
}
const defaultProps = {}

function NavigationMenu({ currentUser, logOut, openUploadModal }) {
  const hasInactiveAccess = !currentUser.isActive

  return (
    <IonMenu
      className="ion-content-scroll-host"
      contentId="main-content"
      type="overlay"
    >
      <IonContent>
        <div className="ion-content-inner">
          <IonHeader className="nav-menu-header ion-padding ion-no-border">
            <IonMenuToggle autoHide={false}>
              <IonButton fill="clear" aria-label="Close menu">
                <IonIcon icon={chevronBackOutline} />
              </IonButton>
              <Logo
                path={
                  hasInactiveAccess ? INACTIVE_ACCESS_HOME_PATH : APP_PATH.HOME
                }
              />
            </IonMenuToggle>
          </IonHeader>
          <NavMenuMainLinks
            hasInactiveAccess={hasInactiveAccess}
            currentUser={currentUser}
            openUploadModal={openUploadModal}
          />
          <div className="secondary-menu-nav-group">
            <IonList className="menu-navigation">
              {MENU_EXTERNAL_LINKS.map((item) => {
                if (
                  item.allowedUserTypes === ANY_ACTIVE_NON_RIDE_USER &&
                  currentUser.clientCustomerType === USER_TYPE.RIDE
                )
                  return null

                return (
                  <IonMenuToggle key={item.name}>
                    <IonItem
                      target="_blank"
                      rel="noreferer"
                      href={item.href}
                      lines="none"
                      detail={false}
                    >
                      <IonIcon
                        slot="start"
                        src={MENU_ICON[item.icon]}
                      ></IonIcon>
                      {item.name}
                    </IonItem>
                  </IonMenuToggle>
                )
              })}

              <IonMenuToggle>
                <IonItem
                  onClick={() => {
                    logOut()
                  }}
                  button
                  lines="none"
                  detail={false}
                >
                  <IonIcon slot="start" src={logOutOutline}></IonIcon>
                  Log Out
                </IonItem>
              </IonMenuToggle>
            </IonList>
            <IonList className="social-media-links">
              {SOCIAL_LINK_ITEMS.map((item) => {
                return (
                  <IonMenuToggle key={item.name}>
                    <IonItem
                      target="_blank"
                      rel="noreferrer"
                      href={item.href}
                      lines="none"
                      aria-label={item.name}
                      detail={false}
                    >
                      <IonIcon icon={MENU_ICON[item.icon]}></IonIcon>
                    </IonItem>
                  </IonMenuToggle>
                )
              })}
            </IonList>
            <div className="menu-disclaimer color-disclaimer">
              <p>
                If you have any questions about using the ATBS Hub, please
                contact your Business Consultant. If you experience any
                technical difficulties, please email our Help Desk at{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="mailto:ithelp@atbs.com"
                >
                  ithelp@atbs.com
                </a>
              </p>
              <p>
                <a
                  id="version-number"
                  target="_blank"
                  rel="noreferrer"
                  href={process.env.VERSION_HISTORY_URL}
                >
                  Version {process.env.VERSION}
                </a>
              </p>
            </div>
          </div>
        </div>
      </IonContent>
    </IonMenu>
  )
}

NavigationMenu.propTypes = exact(propTypes)
NavigationMenu.defaultProps = defaultProps

export default React.memo(NavigationMenu)
