import { APP_PATH } from 'config'

function redirectAfterSignIn() {
  const currentOrigin = window.location.origin
  // force reload by changing location path in order to create a new view stack
  // this affects the document sharing functionality for logged out users on Android,
  // so if this is changed, that feature needs thorough re-testing
  window.location.replace(`${currentOrigin}${APP_PATH.HOME}`)
}

export default redirectAfterSignIn
