import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { CellFormatter } from '../components'
import { IonAccordion, IonItem, IonLabel } from '@ionic/react'
import { PROFIT_PLAN_COLUMN, BREAKEVEN_POINT_CATEGORY } from 'config'
import { MaterialTable } from 'components'
import { getTableBodyRowProps } from 'utils'
import { has, isEmpty } from 'lodash'

const propTypes = {
  filterBy: PropTypes.string.isRequired,
  isCurrency: PropTypes.bool,
  name: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(Types.fullProfitPlanItem).isRequired,
  doNotApplyRowTotalsStyle: PropTypes.bool,
}

const defaultProps = {
  isCurrency: false,
  doNotApplyRowTotalsStyle: false,
}

function ProfitPlanTableMobileSection({
  name,
  filterBy,
  data,
  isCurrency,
  doNotApplyRowTotalsStyle,
}) {
  const filteredData = data.filter((obj) => has(obj, filterBy))
  if (isEmpty(filteredData)) return null

  const columns = [
    {
      header: PROFIT_PLAN_COLUMN.NAME.name,
      accessorKey: PROFIT_PLAN_COLUMN.NAME.value,
      maxSize: 15,
    },
    {
      header: '',
      accessorKey: filterBy,
      Cell: ({ cell, row }) => (
        <CellFormatter
          rawValue={cell.getValue()}
          isCurrency={isCurrency}
          isUnits={
            filterBy === PROFIT_PLAN_COLUMN.CENTS_PER_MILE.value ||
            row.original.name === BREAKEVEN_POINT_CATEGORY.MILES
          }
          isPercentage={
            filterBy === PROFIT_PLAN_COLUMN.REVENUE_PERCENTAGE.value
          }
        />
      ),
      maxSize: 15,
    },
  ]

  return (
    <div className="section-wrapper">
      <IonAccordion value={name}>
        <IonItem slot="header" color="light">
          <IonLabel className="profit-plan-accordion-section-header-label">
            {name}
          </IonLabel>
        </IonItem>
        <div className="accordion-back ion-padding" slot="content">
          <MaterialTable
            columns={columns}
            data={filteredData}
            muiTableBodyRowProps={({ row }) => {
              if (doNotApplyRowTotalsStyle) return

              return getTableBodyRowProps({ row })
            }}
          />
        </div>
      </IonAccordion>
    </div>
  )
}

ProfitPlanTableMobileSection.propTypes = exact(propTypes)
ProfitPlanTableMobileSection.defaultProps = defaultProps

export default React.memo(ProfitPlanTableMobileSection)
