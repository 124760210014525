import { downloadFileFromBlob, fetchFileBlob } from 'utils'

async function downloadFileWithFetch({ url, fileName, setError }) {
  try {
    const blob = await fetchFileBlob({ url, setError })
    await downloadFileFromBlob({ blob, fileName, setError })
  } catch (e) {
    setError(e.message)
  }
}

export default downloadFileWithFetch
