import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts'
import { IonSegment, IonSegmentButton } from '@ionic/react'
import { capitalize, find, map, some, upperCase } from 'lodash'
import {
  CELL_FORMATTING,
  EXPENSES_CHART_COLORS,
  TOTAL_EXPENSES_CATEGORY,
  EXPENSES_DONUT_CHART_TYPES,
} from 'config'
import { CustomTooltip } from '../components'
import { isTotalRow } from 'utils'

const propTypes = {
  data: Types.profitAndLossExpenses.isRequired,
  period: PropTypes.string.isRequired,
}

const defaultProps = {}

function ProfitLossExpensesDonutChart({ data, period }) {
  const [expenseType, setExpenseType] = useState(
    EXPENSES_DONUT_CHART_TYPES.VARIABLE
  )
  const expenseData = data[expenseType]
  const totalExpensesTypeName =
    TOTAL_EXPENSES_CATEGORY[`TOTAL_${upperCase(expenseType)}_EXPENSES`]
  const totalExpenses =
    find(expenseData, { name: totalExpensesTypeName })?.[`${period}Amount`] ?? 0

  const filteredData = expenseData.filter(
    (category) => !isTotalRow(category.name)
  )
  const chartData = filteredData.map((category) => {
    return {
      name: category.name,
      value: category[`${period}Amount`] ?? 0,
    }
  })

  const hasValues = some(chartData, (category) => category.value > 0)

  return (
    <>
      <IonSegment
        mode="ios"
        onIonChange={(e) => setExpenseType(e.detail.value)}
        value={expenseType}
      >
        {map(EXPENSES_DONUT_CHART_TYPES, (value) => (
          <IonSegmentButton key={value} value={value}>
            {capitalize(value)}
          </IonSegmentButton>
        ))}
      </IonSegment>
      <ResponsiveContainer width="100%" minHeight="350px" minWidth={340}>
        <PieChart>
          {hasValues ? (
            <>
              <Tooltip
                content={({ active, payload }) => (
                  <CustomTooltip
                    {...{
                      active,
                      payload,
                      formatter: CELL_FORMATTING.CURRENCY,
                    }}
                  />
                )}
              />
              <Pie
                data={chartData}
                dataKey="value"
                outerRadius={125}
                innerRadius={55}
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={entry.name}
                    fill={
                      EXPENSES_CHART_COLORS[
                        index % EXPENSES_CHART_COLORS.length
                      ]
                    }
                  />
                ))}
              </Pie>
            </>
          ) : (
            <Pie
              data={[{ name: 'No Data', value: 1 }]}
              outerRadius={90}
              innerRadius={60}
              fill="#eeeeee"
            />
          )}
        </PieChart>
      </ResponsiveContainer>
      <p className="chart-footer">{`Total ${capitalize(
        expenseType
      )} Expenses: ${totalExpenses.toLocaleString(
        'en-US',
        CELL_FORMATTING.CURRENCY
      )}`}</p>
    </>
  )
}

ProfitLossExpensesDonutChart.propTypes = exact(propTypes)
ProfitLossExpensesDonutChart.defaultProps = defaultProps

export default React.memo(ProfitLossExpensesDonutChart)
