import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import SignInForm from '../forms/SignInForm'
import { SignInHeader } from '../components'
import { IonList, IonRouterLink } from '@ionic/react'
import { APP_PATH } from 'config'

const propTypes = {}

const defaultProps = {}

function SignIn() {
  return (
    <>
      <IonList>
        <SignInHeader title="Log In">
          <div className="sign-in-header-details">
            <p>Welcome to the ATBS Hub!</p>
            <p>
              Your username is usually your email address. If you are
              experiencing problems logging into the hub, please contact{' '}
              <a href="mailto: IThelp@atbs.com">IThelp@ATBS.com</a>.
            </p>
          </div>
        </SignInHeader>
        <SignInForm />
        <div className="forgot-password-link ion-text-center">
          <IonRouterLink routerLink={APP_PATH.FORGOT_PASSWORD}>
            Forgot Password
          </IonRouterLink>
        </div>
      </IonList>
      <div className="ion-text-center ion-padding sign-in-footer">
        <p>Not yet an ATBS Client?</p>
        <p>
          Visit our website at{' '}
          <a href="http://www.atbs.com" target="_blank" rel="noreferrer">
            www.atbs.com
          </a>
          , or{' '}
          <a
            href=" https://www.atbs.com/contact-us-mobile-app"
            target="_blank"
            rel="noreferrer"
          >
            Contact Us
          </a>{' '}
          for more information!
        </p>
      </div>
    </>
  )
}

SignIn.propTypes = exact(propTypes)
SignIn.defaultProps = defaultProps

export default React.memo(SignIn)
