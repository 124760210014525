import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import { LoadingSpinner } from 'components'

const propTypes = {
  fetchFinancialPerformanceYearOptions: PropTypes.func.isRequired,
  financialPerformanceYears: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
}

const defaultProps = {
  financialPerformanceYears: null,
}

function FinancialPerformanceYearsProvider({
  fetchFinancialPerformanceYearOptions,
  financialPerformanceYears,
  children,
}) {
  const [error, setError] = useState()

  useEffect(() => {
    const requestFinancialReportYearOptions = async () => {
      try {
        await fetchFinancialPerformanceYearOptions()
      } catch (errorMessage) {
        setError(`There was an issue loading year options: ${errorMessage}`)
      }
    }

    requestFinancialReportYearOptions()
  }, [fetchFinancialPerformanceYearOptions])

  if (error) return <p>{error}</p>

  if (!financialPerformanceYears) return <LoadingSpinner />

  return children
}

FinancialPerformanceYearsProvider.propTypes = propTypes
FinancialPerformanceYearsProvider.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    financialPerformanceYears: selectors.financialPerformanceYears(state),
  }
}

const mapDispatchToProps = {
  fetchFinancialPerformanceYearOptions:
    apiActions.fetchFinancialPerformanceYearOptions,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FinancialPerformanceYearsProvider
)
