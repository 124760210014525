import React from 'react'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { IonCard, IonItem, IonLabel, IonList } from '@ionic/react'
import { flatten, map, snakeCase, startCase, toUpper, values } from 'lodash'
import { TableSection, CellFormatter } from '../components'
import { FINANCIAL_PERFORMANCE_SECTION, CELL_FORMATTING } from 'config'

const propTypes = {
  transactionsBatch: Types.transactionsBatch.isRequired,
}

const defaultProps = {}

function TransactionsBatchDetails({ transactionsBatch }) {
  const {
    transactions: transactionsBySection,
    date,
    batchId,
    description,
    total,
  } = transactionsBatch

  const nonTotalTransactions = flatten(values(transactionsBySection)).filter(
    (transaction) => !transaction.description.includes('Total')
  )

  return (
    <div className="transactions-batch-details">
      <div className="ion-text-right">
        <p className="font-bold">
          Transactions found: {nonTotalTransactions.length}
        </p>
      </div>
      <IonCard className="list-style-table-card no-bottom-margin">
        <IonList>
          <IonItem>
            <IonLabel>Batch</IonLabel>
            <p>{batchId}</p>
          </IonItem>
          <IonItem>
            <IonLabel>Date</IonLabel>
            <p>{date}</p>
          </IonItem>
          <IonItem>
            <IonLabel>Description</IonLabel>
            <p>{description}</p>
          </IonItem>
        </IonList>
      </IonCard>
      {map(transactionsBySection, (transactions, sectionName) => {
        const columns = [
          {
            header:
              FINANCIAL_PERFORMANCE_SECTION[toUpper(snakeCase(sectionName))] ||
              startCase(sectionName),
            accessorKey: 'description',
            muiTableHeadCellProps: {
              align: 'left',
              sx: { border: 'none', py: 0, px: '12px' },
            },
          },
          {
            header: 'Amount',
            accessorKey: 'amount',
            Cell: ({ cell }) => (
              <CellFormatter rawValue={cell.getValue()} isCurrency />
            ),
          },
        ]
        return (
          <TableSection
            key={sectionName}
            columns={columns}
            data={transactions}
            disableColumnPinning
          />
        )
      })}
      <div className="custom-ending-balance-toast">
        <span>P&L Total:</span>
        <span>{total.toLocaleString('en-US', CELL_FORMATTING.CURRENCY)}</span>
      </div>
    </div>
  )
}

TransactionsBatchDetails.propTypes = exact(propTypes)
TransactionsBatchDetails.defaultProps = defaultProps

export default React.memo(TransactionsBatchDetails)
