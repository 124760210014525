import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { informationCircle } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'

const propTypes = {}

const defaultProps = {}

function HelpFooter() {
  return (
    <div className="pinned-footer">
      <IonIcon icon={informationCircle} aria-hidden="true" />
      <p>If you have questions, please reach out to your consultant.</p>
    </div>
  )
}

HelpFooter.propTypes = exact(propTypes)
HelpFooter.defaultProps = defaultProps

export default React.memo(HelpFooter)
