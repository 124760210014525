function convertBlobToDataUrl({ blob, onSuccess, onFail }) {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  reader.onload = () => {
    onSuccess(reader.result)
  }
  reader.onerror = onFail
}

export default convertBlobToDataUrl
