import React from 'react'
import exact from 'prop-types-exact'
import { IonButton, IonIcon } from '@ionic/react'
import { alertCircleOutline } from 'ionicons/icons'
import { APP_PATH } from 'config'

const propTypes = {}

const defaultProps = {}

function PageNotFound() {
  return (
    <div className="centered-content full-page">
      <IonIcon icon={alertCircleOutline} />
      <h2>Page Not Found</h2>
      <p>Check the website URL and try again, or return home.</p>
      <IonButton
        fill="outline"
        routerLink={APP_PATH.HOME}
        routerDirection="root"
      >
        Return Home
      </IonButton>
    </div>
  )
}

PageNotFound.propTypes = exact(propTypes)
PageNotFound.defaultProps = defaultProps

export default React.memo(PageNotFound)
