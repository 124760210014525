import { getAuthorizationToken } from '../services/api'

async function fetchFileBlob({ url, setError }) {
  try {
    const res = await fetch(`${process.env.API_URL}${url}`, {
      headers: { Authorization: getAuthorizationToken() },
    })
    if (res.ok) {
      return await res.blob()
    }
    throw new Error('File not found')
  } catch (error) {
    setError && setError(error.message)
  }
}

export default fetchFileBlob
