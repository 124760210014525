import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonButton,
  IonIcon,
} from '@ionic/react'
import { InfoModal, LoadingSpinner } from 'components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { CELL_FORMATTING, PER_DIEM_ALLOCATION, CURRENT_YEAR } from 'config'
import { PerDiemForm } from '../forms'
import { usePerDiemFormSubmit } from 'hooks'
import { helpCircleOutline } from 'ionicons/icons'
import { selectors } from '../reducer'
import * as Types from 'types'
import classnames from 'classnames'
import { map, range, toString } from 'lodash'

const propTypes = {
  fetchPerDiem: PropTypes.func.isRequired,
  perDiem: Types.perDiem,
}

const defaultProps = {
  perDiem: null,
}

const TOTAL_NUMBER_OF_PER_DIEM_YEARS = 3

function PerDiem({ fetchPerDiem, perDiem }) {
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { handleSubmit: handlePerDiemFormSubmit } =
    usePerDiemFormSubmit(perDiem)
  const [selectedYear, setSelectedYear] = useState(CURRENT_YEAR)

  useEffect(() => {
    const requestPerDiem = async () => {
      try {
        setIsLoading(true)
        await fetchPerDiem(selectedYear)
      } catch (errorMessage) {
        setError(
          `There was an issue loading your Per Diem entries: ${errorMessage}`
        )
      }
      setIsLoading(false)
    }

    requestPerDiem()
  }, [fetchPerDiem, selectedYear])

  if (error) {
    return <div className="centered-content">{error}</div>
  }

  if (isLoading && !perDiem) return <LoadingSpinner />

  const { yearToDateFullDays, yearToDatePartialDays, yearToDateDeductions } =
    perDiem
  const summaryCardTitle =
    selectedYear === CURRENT_YEAR
      ? `${selectedYear} YTD DEDUCTIONS`
      : `${selectedYear} DEDUCTIONS`
  const yearOptions = map(
    range(CURRENT_YEAR, CURRENT_YEAR - TOTAL_NUMBER_OF_PER_DIEM_YEARS),
    toString
  )

  const highlightedDates = perDiem.perDiemEntries
    .map((perDiemEntry) => {
      if (perDiemEntry.ownerOperatorFullCount == 1)
        return {
          date: perDiemEntry.effectiveDate,
          backgroundColor: 'var(--ion-color-secondary)',
        }
      else if (perDiemEntry.ownerOperatorPartialCount == 1)
        return {
          date: perDiemEntry.effectiveDate,
          backgroundColor: 'rgba(var(--ion-color-secondary-rgb), 0.4)',
        }
    })
    .filter(Boolean) // there is some data that exists that does not have owner full or partial set; this ignores them

  return (
    <>
      <div
        className={classnames('per-diem-wrapper', { 'is-loading': isLoading })}
      >
        <div className="ytd-card-wrapper">
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>
                <IonButton
                  id="open-ytd-deductions-modal"
                  fill="clear"
                  size="small"
                  aria-label="Open help modal about year-to-date deductions"
                >
                  {summaryCardTitle}
                  <IonIcon icon={helpCircleOutline} />
                </IonButton>
              </IonCardTitle>
              <IonCardSubtitle>
                {yearToDateDeductions.toLocaleString(
                  'en-US',
                  CELL_FORMATTING.CURRENCY
                )}
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>{`${yearToDateFullDays} Full Days - ${yearToDatePartialDays} Partial Days`}</IonCardContent>
          </IonCard>
        </div>

        <PerDiemForm
          yearOptions={yearOptions}
          highlightedDates={highlightedDates}
          isFetchingCalendarData={isLoading}
          onYearChange={setSelectedYear}
          initialValues={{
            year: selectedYear,
            dayAllocation: PER_DIEM_ALLOCATION.FULL_DAY,
            selectedDates: [],
          }}
          handleSubmit={async (values, { resetForm }) => {
            await handlePerDiemFormSubmit(values)
            await fetchPerDiem(selectedYear)
            return resetForm({ values: { ...values, selectedDates: [] } })
          }}
        />
      </div>

      <InfoModal
        trigger="open-ytd-deductions-modal"
        title="YTD Calculation Explanation"
        className="ytd-deductions-modal"
      >
        <>
          <p>
            Your YTD (year-to-date) Per Diem tax deduction is calculated based
            on the number of full and partial days you spend on the road during
            the fiscal year. The deductible amount may be adjusted periodically
            by the IRS. ATBS will report your total allowable deduction on your
            tax return. The actual amount deducted from your tax return will
            likely differ from the amount in the app, based upon certain IRS
            rules.
          </p>
          <p>
            Per Federal Travel Regulations "FTR", full and partial days are
            defined as:
          </p>
          <ul>
            <li>
              Full Day: A day entirely away from home that requires rest to
              perform your duties.
            </li>

            <li>
              Partial Day: A day away from home for more than 12 hours and less
              than 24 hours.
            </li>
          </ul>
          <p>
            To learn more about the Per Diem deduction, visit:{' '}
            <a
              href="https://www.atbs.com/post/seizing-the-per-diem-tax-break"
              target="_blank"
              rel="noreferrer"
            >
              https://www.atbs.com/post/seizing-the-per-diem-tax-break.
            </a>
          </p>
        </>
      </InfoModal>
    </>
  )
}

PerDiem.propTypes = exact(propTypes)
PerDiem.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    perDiem: selectors.perDiem(state),
  }
}

const mapDispatchToProps = {
  fetchPerDiem: apiActions.fetchPerDiem,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(PerDiem)
