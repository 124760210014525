import { size } from 'lodash'

function formatFilesPayloadFromValues(values) {
  const formData = new FormData()
  formData.append('document_title', values.title)
  formData.append('files_count', size(values.files))

  values.files.forEach((file, index) => {
    const fileObject = createFileObject(file.blob, file.fileName)
    formData.append(`file_object_${index}`, fileObject)
    formData.append(`file_comment_${index}`, file.note)
    formData.append(`file_original_file_name_${index}`, file.fileName)
  })

  return formData
}

function createFileObject(blob, fileName) {
  if (!blob.lastModifiedDate) blob.lastModifiedDate = new Date()
  if (!blob.name) blob.name = fileName
  return blob
}

export default formatFilesPayloadFromValues
