import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { SendIntent } from 'send-intent'
import { hasActiveSession } from '../services/auth'
import {
  getSharedDocument,
  setSharedDocument,
  setIntentChecked,
  getIntentChecked,
} from '../services/documentShare'
import { startsWith } from 'lodash'
import { PLUGIN_ERROR_MESSAGE } from 'config'

function useDocumentShare() {
  const history = useHistory()

  useEffect(() => {
    const intentChecked = getIntentChecked()

    function checkIntent() {
      setIntentChecked()
      SendIntent.checkSendIntentReceived()
        .then((result) => {
          if (result.url) {
            setSharedDocument(result)
            if (hasActiveSession()) history.push('/documents/share', result)
          }
        })
        .catch((error) => {
          if (
            startsWith(error.message, PLUGIN_ERROR_MESSAGE.NO_PROCESSING_NEEDED)
          )
            return
          throw error
        })
    }

    window.addEventListener('sendIntentReceived', checkIntent)

    // Check for an intent when the webview within the native app *first loads*
    // since it's likely that the custom `sendIntentReceived` event will have
    // been sent before the JS loaded on cold start. However, we do not need to
    // keep checking for this when the *webview* reloads (e.g., `window.location.reload()`)
    // since this will retrieve the already processed share intent (Android only)
    // and errantly launch the user into the share experience.
    if (!intentChecked) {
      checkIntent()
    } else {
      const doc = getSharedDocument()
      if (doc && hasActiveSession()) history.push('/documents/share', doc)
    }

    return () => {
      window.removeEventListener('sendIntentReceived', checkIntent)
    }
  }, [history])
}

export default useDocumentShare
