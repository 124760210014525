import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  IonCard,
  IonList,
  IonItem,
  IonLabel,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react'
import { CELL_FORMATTING, NET_INCOME_BEFORE_TAX } from 'config'
import classnames from 'classnames'
import { isEmpty } from 'lodash'

const propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([Types.balanceSheetRecord, Types.profitAndLossRecord])
  ).isRequired,
  period: PropTypes.string.isRequired,
}

const defaultProps = {}

function TableCard({ title, data, period }) {
  return (
    <IonCard className="chart list-style-table-card">
      <IonCardHeader>
        <IonCardTitle>{title}</IonCardTitle>
      </IonCardHeader>
      <IonList>
        {isEmpty(data) ? (
          <p className="align-center">No data to display</p>
        ) : (
          data.map((row) => {
            const isNetIncomeRow = row.name === NET_INCOME_BEFORE_TAX
            return (
              <IonItem
                key={row.name}
                className={classnames({
                  'total-row-background': isNetIncomeRow,
                })}
              >
                <IonLabel>{row.name}</IonLabel>
                <p>
                  {row[`${period}Amount`].toLocaleString(
                    'en-US',
                    CELL_FORMATTING.CURRENCY
                  )}
                </p>
              </IonItem>
            )
          })
        )}
      </IonList>
    </IonCard>
  )
}

TableCard.propTypes = exact(propTypes)
TableCard.defaultProps = defaultProps

export default React.memo(TableCard)
