import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Formik, Form } from 'formik'
import { SubmitButton, ModalDateInput, MaskedInput, TextArea } from 'components'
import * as Yup from 'yup'
import { IonRouterLink } from '@ionic/react'
import { getTimeZone } from 'utils'
import { APP_PATH, minimumTripDate } from 'config'
import { maskitoParseNumber, maskitoNumberOptionsGenerator } from '@maskito/kit'
import { maskitoInitialCalibrationPlugin } from '@maskito/core'

const userTimeZone = getTimeZone()

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  startTimeZone: PropTypes.string,
  endTimeZone: PropTypes.string,
  children: PropTypes.node,
}

const defaultProps = {
  submitButtonText: 'Submit',
  startTimeZone: userTimeZone,
  endTimeZone: userTimeZone,
  children: null,
}

const validationSchema = Yup.object({
  from: Yup.date().required('"From" date-time is required'),
  to: Yup.date()
    .required('"To" date-time is required')
    .when('from', ([from], schema) => {
      if (!from) return

      return schema.min(
        new Date(from),
        '"To" date-time should be after "From" date-time'
      )
    }),
  miles: Yup.number()
    .transform((_value, originalValue) => maskitoParseNumber(originalValue))
    .typeError('Must be a number')
    .min(0, 'Must be a positive value')
    .required('Total miles is required'),
  note: Yup.string().max(250, 'Cannot be more than 250 characters'),
})

const usLocaleStringMask = maskitoNumberOptionsGenerator({
  decimalSeparator: '.',
  thousandSeparator: ',',
  precision: 2,
  min: 0,
})

const initialCalibrationPlugin = maskitoInitialCalibrationPlugin()

function TripForm({
  initialValues,
  handleSubmit,
  submitButtonText,
  startTimeZone,
  endTimeZone,
  children,
}) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, ...rest) => {
        const miles = maskitoParseNumber(values.miles)
        return handleSubmit({ ...values, miles }, ...rest)
      }}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form className="bottom-buttons">
          <div>
            <ModalDateInput
              name="from"
              displayTimeZone={true}
              timeZone={startTimeZone}
              min={minimumTripDate}
            />
            <ModalDateInput
              name="to"
              displayTimeZone={true}
              timeZone={endTimeZone}
              min={minimumTripDate}
            />
            <MaskedInput
              name="miles"
              maskOptions={{
                options: {
                  ...usLocaleStringMask,
                  plugins: [initialCalibrationPlugin], // Allow masking on an initial value
                },
              }}
            />
            <TextArea
              name="note"
              label="Note (optional)"
              placeholder="Enter note..."
              aria-label="Note"
              counter={true}
              maxlength={250}
              counterFormatter={(inputLength, maxLength) =>
                `${maxLength - inputLength} characters remaining`
              }
            />
            {children}
          </div>
          <div className="button-group">
            <SubmitButton color="secondary" submitting={isSubmitting}>
              {submitButtonText}
            </SubmitButton>
            <IonRouterLink
              routerLink={APP_PATH.MILEAGE_TRACKER.ALL_TRIPS}
              routerDirection="back"
              className="button-style"
            >
              Cancel
            </IonRouterLink>
          </div>
        </Form>
      )}
    </Formik>
  )
}

TripForm.propTypes = exact(propTypes)
TripForm.defaultProps = defaultProps

export default TripForm
